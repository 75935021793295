.writer-group {
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 39px;
  padding-left: 50px;
  min-height: 41px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    text-transform: capitalize;
  }

  p {
    color: #637280;
  }

  &.newbie {
    background-image: url(./img/icons/level-newbie.svg);
  }

  &.beginner {
    background-image: url(./img/icons/level-beginner.svg);
  }
  &.a-level {
    background-image: url(./img/icons/group-a.svg);
  }
  &.a-plus-level {
    background-image: url(./img/icons/group-a-plus.svg);
  }
  &.b-level {
    background-image: url(./img/icons/group-b.svg);
  }
  &.c-level {
    background-image: url(./img/icons/group-c.svg);
  }
  &.d-level {
    background-image: url(./img/icons/group-d.svg);
  }
  &.f-level {
    background-image: url(./img/icons/group-f.svg);
  }
}
