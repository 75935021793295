.pt0 {
  padding-top: 0px!important;
}

.pt8 {
  padding-top: 8px;
}

.pt10 {
  padding-top: 10px;
}

.pt20 {
  padding-top: 20px;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pb8 {
  padding-bottom: 8px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb35 {
  padding-bottom: 35px;
}

.pr0 {
  padding-right: 0 !important;
}

.pr8 {
  padding-right: 8px;
}

.pr10 {
  padding-right: 10px;
}

.pr15 {
  padding-right: 15px;
}

.pr10 {
  padding-right: 10px;
}

.pr20 {
  padding-right: 20px;
}

.pr30 {
  padding-right: 30px;
}

.pr35 {
  padding-right: 35px;
}

.pl0 {
  padding-left: 0 !important;
}

.pl8 {
  padding-left: 8px;
}

.pl15 {
  padding-left: 15px;
}

.pl10 {
  padding-left: 10px;
}

.pl20 {
  padding-left: 20px;
}

.pl30 {
  padding-left: 30px;
}

.pl35 {
  padding-left: 35px;
}
