.fsXs {
  font-size: $fontSizeXs;
}

.fsXs2 {
  font-size: $fontSizeXs2;
}

.fsXSm {
  font-size: $fontSizeXSm;
}

.fsSm {
  font-size: $fontSizeSm;
}

.fsMd {
  font-size: $fontSizeMd;
}

.fs15 {
  font-size: $fontSize;
}

.fsL {
  font-size: $fontSizeL;
}

.fsXL {
  font-size: $fontSizeXL;
}

.fsMAX {
  font-size: $fontSizeMAX;
}
