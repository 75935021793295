@import 'scss/_variables.scss';

.checked-tools {
  margin-right: 20px;

  &__deselect {
    font-size: $fontSizeSm;
    color: $colorText;
    cursor: pointer;
  }

  &__icon-download {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 14px;
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%2339444D'%3E%3Cg%3E%3Cg%3E%3Cpath d='M19 15c.513 0 .936.386.993.883L20 16v3c0 .513-.386.936-.883.993L19 20H5c-.513 0-.936-.386-.993-.883L4 19v-3c0-.552.448-1 1-1 .513 0 .936.386.993.883L6 16v2h12v-2c0-.513.386-.936.883-.993L19 15zM12 4c.513 0 .936.386.993.883L13 5v7.585l1.293-1.292c.36-.36.928-.388 1.32-.083l.094.083c.36.36.388.928.083 1.32l-.083.094-3 3c-.035.036-.073.068-.112.097l-.11.071-.114.054-.105.035-.149.03L12 16l-.075-.003-.126-.017-.111-.03-.111-.044-.098-.052-.092-.064-.094-.083-3-3c-.39-.39-.39-1.024 0-1.414.36-.36.928-.388 1.32-.083l.094.083L11 12.585V5c0-.552.448-1 1-1z' transform='translate(-1866 -578) translate(1735 578) translate(131)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  }
}
