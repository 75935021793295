.radiobutton {

    $iconSize: 16px;

    position: relative;
    cursor: pointer;
    font-size: $fontSizeSm;
    display: block;
    color: $color08;
    white-space: nowrap;
    transition: color $transitionTime;
    display: flex;
    align-items: center;

    @include breakpointMax(medium) {
        font-size: 16px;
        color:#39444D;
        padding: 12px 0 12px 0;
    }

    .input-text {
        cursor: pointer;
    }

    .input-text:focus {
        cursor: auto;
    }

    .radio-icon {
        display: inline-block;
        position: absolute;
        width: 14px;
        height: 14px;
        background-color: $colorWhite;
        border: 1px solid $color06;
        border-radius: 50%;
        vertical-align: middle;
        transition: background-color $transitionTime;
        transition: all $transitionTimeFast;
        box-sizing: content-box;
        top: 50%;
        margin-top: -7px;

        @include breakpointMax(medium) {
            width: 16px;
            height: 16px;
        }
    }

    &:hover {
        color: $colorText;
        
        .radio-icon {
            border: 1px solid $colorText;
        }

    }

    .radio-label {
        margin-left: 5px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 25px;

        @include breakpointMax(medium) {
            margin-left: 32px;
        }
    }

    .radio-label-note {
        color: $color06;
    }

    [type="radio"] {
        position: absolute;
        left: -9999px;

        &:checked {
            &+.radio-icon {
                width: 6px;
                height: 6px;
                background-color: $colorWhite;
                border: 5px solid $colorAction10;

                @include breakpointMax(medium) {
                    width: 8px;
                    height: 8px;
                }
            }

            ~ .radio-label {
                // color: $colorAction10;
            }
        }

        &:not(:checked) + .radio-icon:after {
            opacity: 1;
            transform: scale(1);
        }

    }

    &.disabled {
        cursor: not-allowed;
        color: $color00;

        .radio-icon {
            background-color: $color01;
            border: 1px solid $color00;
        }

        [type="radio"]:checked + .radio-icon{
            background-color: $colorWhite !important;
            border: 4px solid $color11 !important;
            box-shadow: 0px 0px 0px 1px $color06;
        }
    }
}
