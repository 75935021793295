
footer {
    border-top: 1px solid $color05;
    padding: 10px 0;
    flex: 0;

    .container {
        display: flex;
        justify-content: space-between;
        font-size: 11px;
        color: $colorExtraLight;
        letter-spacing: 0.1px;
    }
}