@import "../../../node_modules/normalize.css/normalize";
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");

* {
  box-sizing: border-box;
}

body {
  @include fontFamilyRegular;
  font-size: $fontSize;

  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: $colorAction10;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;

  &.list {
    position: relative;

    li {
      position: relative;
      padding-left: 10px;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #fff;
        left: -3px;
        top: 5px;
      }
    }
  }

  &.list_dark {
    position: relative;

    li {
      position: relative;
      padding-left: 15px;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #4e4e4e;
        left: 0;
        top: 8px;
      }
    }
  }
}

ol {
  margin: 0;
  padding: 0;
}

b,
strong {
  font-weight: normal;
  @include fontFamilyBold;
}

i {
  font-style: normal;
}

button:focus,
button:hover,
button:visited,
a:focus,
a:hover,
a:visited {
  outline: none;
}

@import "../icons";
@import "../preloading";
@import "./stars.scss";
@import "./tooltip.scss";
@import "./margins.scss";
@import "./fs.scss";
@import "./paddings.scss";
@import "./text-align.scss";
@import "./balance-box.scss";
@import "./withdrawal-box.scss";
@import "./summary-box.scss";
@import "./text-notation.scss";
@import "./input-text.scss";
@import "./line-height.scss";
@import "./transitions.scss";

.no-padding {
  padding: 0 !important;
}

.with-border-bottom {
  border-bottom: 1px solid $color08;
}

.block {
  display: block !important;
}

.pos-static {
  position: static !important;
}

.pos-relative {
  position: relative;
}

.clear {
  clear: both;
}

.minWidth100 {
  min-width: 100% !important;
}

.width0 {
  width: 0;
}

.full-height {
  height: 100vh;
}

.half-height {
  height: 50vh;
}

.switch-platform-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & .switch-actions {
    display: flex;
    align-items: center;
    margin-top: 16px;

    &__switch {
      margin: 0 16px;
    }
  }
}

.underline-text {
  text-decoration: underline;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-wrap {
  white-space: nowrap;
}

.pre-wrap {
  white-space: pre-wrap;
}

.overflow-wrap {
  overflow-wrap: break-word;
}

.icon-link {
  background-image: url("./img/icons/outLink.svg");
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  display: block;
}

.as-link-text {
  color: $colorAction11 !important;
}

.as-grey-text {
  color: $colorText !important;
}

.as-error-text {
  color: $colorBright2_10 !important;
}

.as-green-text {
  color: $colorBright1_10 !important;
}

.disabled-no-cursor {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.opacity-05 {
  opacity: 0.5;
}

.disabled {
  pointer-events: none;
}

.break-word {
  word-break: break-word !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-hidden-with-child > * {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-visible-with-child > * {
  overflow: visible !important;
}

.transparent-block {
  opacity: 0;
}

.bold-text {
  @include fontFamilyBold;
}

.table-note {
  padding: 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #3b464e;
  border-bottom: 1px solid #e6e8eb;
  display: flex;

  .icon_info {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjUgMTVDMTEuNjQyMSAxNSAxNSAxMS42NDIxIDE1IDcuNUMxNSAzLjM1Nzg2IDExLjY0MjEgMCA3LjUgMEMzLjM1Nzg2IDAgMCAzLjM1Nzg2IDAgNy41QzAgMTEuNjQyMSAzLjM1Nzg2IDE1IDcuNSAxNVpNNy41IDEzLjc1QzEwLjk1MTggMTMuNzUgMTMuNzUgMTAuOTUxOCAxMy43NSA3LjVDMTMuNzUgNC4wNDgyMiAxMC45NTE4IDEuMjUgNy41IDEuMjVDNC4wNDgyMiAxLjI1IDEuMjUgNC4wNDgyMiAxLjI1IDcuNUMxLjI1IDEwLjk1MTggNC4wNDgyMiAxMy43NSA3LjUgMTMuNzVaIiBmaWxsPSIjMjM4M0U1Ii8+CjxwYXRoIGQ9Ik04LjEyNSAzLjc1QzguMTI1IDQuMDk1MTggNy44NDUxOCA0LjM3NSA3LjUgNC4zNzVDNy4xNTQ4MiA0LjM3NSA2Ljg3NSA0LjA5NTE4IDYuODc1IDMuNzVDNi44NzUgMy40MDQ4MiA3LjE1NDgyIDMuMTI1IDcuNSAzLjEyNUM3Ljg0NTE4IDMuMTI1IDguMTI1IDMuNDA0ODIgOC4xMjUgMy43NVoiIGZpbGw9IiMyMzgzRTUiLz4KPHBhdGggZD0iTTYuODc1IDYuMjVDNi44NzUgNS45MDQ4MiA3LjE1NDgyIDUuNjI1IDcuNSA1LjYyNUM3Ljg0NTE4IDUuNjI1IDguMTI1IDUuOTA0ODIgOC4xMjUgNi4yNVYxMS4yNUM4LjEyNSAxMS41OTUyIDcuODQ1MTggMTEuODc1IDcuNSAxMS44NzVDNy4xNTQ4MiAxMS44NzUgNi44NzUgMTEuNTk1MiA2Ljg3NSAxMS4yNVY2LjI1WiIgZmlsbD0iIzIzODNFNSIvPgo8L3N2Zz4K");
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    background-size: contain;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
}

.inline-block {
  display: inline-block;
}

.no-strong {
  font-weight: normal!important;
}

#audio_nt_notice {
  position: absolute;
  left: -9999px;
  top: -9999px;
}
