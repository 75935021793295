.preview-slider {
  height: 100vh;
  width: 100vw;
  z-index: 2;

  top: 0;

  background-color: rgba(0,0,0, 0.7);

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 20px;

  position: absolute;

  .btn-close {
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;

    svg.close {
      fill: #fff;
    }
  }

  &__img-container {
    max-width: 80vw;
    max-height: 90vh;

    > * {
      max-width: 100%;
      max-height: 100%;

      > * {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &__img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;

    width: 100%;
    height: 100%;
    padding: 10px;
  }

  &__zoom-tools {
    position: fixed!important;
    left: 0!important;
  }

  &__btn {
    background: transparent;
    border: none;
    outline: none;

    cursor: pointer;

    i {
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;

      display: block;
    }

    &__left {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M15 18l-6-6 6-6'/%3E%3C/svg%3E");
    }

    &__right {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M9 18l6-6-6-6'/%3E%3C/svg%3E");
    }
  }
}
