@import 'scss/_variables';

.input-search-container {
  position: relative;

  &--disabled {
    opacity: 0.5;
    pointer-events: none;

    .input-search-container__input {
      &.input-text {
        padding-left: 10px;
      }
    }
  }

  &--no-search {
    .input-search-container__input {
      &.input-text {
        padding-left: 10px;
      }
    }
  }

  .svg-icon.search {
    cursor: pointer;
    position: absolute;
    left: 10px;
    top: 7px;
  }

  .svg-icon.close {
    width: 10px;
    height: 10px;
    fill: #c0c7ce;
  }

  &__input {
    width: 100%;

    &.input-text {
      padding: 5px 30px 5px 30px;
    }


    text-overflow: ellipsis;
  }

  .btn.btn-close {
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;

    position: absolute;
    right: 0;
    top: 1px;
    background: transparent;

    &--show {
      opacity: inherit;
      pointer-events: all;
    }
  }

  .input-search-container-search-container {
    width: 100%;
    background-color: #fff;
    border: 1px solid $color06;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: $borderRadius;
    position: absolute;
    padding: 16px 11px 11px 11px;
    margin-top: 12px;
    max-height: 321px;
    overflow-y: auto;
    z-index: 1;

    &::-webkit-scrollbar {
      width: 6px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #F0F2F5;
    }

    &__item {
      font-size: $fontSize;
      line-height: 23px;
      cursor: pointer;
      letter-spacing: 0.1px;
      color: $colorText;
      padding: 5px 10px;
      transition: all 0.3s ease;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        background: #ECF7FF;
        border-radius: 3px;
        white-space: normal;
      }
    }

    mark.input-search-container-search-container__mark {
      background-color: transparent;
      color: $colorAction10;
      @include fontFamilyMedium;
    }

    &__info {
      font-size: $fontSize;
      color: $colorExtraLight;
    }
  }
}
