.order-standalone-page {
    .order-tab {
        position: static;
        top: auto;
        right: auto;
        bottom: auto;
        width: auto;
        border-radius: 4px;

        .order-tab-head {
            min-height: unset;
            padding: 0;
            box-shadow: none;
            margin-top: -8px;
            margin-bottom: 15px;

            .row {
                display: none;
            }

            @include breakpointMax(medium) {
                .issue-banner {
                    width: 100%;
                    margin: 0;
                    padding: 8px 25px;
                    height: auto;
                }
            }

            @include breakpoint(medium) {
                padding: 0 30px 0;
                min-height: 50px;
                margin-top: 0px;
                margin-bottom: 0px;

                .row {
                    display: flex;
                }
            }
        }

        @include breakpointMax(medium) {
            & .order-static-tab {
                position: fixed;
                width: calc(100% - 60px);
                left: 30px;
                top: 58px;
                background-color: #fff;
                z-index: 1;
                padding-top: 20px;
            }
        }
    }

    .order-number {
        color: $colorText;
    }

    .order-detail-body {
        padding: 0 30px 20px 30px;

        &--standalone-NT {
            padding: 0 10px 20px 10px;
        }

        @include breakpointMax(medium) {
            &.with-static {
                padding-top: 35px;

                &--big {
                    padding-top: 72px;
                }
            }
        }
    }

    .row-border {
        padding: 0;
    }
}
