@import "scss/_variables.scss";

.modal-reduce-container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;

  &--overflow {
    overflow-y: hidden;
  }

  &__header {
    height: 38px;
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    z-index: 1;
    box-shadow: inset 0 -1px 0 0 rgba(198, 198, 198, 0.5);

    &__back {
      width: 40px;
      height: 38px;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .svg-icon {
        width: 20px;
        height: 20px;
      }
    }

    &__name {
      @include fontFamilyBold;

      white-space: nowrap;
      max-width: 290px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    + div {
      padding: 38px 16px;
    }
  }
}
