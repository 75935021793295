.chat-box {
  margin-top: 20px;

  .chat-item-list {
    margin-bottom: 60px;

    .chat-item {
      margin-left: 20%;
      text-align: right;
      margin-bottom: 15px;

      &.chat-item--system {
        margin-top: 30px;

        .btn-group {
          margin-top: 20px;

          .btn {
            padding: 0 20px;
          }
        }

        .btn-sent-issue,
        .btn-sent-reg-message {
          border-radius: 100px;
          color: #fff;
          border: none;
          height: 25px;
          transition: background-color 0.3s;
        }

        .btn-sent-reg-message {
          background-color: #00af6f;
          &:hover {
            background-color: rgba(0, 175, 111, 0.7);
          }
        }

        .btn-sent-issue {
          background-color: #ffa65d;
          margin-right: 12px;
          &:hover {
            background-color: rgba(255, 166, 93, 0.7);
          }
        }
      }

      .chat-message-wrap {
        max-width: 100%;
        min-width: 200px;
        display: inline-block;
        position: relative;
      }

      .dropdown {
        position: absolute;
        left: -20px;
        top: 15px;
      }

      .dotted-block {
        width: 4px;
        padding-top: 0px;
        border: none;
        background: none;
        cursor: pointer;

        i,
        &:after,
        &:before {
          content: "";
          display: block;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: rgba(99, 114, 128, 0.28);
          transition: background-color 0.3s;
        }

        i {
          margin-top: 4px;
        }

        &:after {
          margin-top: 4px;
        }

        &:hover {
          i,
          &:after,
          &:before {
            background-color: rgba(99, 114, 128, 1);
          }
        }
      }

      .menu-message-action {
        position: absolute;
        font-size: 14px;
        letter-spacing: 0.11px;
        color: #3b464e;
        padding: 8px;
        border-radius: 3px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
        border: solid 1px #c0c7ce;
        background-color: #ffffff;
        white-space: nowrap;
        cursor: pointer;
        top: -10px;
        left: -5px;
      }

      .chat-system-message {
        font-size: 15px;
        line-height: 1.2;
        letter-spacing: 0.12px;
        color: #637280;
        margin-bottom: 16px;
      }

      .chat-message {
        border-radius: $borderRadiusChatXl $borderRadius $borderRadiusChatXl $borderRadiusChatXl;
        text-align: left;
        border: 1px solid $colorAction09;
        display: inline-block;
        font-size: $fontSize;
        line-height: 20px;
        margin-bottom: 10px;
        white-space: pre-wrap;
        word-break: break-all;
        width: 100%;
        overflow: hidden;

        &.chat-message--system {
          border: none;
          border-radius: 0px;
        }
      }

      .chat-message-text {
        background-color: $colorAction09;
        color: $colorWhite;
        padding: 15px 40px 15px 20px;
        word-break: break-word;
      }

      .btn {
        border-radius: $borderRadiusChatXl;
        padding: 10px 20px;

        &.btn-confirm {
          background-color: $colorBright1_10;
          color: $colorWhite;
          font-size: $fontSizeSm;
          height: 25px;
          padding: 5px 15px;

          &:disabled {
            opacity: 0.5;
          }
        }
      }

      .btn-confirm--wrap {
        margin-left: 0;
        text-align: left;

        .btn {
          margin-right: 5px;
        }
      }

      .file-icon {
        padding: 7px 10px 7px 45px;
        border: 1px solid $color05;
        display: block;
      }

      .chat-time {
        display: block;
        text-align: left;
      }

      &.chat-item-in {
        width: 80%;
        margin-left: 0;
        text-align: left;

        .chat-user-info {
          margin-bottom: 10px;
        }

        .chat-message {
          border-color: $color04;
          color: $colorText;
          border-radius: $borderRadius $borderRadiusChat $borderRadiusChat $borderRadiusChat;
        }

        .chat-message-text {
          background-color: $color04;
          color: $colorText;
          word-break: break-word;
        }

        .chat-time {
          text-align: right;
        }
      }

      &.download-box {
        width: 200px;
      }

      .upload-file {
        word-break: break-all;
        padding: 5px 15px 5px 45px;
        min-width: 200px;
        margin-bottom: 5px;
        border: none;
      }
    }

    .chat-item {
      transition: all 0.3s;
      &.disabled {
        .chat-message {
          border-radius: 25px 3px 25px 25px;
          text-align: left;
          border: solid 1px #e6e8eb;
          background-color: rgba(190, 199, 207, 0.25);
          color: #3b464e;
          display: inline-block;
          font-size: 15px;
          line-height: 20px;
          margin-bottom: 10px;
          white-space: pre-wrap;
          word-break: break-all;
          width: 100%;
          overflow: hidden;
        }

        a {
          color: #3b464e;
          opacity: 0.7;

          &.issue-link {
            color: #2a86e1;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }

        .chat-message-text {
          color: #3b464e;
          opacity: 0.7;
          background-color: rgba(190, 199, 207, 0.25);
        }

        .notice {
          color: #9ca9b6;
          text-align: left;
          display: block;
          font-size: 12px;
          margin-bottom: 5px;
        }
      }
    }

    .chat-user-type {
      display: inline-block;
      vertical-align: top;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      color: $colorWhite;
      text-align: center;
      margin-right: 5px;

      &.type-system {
        background: url("./img/icons/system-avatar.svg") no-repeat center center;
      }

      &.type-operator {
        background: url("./img/icons/operator-avatar.svg") no-repeat center center;
      }

      &.type-grader {
        background: url("./img/icons/grader-avatar.svg") no-repeat center center;
      }

      &.type-client {
        background: url("./img/icons/client-avatar.svg") no-repeat center center;
      }
    }

    .chat-system-name {
      font-size: 11px;
      letter-spacing: 0.09px;
      text-align: right;
      color: #9ca9b5;
      margin-bottom: 8px;
    }

    .chat-user-name {
      display: inline-block;
      line-height: 35px;
      color: $color09;
      @include fontFamilyMedium;
      vertical-align: top;

      span {
        color: $color07;
      }
    }

    .chat-time {
      font-size: $fontSizeXSm;
      color: $color07;
    }

    .text-success {
      font-size: $fontSizeXs2;
      display: flex;
      align-items: center;

      .check {
        fill: $colorBright1_09;
        margin-right: 3px;
      }
    }
    .download {
      border-radius: $borderRadius $borderRadiusChat $borderRadiusChat $borderRadiusChat;
      overflow: hidden;
      width: 200px;
      margin-bottom: 5px;
      cursor: pointer;

      .image-wrap {
        max-height: 145px;
        margin: 0;
        padding: 0;

        img {
          width: 100%;
          display: block;
        }
      }

      .btn {
        width: 100%;
        border: solid 1px $color05;
        border-radius: 0 0 $borderRadiusChat $borderRadiusChat;
        font-size: $fontSizeMd;
        color: $colorAction09;
        @include fontFamilyMedium;
      }
    }
  }
}

.upload-files {
  max-height: 150px;
  overflow-y: auto;
}

.message-box-panel {
  box-shadow: inset 0 1px 0 0 #e6e8eb;
  background-color: #fff7ee;
  padding: 3px 20px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.09px;
  color: #e2bb91;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .btn-link {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTAgOSI+CiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iIzk3OTc5NyI+CiAgICAgICAgPHBhdGggZD0iTTMuNSA2LjA5SDFWMWg1LjA5djEuOTI3Ii8+CiAgICAgICAgPHBhdGggZD0iTTMuNTQ1IDIuOTA5aDUuMDkxVjhIMy41NDV6Ii8+CiAgICA8L2c+Cjwvc3ZnPg==");
    background-position: 0 center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    opacity: 0.25;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0.09px;
    color: #3b464e;
    border: none;
    padding: 0;
    cursor: pointer;
    padding-left: 20px;
  }
}

.send-message {
  position: relative;
  display: block;
  background-color: $colorWhite;
  box-shadow: inset 0 1px 0 0 $color05;
  padding: 15px 20px;

  &.disabled {
    &:before {
      content: "";
      display: block;
      position: absolute;
      background-color: #fff;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  textarea {
    border: none;
    width: 100%;
    padding: 0;
    overflow-y: auto;
    max-height: 200px;
    outline: none;
    resize: none;
    overflow: hidden;
    font-size: $fontSizeSm;
    letter-spacing: 0.1px;
    position: absolute;
    left: 0;
    top: 1px;
    height: 100%;
    line-height: 16px;
    white-space: pre-wrap;
    word-break: break-all;

    &:focus {
      outline: none;
    }
  }

  .message-wrap {
    position: relative;
    min-width: auto;
    flex-basis: auto;

    @include breakpoint(medium) {
      min-width: 375px;
    }
  }

  [class^="col-"].send-message-btn {
    padding-left: 10px;
    flex: 0 0 auto;
  }

  .message-copy {
    width: 100%;
    white-space: pre-wrap;
    max-height: 200px;
    overflow: hidden;
    letter-spacing: -0.2px;
    word-break: break-all;
  }

  .btn-clip {
    border: none;
    background-color: transparent;
    cursor: pointer;
    z-index: 1;
  }

  .btns-wrap {
    display: flex;
    align-items: center;
    margin-right: 0;

    @include breakpoint(medium) {
      margin-right: 25px;
    }

    .btn-smile {
      border: none;
      background-color: transparent;
      cursor: pointer;
      height: 100%;
    }

    .btn-bright {
      margin-left: 10px;
      padding: 7px 20px;
    }
  }

  .clip {
    width: 21px;
    height: 20px;
    fill: $colorAction09;
    stroke: none;
  }

  .dropdown.dropdown-up {
    .dropdown-content {
      top: auto;
      bottom: calc(100% + 10px);
      right: -100%;
      left: auto;
      width: 274px;

      @include breakpoint(medium) {
        right: 0;
      }
      
    }

    .dropdown-content-smile {
      right: 0;
      left: auto;
      bottom: calc(100% + 10px);
      width: 275px;
    }

    .smile-item {
      text-decoration: none;
      display: inline-block;
      padding: 3px;
      font-size: 24px;
    }
  }

  .upload-file-box {
    margin: 10px 0 0 10px;

    .upload-file-item {
      a {
        font-size: 14px;
        line-height: 1.07;
        letter-spacing: 0.1px;
        text-align: left;
        color: $color09;
        position: relative;

        &:hover {
          text-decoration: none;
        }

        span {
          position: relative;
          z-index: 1;
        }

        i {
          display: inline-block;
          background: #d6e9ff;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 0;
        }
      }

      .btn-delete-file {
        margin-left: 10px;
      }
    }
  }
}
