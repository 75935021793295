@import 'scss/_variables.scss';

.client-chat-message-box {
  position: fixed;

  width: 100%;
  bottom: 0;
  right: 0;

  z-index: 1;

  @include breakpoint(medium) {
    width: 350px;
    right: 600px;
    animation: rightElements600 0.1s ease-in-out;
  }

  &--standalone {
    right: unset;
    bottom: 30px;
    width: 350px;
    left: calc(50% - (600px / 2) + 250px);
  }
}
