@import 'scss/_variables.scss';

.card-live-session-container {

  &__header {
    font-size: $fontSizeL;
    @include fontFamilyBold;
    
    color: $colorDark;
  }

  &__header-icon {
    width: 24px;
    height: 24px;

    background-repeat: no-repeat;
    background-position: center;

    margin-right: 8px;

    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.2998 15.8931V15.1943V14.2294V9.68751V8.72256L19.0433 7.17531L22.7607 4.23057C23.2563 3.81465 23.9998 4.16403 23.9998 4.81287V18.8877C23.9998 19.5366 23.2398 19.8859 22.7442 19.47L18.2998 15.8931Z' fill='%2300AC47'/%3E%3Cpath d='M6 2.1001L0 8.1001H6V2.1001Z' fill='%23EA4335'/%3E%3Cpath d='M6 8.1001H0V15.9001H6V8.1001Z' fill='%232684FC'/%3E%3Cpath d='M0 15.8999V19.8999C0 20.9999 0.9 21.8999 2.00001 21.8999H6V15.8999H0Z' fill='%230066DA'/%3E%3Cpath d='M19.05 4.07716C19.05 2.98978 18.1691 2.1001 17.0925 2.1001H13.1775H6V8.1001H13.35V12.0001L19.05 11.8206V4.07716Z' fill='%23FFBA00'/%3E%3Cpath d='M13.35 15.9H6V21.9H13.1775H17.0925C18.1691 21.9 19.05 21.0116 19.05 19.9258V12H13.35V15.9Z' fill='%2300AC47'/%3E%3Cpath d='M19.0496 7.2002V16.5002L13.3496 12.0002L19.0496 7.2002Z' fill='%2300832D'/%3E%3C/svg%3E%0A");
    
    &--isOver, &--cancelled {
      filter: grayscale(1);
    }
  }

  .card-live-session-time-container {
    &__hours {
      font-size: $fontSizeTitle;
      @include fontFamilyBold;

      color: $colorDark;
    }

    &__date {
      font-size: $fontSizeMd;

      color: $colorText;

      margin-top: 4px;
    }

    &__avatar {
      width: 45px;
      height: 45px;

      background-repeat: no-repeat;
      background-position: center;

      background-color: $colorAction11;

      border-radius: 50%;
    }
  }

  &__btn {
    min-width: 100%;

    box-shadow: 0px 4px 8px rgba(86, 93, 107, 0.1), 0px 8px 16px rgba(186, 207, 255, 0.232903);
    border-radius: 20px!important;

    margin-bottom: 16px;

    line-height: 20px;
  }
}
