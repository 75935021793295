@import 'scss/_variables.scss';

.react_pdf__Document {
  position: absolute;
  overflow-y: scroll;
  height: 100%;
  overflow: auto;

  opacity: 0;

  min-width: 824px;
  background: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include breakpointMax(medium) {
    min-width: 336px;
    position: absolute;
  }

  &--active {
    opacity: 1;
    @include breakpointMax(medium) {
      position: relative;
    }
  }


  .react-pdf__message.react-pdf__message--loading {
    display: none;
  }
}
