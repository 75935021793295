.slider {
    display: block;
    height: $blockHeight;
    padding: 8px 14px 0 11px;

    .rc-slider {
        position: relative;
        height: 14px;
        padding: 5px 0;
        border-radius: 6px;
        touch-action: none;

        .rc-slider-rail {
            position: absolute;
            width: 100%;
            background-color: $colorAction07;
            height: 4px;
            border-radius: 6px;
        }

        .rc-slider-track {
            position: absolute;
            left: 0;
            height: 3px;
            border-radius: 6px;
            background-color: $colorAction10;
        }

        .rc-slider-handle {
            position: absolute;
            margin-left: -11px;
            margin-top: -11px;
            width: 25px;
            height: 25px;
            cursor: pointer;
            cursor: -webkit-grab;
            cursor: grab;
            border-radius: 50%;
            background-image: linear-gradient(329deg, $color05, $colorWhite);
            border: solid 1px $color06;
            touch-action: pan-x;
        }

        .rc-slider-handle:active {
            cursor: grabbing
        }

        .rc-slider-step {
            position: absolute;
            width: 100%;
            height: 4px;
            background: transparent;
        }
    }
}

.rc-slider-tooltip {
    z-index: 12;
}

.rc-slider-tooltip-inner {
    padding: 6px 7px;
}
