@import 'scss/_variables.scss';

@keyframes rightAnimation {
  0% {
    right: 600px;
  }
  100% {
    right: 950px;
  }
}

.order-suggested-steps-container {
  position: fixed;
  top: 0;
  right: 950px;
  width: 250px;
  animation: rightAnimation 0.1s ease-in-out;
  z-index: -1;
  background-color: #fff;
  padding: 15px 20px;
  border-right: 1px solid $color05;
  overflow-y: scroll;

  &--standalone {
    position: relative;
    top: unset;
    right: unset;
    z-index: 1;
    flex-shrink: 0;
    margin-left: -10px;
  }

  &::-webkit-scrollbar {
    width: 0px;
  }

  &__header {
    font-size: $fontSizeMd;
    @include fontFamilyMedium;
    line-height: 18px;
    color: $color09;

    margin-bottom: 18px;
  }
}
