.row {
    display: flex;
    flex-wrap: wrap;
  
    &.column {
        flex-direction: column;
    }
  
    &.no-wrap {
        flex-wrap: nowrap; 
    }
  
    &.subjects-center {
        padding-left: calc(50% - 200px);
    }
  
    @include breakpoint(medium) {
        flex-wrap: nowrap;
    }
  
    &.vertical {
        align-items: center;
    }
  
    &.on-top {
        align-items: flex-start;
    }
  
    &.space-between {
        justify-content: space-between;
    }
  
    &.flex-end {
        justify-content: flex-end;
    }
  
    &.column {
        flex-direction: column;
    }
    
    &.left {
        justify-content: flex-end;
    }
  
    &.center_center {
        align-items: center;
        justify-content: center;
    }

    .no-shrink-item {
        flex-shrink: 0;
    }
  
    &.column_gap_8 {
        gap: 0 8px;
    }
  
    &.column_gap_10 {
        gap: 0 10px;
    }
  
    &.column_gap_12 {
        gap: 0 12px;
    }
  
    &.column_gap_24 {
        gap: 0 24px;
    }
  
    &.row_gap_8 {
        gap: 8px 0;
    }
  
    &.row_gap_10 {
        gap: 10px 0;
    }
  
    &.row_gap_12 {
        gap: 12px 0;
    }
  
    &.row_column_gap_8 {
        gap: 8px;
    }
  
    &.row_column_gap_10 {
        gap: 10px;
    }
  
    &.row_column_gap_12 {
        gap: 12px;
    }
    
    > [class^="col-"] {
        padding: 0 10px;
  
        &.no-padding {
            padding: 0;
        }
  
        &.no-wrap {
            white-space: nowrap;
        }
    }
  
    > [class^="col-"]:first-child {
        padding-left: 0;
    }
  
    > [class^="col-"]:last-child {
        padding-right: 0;
    }
  
    > [class^="col-"]:first-child {
        &.is-padding {
          padding-left: 10px;
        }
    }
  
    > [class^="col-"]:last-child {
        &.is-padding {
            padding-right: 10px;
        }
    }
  
    @for $i from 1 through 12 {
        .col-#{$i} {
            flex: $i;
        }
    }
  
    .col-0 {
        flex: 0 1 auto;
    }

    .basis-100 {
        flex-basis: 100%;
    }
  }
  