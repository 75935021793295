$colorWhite : #fff;
$colorBlack : #000;

$colorBlackOpacity25 : rgba(0, 0, 0, 0.25);
$colorBlackOpacity07 : rgba(0, 0, 0, 0.07);
$colorBlackOpacity04 : rgba(0, 0, 0, 0.4);

$colorDark : #030303;
$colorDarkOpacity : rgba(3, 3, 3, .4);
$colorLight : #fcfdfd;
$colorExtraLight : #a7a8ac;

$colorText : #637280;
$colorTextDark : #39444d;
$colorTextLightGrey : #667580;

$color13 : #e3e3e3;
$color12 : #eceff1;
$color11 : #dadee2;
$color10 : #030303;
$color09 : #3b464e;
$color08 : #637280;
$color08Opacity007 : rgba(99, 114, 128, 0.07);
$color07 : #9ca9b6;
$color06 : #c0c7ce;
$color05 : #e6e8eb;
$color04 : #f2f3f5;
$color03 : #f7f9fa;
$color02 : #fcfdfd;
$color01 : #f9f9f9;
$color00 : #d7d7d9;

$colorAction11 : #39A6F1;
$colorAction10 : #2a86e1;
$colorAction09 : #389bfe;
$colorAction08 : #8dbdff;
$colorAction07 : #cbe5fb;
$colorAction06 : #d6e9ff;
$colorAction05 : #e1effd;
$colorAction04 : #ebf7ff;
$colorAction03 : #f3f8fd;

$colorMark : #fff2a6;

$colorBright1_10 : #12ca86;
$colorBrightOpacity008_1_10 : rgba(18, 202, 134, 0.08);
$colorBright1_09 : #00af6f;
$colorBrightOpacity1_09 : rgba(0, 175, 111, .08);
$colorBrightOpacity20_1_09 : rgba(0, 175, 111, .2);
$colorBright1_08 : #f7fff2;
$colorBright_1_07 : #00b06d;
$colorBrightOpacity80_1_07 : rgba(0, 176, 109, .8);

$colorBright2_11 : #AA6DD0;
$colorBright2_10 : #fc694f;
$colorBrightOpacity2_10 : rgba(252, 105, 79, .2);
$colorBrightOpacity2_10_06 : rgba(252, 105, 79, .6);
$colorBrightOpacity055_2_10 : rgba(252, 105, 79, .05);
$colorBright2_09 : #ffa65d;

$colorBrightOpacity01_2_09 : rgba(255, 166, 93, .1);
$colorBrightOpacity013_2_09 : rgba(255, 166, 93, 0.13);
$colorBright2_08 : #ff3c3c;
$colorBright2_07 : #fb4e35;
$colorBright2_06 : #fffae4;
$colorBright2_05 : #FFAA46;
$colorBright3_01 : #ea9e00;
$colorBright3_02 : #e89d00;

// $fontFamilyHb                   : 'HelveticaNeue-Bold';
// $fontFamilyHr                   : 'HelveticaNeue';
// $fontFamilyHm                   : 'HelveticaNeueMedium';

$transitionTime : .5s;
$transitionTimeFast : .2s;

$boxShadow : rgba(0, 0, 0, 0.1);

$layoutWidth : 1200px;
$blockHeight : 30px;

$fontSizeMAX : 30px;
$fontSizeXL : 17px;
$fontSizeL : 16px;
$fontSize : 15px;
$fontSizeMd : 14px;
$fontSizeSm : 13px;
$fontSizeXSm : 12px;
$fontSizeXs2 : 11px;
$fontSizeXs : 10px;
$fontSizeXXs : 8px;

$fontSizeTitle : 22px;
$fontSizeTitleSm : 19px;

$fontSizeEmoji : 27px;

$borderRadius : 3px;
$borderRadiusChat : 25px;
$borderRadiusChatXl : 25px;

@mixin breakpoint($point) {
  @if $point==small {
    @media (min-width: 320px) {
      @content;
    }
  }

  @else if $point==medium {
    @media (min-width: 768px) {
      @content;
    }
  }

  @else if $point==large {
    @media (min-width: 1200px) {
      @content;
    }
  }
}

@mixin breakpointMax($point) {
  @if $point==small {
    @media (max-width: 320px) {
      @content;
    }
  }

  @else if $point==medium {
    @media (max-width: 768px) {
      @content;
    }
  }

  @else if $point==large {
    @media (max-width: 1200px) {
      @content;
    }
  }
}


@mixin fontFamilyBolder {
  font-weight: 900;
}

@mixin fontFamilyBold {
  font-family: 'Lato', sans-serif;
  font-weight: bold;
}

@mixin fontFamilyMedium {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}

@mixin fontFamilyRegular {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

@mixin border-btn {
  border: 1px solid $color06;
  background-color: $colorWhite;
  border-radius: $borderRadius;
}

@mixin no-results {
  &.no-border {
    border: none;
  }

  .emoji {
    font-size: 40px;
    font-style: normal;
    margin-bottom: 10px;
  }

  h1 {
    font-size: $fontSizeTitle;
    @include fontFamilyBold;
    margin-bottom: 10px;
    letter-spacing: 0.2px;
  }

  .notes {
    letter-spacing: 0.1px;
    font-size: $fontSize;
    color: $color06;
  }
}

@mixin new-msg {
  &:before {
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    background-color: transparent;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    right: 12px;
    transition: background-color 0.5s
  }

  &.new:before {
    background-color: $colorBright2_10;
  }

  &.new--usd:not(.new--warnings):before {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='7.755' cy='7.755' r='7.245' fill='%23FC694F'/%3E%3Cpath fill='%23FFF' d='M6.41 8.76c-.007.38.093.68.3.9.207.22.463.36.77.42V7.99a2.155 2.155 0 0 0-.32-.09 8.087 8.087 0 0 1-.73-.235c-.24-.09-.455-.21-.645-.36a1.66 1.66 0 0 1-.455-.56c-.113-.223-.17-.502-.17-.835 0-.34.065-.64.195-.9a2 2 0 0 1 .515-.655 2.35 2.35 0 0 1 .74-.41c.28-.097.57-.155.87-.175V3h.59v.77c.3.033.582.098.845.195s.495.23.695.4c.2.17.363.382.49.635s.2.553.22.9H8.9a1.052 1.052 0 0 0-.25-.68.738.738 0 0 0-.58-.28v1.77l.245.06c.083.02.172.043.265.07.493.133.873.298 1.14.495.267.197.463.402.59.615.127.213.202.427.225.64.023.213.035.403.035.57 0 .147-.037.332-.11.555a1.871 1.871 0 0 1-.395.655c-.19.213-.445.403-.765.57-.32.167-.73.267-1.23.3v.85h-.59v-.85c-.747-.053-1.33-.283-1.75-.69-.42-.407-.663-1.003-.73-1.79h1.41zm1.66 1.32c.12-.013.245-.042.375-.085.13-.043.247-.105.35-.185a.99.99 0 0 0 .255-.295.823.823 0 0 0 .1-.415c0-.26-.082-.455-.245-.585-.163-.13-.442-.248-.835-.355v1.92zm-.59-5.14a1.1 1.1 0 0 0-.33.05.87.87 0 0 0-.29.155.748.748 0 0 0-.205.26.83.83 0 0 0-.075.365c0 .22.07.39.21.51.14.12.37.217.69.29V4.94z'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    height: 15px;
    width: 15px;
    top: 3px;
    animation: pulse 2s infinite;
    z-index: 1;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.9);
    }

    50% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.9);
    }
  }

  &.new--usd:not(.new--warnings):after {
    content: "";
    display: block;
    width: 21px;
    height: 21px;
    background-color: rgba(252, 105, 79, 0.6);
    border-radius: 50%;
    position: absolute;
    top: -1px;
    right: 8px;
    border-radius: 50%;
    transition: background-color 0.5s;
    z-index: 0;
    animation: circle-anim 2s infinite cubic-bezier(0.18, 0.89, 0.32, 1.28);
  }

  @keyframes circle-anim {
    0% {
      transform: scale(0.5);
      border: 1px solid rgba(252, 105, 79, 0.8);
    }

    100% {
      transform: scale(1);
      border: 1px solid rgba(252, 105, 79, 0.0);
      background-color: rgba(252, 105, 79, 0.0);
    }
  }

  &.new--warnings:not(.new--usd):before {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3E%3Cpath fill='%23FC694F' fill-rule='evenodd' d='M7.5 15a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15zM6.336 5.682L6.59 9h1.793l.281-3.318v-2.96H6.336v2.96zm.053 4.481v2.114h2.248v-2.114H6.39z'/%3E%3C/svg%3E%0A");    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    height: 15px;
    width: 15px;
    top: 3px;
    z-index: 1;
  }
}

@mixin keyframeMarginTop($startValue) {
  animation: marginTop 1s;
  @keyframes marginTop {
    0% {
      margin-top: $startValue;
    }
    100% {
      margin-top: 0px;
    }
  } 
}

@mixin dropdown-footer {
  .dropdown-footer {
    height: 60px;
    border: solid 1px $color05;
    border-radius: $borderRadius;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    .btn+.btn {
      margin-left: 10px;
    }
  }
}

@mixin common-card {
  padding: 12px 16px;
  position: relative;
  display: block;
  box-shadow: inset 0 -1px 0 0 #e6e8eb;
}

@mixin person-common-info {
  .person-common-info {
    letter-spacing: 0.1px;
    color: $color07;
    margin-bottom: 20px;

    .text-danger {
      color: #fc694f;
    }

    .cnt-symbols {
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.1px;
      text-align: right;
      color: #00af6f;

      &.text-danger {
        color: #fc694f;
      }
    }

    .textarea {
      padding: 10px;
      height: 90px;
      border-radius: 3px;
      width: 100%;
      border: 1px solid #c0c7ce;
      resize: none;
      margin-top: 7px;

      &.is-completed {
        background-color: #feffe7;
      }
    }

    .person-common-info-title {
      font-size: 10px;
      font-weight: 900;
      letter-spacing: 0.1px;
      text-align: right;
      color: #9ca9b5;
      text-transform: uppercase;
      max-width: 145px;
      padding-top: 2px;
    }

    // li {
    //     margin-bottom: 8px;
    // }

    .dropdown {
      display: inline;
    }

    .dropdown-narrow {
      height: auto;
      word-break: break-all;
    }

    .dropdown-content {
      padding: 10px;

      .from-to {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          margin: 0 10px;
        }

        .input-text {
          width: 90px;
        }
      }

      .input-email {
        width: 280px;
      }
    }

    .verify-btn {
      font-size: $fontSizeL!important;
    }

    .empty-verify-btn {
      border: none !important;
      pointer-events: none;
    }
  }
}

@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  width:  $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%; 
  list-style: none;       
  
  > * {
    display: block;
    position: absolute;
    top:  50%; 
    left: 50%;
    width:  $item-size;
    height: $item-size;
    margin: -($item-size / 2);
  
    $angle: (360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: 
          rotate($rot * 1deg) 
          translate($circle-size / 2) 
          rotate($rot * -1deg);
      }

      $rot: $rot + $angle;
    }
  }
}

@keyframes rightElements600 {
  0% {
    right: 400px;
  }
  100% {
    right: 600px;
  }
}
