.article-item-container {
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 1px solid $color05;
    font-size: 17px;
    line-height: 1.59;
    letter-spacing: 0.1px;
    color: $colorText;
    max-width: 800px;

    .article-item-image {
        margin-bottom: 25px;
        max-width: 100%;
    }

    .article-item-info {
        margin-bottom: 0;
    }

    .article-item-text {
        white-space: pre-wrap;
        word-break: break-word;
    }

    p {
        margin-bottom: 25px;
    }

    .title {
        color: $color09;
        margin-bottom: 20px;
    }

    .badge-new {
        margin-right: 10px;
    }
}

.article-item-footer {
    text-align: center;

    .sub-title {
        font-size: 18px;
        @include fontFamilyBold;
        margin-bottom: 5px;
    }

    .notes {
        @include fontFamilyMedium;
        margin-bottom: 5px;
        color: $color07;
        font-size: $fontSizeSm;
        margin-bottom: 20px;
    }

    .emotion {
        display: inline-block;
        margin: 0 10px;
        font-size: 35px;
    }
}

.article-item-author {
    font-size: 17px;
    line-height: 1.59;
    letter-spacing: 0.1px;
    color: $colorText;
}

.article-item {
    padding: 20px;
    border-top: 1px solid $color05; 
    border-left: 1px solid $color05; 
    border-right: 1px solid $color05; 

    &:last-child {
        border-bottom: 1px solid $color05; 
    }

    .article-item-author {
        font-size: $fontSizeXSm;
        line-height: 1.92;
        @include fontFamilyMedium;
    }

    .article-item-info {
        margin-bottom: 5px;
    }

    .badge-new {
        margin-right: 10px;
    }

    .article-item-image {
        max-height: 80px;
        overflow: hidden;
        background-color: $color05;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 !important;

        .active-notification {
            fill: $color06;
            width: 52px;
            height: 52px;
        }


        img {
            width: 100%;
        }
    }

    .article-item-lnk {
        font-size: 17px;
    }

    .article-item-preview {
        font-size: $fontSize;
        line-height: 1.53;
        letter-spacing:0;
        color: $colorText;
        margin-top: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 960px;
        overflow: hidden;
    }

    a {
        text-decoration: none;
    }

    a:hover .article-item-lnk {
        text-decoration: underline;
    }
}

