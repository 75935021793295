$border: 1px solid $color05;

.table {
  .table-header,
  .table-row {
    padding: 10px 15px;
    font-size: $fontSizeSm;
    color: $colorText;

    &.cancelled {
      background-color: #fff7f6;
    }

    .small-col {
      max-width: 26px;
    }
  }

  .table-header {
    a:hover,
    a {
      color: $colorText;
      text-decoration: none;
      position: relative;
    }
  }

  .table-row {
    transition: all $transitionTime, border 0ms, padding 0ms;
    cursor: pointer;
    position: relative;
    border: $border;
    border-top: none;
    background-color: $color03;

    .order-number {
      &.new {
        display: inline-block;
        position: relative;
        @include new-msg;

        &:before {
          right: -10px;
          top: 0;
        }
      }
    }

    @mixin row-left-border {
      &:before {
        content: "";
        position: absolute;
        width: 4px;
        height: 100%;
        left: 0;
        top: 0;
      }
    }

    &:last-child {
      border-bottom-left-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
    }

    & .order-number {
      display: flex;

      &__text {
        max-width: 112px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    /* disabled row */
    &.disabled {
      background-color: $color04;
      cursor: default;

      > div {
        opacity: 0.4;

        &:last-child {
          opacity: 1;
        }
      }
    }

    &.disabled:hover {
      color: $colorText !important;
      box-shadow: none !important;
    }

    &.disabled + .table-row {
      border-top: $border;
    }
    /* ./disabled row */

    &.is-advert {
      background-color: $color03;
    }

    &.not-read {
      @include fontFamilyBold;
      background-color: #fff;
    }

    &.new-order {
      background-color: #e5f7f0;
      position: relative;

      &--with-animation {
        animation: showNewOrders 1s;
      }

      @keyframes showNewOrders {
        0% {
          max-height: 0;
          padding: 0;
          border: none;
        }
        100% {
          max-height: 41px;
          border: $border;
          padding: 10px 15px;
        }
      }

      &--viewed {
        background-color: $color03;
      }

      @include row-left-border;

      &:before {
        background-color: $colorBright1_09;
      }
    }

    &.taken {
      cursor: default;
      pointer-events: none;
      font-weight: 500;
      color: $color06;
      min-height: 41px;
      overflow: hidden;

      @include row-left-border;

      &:before {
        background-color: $color06;
      }

      &--hide {
        color: transparent;
        animation: hideOrders 1s;
        padding: 0;
        border: none;
        min-height: 0;
        max-height: 0;
      }

      @keyframes hideOrders {
        0% {
          min-height: 41px;
          border: $border;
          padding: 10px 15px;
        }
        100% {
          padding: 0;
          border: none;
          min-height: 0;
        }
      }
    }
    &.is-success {
      background-color: $colorBright1_08;

      .combined-shape {
        fill: $colorBrightOpacity20_1_09;
        stroke: $colorBright1_09;
        transform: rotate(200grad);
      }

      .row {
        > div:nth-child(2) {
          color: $colorBright1_09;
        }

        > div:nth-child(3) {
          color: $colorBright1_09;
          @include fontFamilyBold;
        }
      }
    }
    .col-1 {
      .time-icon {
        background-repeat: no-repeat;
        background-size: contain;
        width: 17px;
        height: 17px;
      }
    }

    .max-200-tooltip {
      max-width: 200px!important;
    }

    .fit-content-tooltip {
      height: fit-content!important;
    }

    .list-tooltip {
      li {
        line-height: 18px;
        margin-bottom: 2px;
        
        &:before {
          content: "";
          background-image: url("data:image/svg+xml,%3Csvg width='4' height='4' viewBox='0 0 4 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='2' cy='2' r='2' fill='%23637280'/%3E%3C/svg%3E%0A");
          min-width: 5px;
          height: 5px;
          display: block;
          background-repeat: no-repeat;
          margin-right: 10px;
          margin-top: 7px;
          background-size: contain;
        }
      }
    }

    .white-space-normal-tooltip {
      white-space: normal!important;
    }

    &.reserve-by-me {
      .col-4.reserve-time {
        color: $colorBright_1_07;
      }
      .col-1 {
        .time-icon {
          background-image: url("./img/icons/time_green.svg");
        }
      }
    }

    &.reserved {
      .col-4.reserve-time {
        color: #9ca9b5;
      }
      .col-1 {
        .time-icon {
          background-image: url("./img/icons/time.svg");
        }
      }
    }

    &.pinned {
      + :not(.pinned) {
        border-top: $border;
        border-top-width: 2px;
      }
      .col-1 {
        .pin-icon {
          background-image: url("./img/icons/pin.svg");
          background-repeat: no-repeat;
          background-size: contain;
          width: 17px;
          height: 17px;
        }
      }
    }
    .col-6 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 215px;

      .text-ellipsis {
        max-width: 160px;
      }

      .tags-list {
        max-height: 14px;
        margin-top: 0;

        *:not(.tooltip-content) {
          max-height: inherit;
        }
        .svg-icon {
          margin-right: 2px;
          &:not(:last-child) {
            margin-right: 0;
          }
        }
      }
    }

    .open-new-window {
      margin-left: 10px;
      opacity: 0;
    }

    &:hover .open-new-window {
      opacity: 1;
    }

    &.disabled:hover .open-new-window {
      opacity: 0;
    }

    .table-row-notification {
      &:after {
        content: "";
        display: block;
        width: 5px;
        height: 5px;
        background-color: $colorBright2_10;
        border-radius: 50%;
      }
    }
  }

  .btn-show-next {
    margin-bottom: 35px;
  }

  .table-filter {
    padding: 15px;
    border-bottom: 1px solid #e6e8eb;
    min-height: 71px;
    display: flex;
    align-items: center;

    .switch-filter {
      display: flex;
      align-items: center;
      color: $color08;

      &__name {
        font-size: 12px;
        margin-right: 5px;
      }

      &--active {
        color: $colorAction11;
      }
    }

    .form-search {
      .btn.btn-bright {
        opacity: 1;
        animation: $transitionTime linear opacityElements;
      }
    }

    .search-wrap {
      position: relative;
      display: inline-block;
      margin-right: 10px;

      .input-text {
        width: 250px;
        animation: 0.3s cubic-bezier(0.18, 0.89, 0.32, 1) linearWidth;
        padding-left: 30px;
        font-size: $fontSizeSm;
      }

      @keyframes opacityElements {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      @keyframes linearWidth {
        0% {
          width: 30px;
        }
        100% {
          width: 250px;
        }
      }

      .search {
        position: absolute;
        top: 7px;
        left: 8px;
      }

      .btn-close {
        position: absolute;
        top: 1px;
        right: 0;
        background-color: transparent;
        opacity: 1;
        animation: 0.3s linear transformElement;
      }

      @keyframes transformElement {
        0% {
          transform: rotate(0) scale(0, 0);
        }
        100% {
          transform: rotate(-100grad) scale(1, 1);
        }
      }

      .close {
        width: 10px;
        height: 10px;
        fill: $color06;
      }
    }
  }

  // orders-table
  &.orders-table {
    .table-row {
      &.active,
      &:hover {
        background-color: $colorAction03;
      }
    }
  }
  // ./orders-table

  &.finances-table {
    .table-row {
      cursor: auto;

      &.active {
        background-color: $color03;
        align-items: flex-start;
      }
    }

    .balance {
      font-size: 14px;
      color: $color09;

      b {
        font-size: 13px;
      }
    }

    .ReactCollapse--content {
      padding-top: 12px;
    }

    .finance-row-title {
      word-break: break-all;
    }
  }

  &.progress-table {
    .table-header {
      border-top: $border;
      border-bottom: $border;
    }

    .table-row {
      border-bottom: 1px solid $color05;
      border-left: none;
      border-right: none;
      border-radius: 0;
      padding: 15px;
      cursor: auto;
      word-break: break-all;

      &.is-completed {
        color: $color07;

        .money-income {
          fill: $color07;
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
