.success-wrap {
  position: fixed;
  margin: 0 auto;
  width: 100%;
  z-index: 10;
  text-align: center;
  top: 50px;

  .check-fill {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    position: relative;
    top: 18px;

    &.success {
      margin-right: 10px;
    }
  }

  a {
    color: #00af6f;
    text-decoration: none;
  }
}

.success-container {
  padding: 17px 20px 17px 0px;
  display: block;
}

.success-reject-order-container {
  position: relative;
  border-radius: 10px;
  margin-left: -30px;
  background-color: #ecfff8;
}


.success-content {
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  max-width: 560px;
  border-radius: 10px;
  padding-left: 20px;
  border: solid 1px #00af6f;
  background-color: #ecfff8;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.12px;
  color: #00af6f;
  text-align: left;
  line-height: 1.4;

  a {
    color: #389bfe;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  animation: topElements 1s ease-in-out;

  @keyframes topElements {
    0% {
      top: -100%;
    }
    100% {
      top: 50px;
    }
  }
}
