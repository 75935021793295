.full-arrow {
    display: block;
    position: relative;
    top: 0px;
    width: 13px;
    height: 8px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('./img/icons/full-arrow-up.svg');
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
}
.full-arrow-down {
    background-image: url('./img/icons/full-arrow-down.svg');
}

.full-arrow-up {
    background-image: url('./img/icons/full-arrow-down.svg');
    transform: rotate(-180deg) translateY(50%);
}

.arrow-down {
    display: block;
    width: 6px;
    height: 7px;
    border-right: 1px solid $color07;
    border-bottom: 1px solid $color07;
    transform: rotate(45deg);
}

.arrow-down-xs {
    background-color: $colorAction08;
    display: inline-block;
    width: 12px;
    height: 12px;
    position: relative;
    border-radius: 50%;
    transition: transform $transitionTime;

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 4px;
        top: 3px;
        width: 3px;
        height: 3px;
        border-right: 1px solid $colorWhite;
        border-bottom: 1px solid $colorWhite;
        transform: rotate(45deg);
    }
}

.arrow-right {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px;
    border-color: transparent transparent transparent #2a86e1;
    margin: 0 0 0 5px;
}


