@import 'scss/_variables.scss';

.upload-files-modal {
  max-width: 344px;
  transition: opacity .3s;

    &--submitting {
      opacity: 0.5;
      pointer-events: none;
    }
    
    &__header {
      font-size: $fontSizeXL;
      @include fontFamilyMedium;
      line-height: 28px;
    }

    &__text {
      font-size: $fontSizeL;
      line-height: 24px;
    }

    &__file-counter {
      font-size: $fontSizeMd;
      line-height: 24px;
      @include fontFamilyMedium;
    }

    &__grey-text {
      color: $colorText;
    }

    .upload-files-modal-file-list {
      max-height: 150px;
      overflow: auto;
      padding-right: 5px;

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    
      &::-webkit-scrollbar {
        width: 4px;
        background-color: #F7F9FA;
      }
  
      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #E3E3E3;
      }

      .upload-files-modal-file-item {
        border-radius: 4px;
        border: 1px solid $color11;
        padding: 3px 6px 3px 4px;
        background-color: $colorWhite;
        transition: all .3s;
  
        &:hover {
          background-color: $colorAction04;
  
          .upload-files-modal-file-item__size {
            color: $colorText;
          }
        }

        .reload {
          width: 16px;
          height: 16px;
          fill: $color07;
          cursor: pointer;

          &:hover {
            fill: $colorAction10;
          }
        }
  
        &__name {
          font-size: $fontSizeMd;
          white-space: nowrap;
  
          &--error {
            color: $color07;
          }
          
          &--uploading {
            color: $colorText;
          }
        }
  
        &__size {
          font-size: $fontSizeXSm;
          color: $color07;
          transition: all .3s;
  
          &--error {
            color: $colorBright2_08!important;
          }
        }
      }
    }

    .confirm-modal {
      
      &__header {
        font-size: $fontSizeXL;
        @include fontFamilyMedium;
        line-height: 28px;
        color: $color09;
        margin-bottom: 24px;
      }

      &__text {
        font-size: $fontSizeL;
        line-height: 24px;
        color: $colorTextDark;
      }

      &__sub-text {
        font-size: $fontSizeMd;
        line-height: 20px;
        color: $colorText;
        margin-top: 16px;
      }
    }

}
