@import 'scss/_variables.scss';

.input-block {
  padding: 12px 0;

  position: relative;

  &__label {
    font-weight: bold;
    font-size: $fontSizeXL;
  }

  &--error {
    button {
      border-color: $colorBright2_08;
      color: $colorBright2_08;
    }
  }

  &__error-text {
    position: absolute;
    top: 33px;
    
    font-size: 11px;
    font-weight: bold;

    color: $colorBright2_08;
  }

  &.input-block-calendar {
    .dropdown .btn-block {
      padding-left: 30px;
  
      font-size: 14px;
      line-height: 14px;
  
      max-width: 254px;
    }
  
    .dropdown-content {
      left: 0!important;
      right: unset!important;
    }
  }


  .calendar {
    width: 15px;
    height: 15px;
    position: absolute;
    left: 10px;
    top: 6px;
  }


  .input-container {
    display: flex;
    align-items: center;
    padding-left: 18px;

    .input-container-item {
      display: flex;
      align-items: center;

      &__dropdown {
        max-height: 163px;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 0;
        }
      }


      .menu {
        padding: 0;
      }

      &__input {
        height: 30px;
        width: 40px;
        line-height: 13px;

        padding: 7px 10px;
        
        border: 1px solid $color06;
        border-radius: 4px;

        color: $colorText;

        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}
