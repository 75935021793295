@import 'scss/_variables.scss';

.verify-form-container {
  position: relative;

  padding-left: 28px!important;
  padding-right: 28px!important;


  &__header {
    @include fontFamilyBold;

    font-size: $fontSizeXL;

    margin-bottom: 14px;
  }

  .verify-form-container-form-group {
    padding: 10px 0;

    &__label {
      display: block;

      margin-bottom: 8px;

      color: $colorTextLightGrey;

      font-size: $fontSizeMd;
    }

    &__input {
      width: 100%;
    }
  }
  &__description {
    color: $colorTextLightGrey;

    margin-top: 8px;

    font-size: $fontSizeMd;

    &--error {
      color: $colorBright2_08;
    }
  }

  &__resubmissions_count_warning {
    color: $colorBright2_10;
    max-width: 310px;
  }

}
