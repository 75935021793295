.goToMobileContainer {
  position: fixed;
  top: -125px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  color: #fff;
  background-color: $colorBright1_10;
  opacity: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: all $transitionTime;
  border-radius: 8px;
  z-index: 2;

  &--show {
    opacity: 1;
    top: 20px;
  }

  & a {
    color: #fff;
    text-decoration: underline;
  }
}
