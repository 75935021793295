@import 'scss/_variables.scss';

.card-estimate-container {
  
  &--active {
    margin-bottom: 15px;
  }

  &__header {
    font-size: $fontSizeL;
    @include fontFamilyBold;
    
    color: $colorDark;

  }

  &__title {
    font-size: $fontSizeMd;
    color: $colorDark;

    margin-top: 24px;

    margin-bottom: 4px;

  }

  &__size {
    font-size: $fontSizeMd;
    color: $colorText;
  }

  &__header-icon {
    width: 16px;
    height: 16px;

    margin-right: 8px;

    background-repeat: no-repeat;
    background-position: center;

    &--active {
      width: 0;
      height: 0;
  
      margin-right: 0;
    }

    &--canceled, &--expired, &--declined {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z' fill='%23ED2C39'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.76744 4.76772C4.4327 5.10246 4.4327 5.64517 4.76744 5.9799L6.78763 8.0001L4.76753 10.0202C4.4328 10.3549 4.4328 10.8976 4.76753 11.2324C5.10227 11.5671 5.64498 11.5671 5.97972 11.2324L7.99981 9.21228L10.0202 11.2327C10.355 11.5674 10.8977 11.5674 11.2324 11.2327C11.5671 10.898 11.5671 10.3552 11.2324 10.0205L9.212 8.0001L11.2325 5.97958C11.5672 5.64485 11.5672 5.10214 11.2325 4.7674C10.8978 4.43267 10.3551 4.43267 10.0203 4.7674L7.99981 6.78791L5.97962 4.76772C5.64488 4.43299 5.10217 4.43299 4.76744 4.76772Z' fill='white'/%3E%3C/svg%3E%0A");
    }

    &--accepted {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' rx='8' fill='%23489900'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.6834 5.04887C12.0508 5.34738 12.1067 5.88721 11.8081 6.25461L7.52881 11.5214L4.2877 8.64045C3.93388 8.32595 3.90201 7.78418 4.21652 7.43036C4.53102 7.07655 5.07279 7.04468 5.42661 7.35918L7.32836 9.04963L10.4777 5.17359C10.7762 4.80619 11.316 4.75035 11.6834 5.04887Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }

  &__active-btn {
    background: rgba(247, 181, 0, 0.2);
    border-radius: 8px;

    width: 256px;
    height: 40px;
    line-height: 40px;

    font-size: $fontSizeMd;

    text-align: center;

    background: rgba(247, 181, 0, 0.2);
    color: #E09600;

    margin: 25px auto 0 auto;
  }
}
