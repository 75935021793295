.side_bar {
  width: 330px;
  position: absolute;
  left: 0;
  height: 100vh;
  z-index: 2;

  background-color: #39444d;

  &__bar_controls {
    padding: 12px 20px 9px 20px;
    background-color: #42525d;
    font-size: 13px;
    display: flex;
    min-height: 47px;
    align-items: center;
    color: #fff;

    @import './controls.scss';
  }
  @import './mini-previews-container.scss';
}
