.badge {
    display: inline-block;
    border-radius: 2px;
    width: 100%;
    max-width: 110px;
    height: 20px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: $colorWhite;
    font-size: 10px;
    @include fontFamilyMedium;
    cursor: default;

    &.badge-suggested {
        border: solid 1px #fc694f;
        color: #fc694f;
        background: transparent;
    }

    &.badge-recommended {
        display: flex;
        align-items: center;
        background-color: $colorBright2_10;

        .hot-recommended {
            margin: 0 5px;
        }
    }

    &.badge-experienced {
        border: solid 1px $colorExtraLight;
        color: $colorText;
    }

    &.badge-urgent-hot {
        border: solid 1px $colorBright2_10;
        color: $colorBright2_10;
        .emoji {
            font-size: 16px;
            line-height: 15px;
            vertical-align: top;
        }
    }

    &.badge-vip {
        border: solid 1px $colorBright3_02;
        color: $colorBright3_01;

        .emoji {
            font-size: 14px;
            line-height: 15px;
            vertical-align: top;
        }
    }

    &.badge-updated {
        border: solid 1px $colorBright1_10;
        color: $colorBright1_10;
    }

    &.badge-refund-tag {
        border: solid 1px $colorBright2_10;
        color: $colorBright2_10;
        // width: auto;
        // height: auto;
        white-space: nowrap;
        padding: 0 3px;
    }

    &.badge-new {
        height: 25px;
        width: 45px;
        background-color: $colorBright2_10;
        text-transform: uppercase;
        font-size: 10px;
        @include fontFamilyMedium;
        line-height: 25px;
        letter-spacing: 0.6px;
        vertical-align: middle;
    }

    &.badge-answer {
        background-color: $colorBright2_06;
        border-radius: 100px;
        padding: 10px 22px;
        height: 40px;
        color: $color09;
        text-transform: initial;
        font-size: 14px;
        width: auto;
        max-width: none;
        @include fontFamilyRegular;
    }

    &.plag-report {
        background-color: $colorAction03;
        color: $colorBright2_10;
        text-decoration: underline;
        width: auto;
        padding: 0 10px;
        cursor: pointer;
    }
}
