.comment-block {
  &__label {
    font-weight: bold;
    font-size: $fontSizeXL;
  }

  &__hint {
    color: $colorText;
    font-size: $fontSizeXSm;
  }

  &__comment-size-wrap {
    position: relative;
    margin-bottom: 18px;
  }

  &__comment-size {
    color: $colorBright1_09;
    font-size: $fontSizeXSm;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.12px;
    position: absolute;
    bottom: 14px;
    right: 9px;
  }

  .comment-block-comment {
    width: 100%;
    height: 110px;
    border: 1px solid rgba(99, 114, 128, 0.4);
    border-radius: 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.12px;
    color: #3b464e;
    // text-align: center;
    // line-height: calc(80px - 16px);
    resize: none;
    padding: 8px 8px 20px;

    // &--with-text {
    //   line-height: normal;
    //   text-align: left;
    // }

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
