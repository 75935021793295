@import 'scss/_variables.scss';

.step-container {  
  &__title {
    color: $color08;
    font-size: $fontSizeXSm;
    margin-bottom: 8px;
  }

  &__tips {
    font-size: $fontSizeXXs;
    color: #9CA9B6;
    margin-bottom: 2px;
  }

  &__green-text {
    color: #14B479;
    font-size: $fontSizeXSm;
  }

  .step-container-list {
    color: #6C7680;
    margin-bottom: 4px;

    &__item {
      @include fontFamilyMedium;

      &:not(:last-child) {
        margin-bottom: 2px;
      }
  
      &:before {
        content: '';
        min-width: 4px;
        height: 4px;
        margin-top: 2px;
        margin-right: 8px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='4' height='4' viewBox='0 0 4 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='2' cy='2' r='2' fill='%23637280'/%3E%3C/svg%3E%0A");
      }
    }
  }
}
