.inputs-time-container {
  &__input-wrap {
    display: flex;
    align-items: center;

    > span {
      margin: 0 5px;
    }
  }

  &__title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #637280;

    &--disabled {
      color: #9CA9B5;
    }
  }

  &__input-container {
    position: relative;
    display: flex;
    align-items: center;

    input {
      width: 56px;
      height: 30px;
      border-radius: 3px;
      border: solid 1px #389bfe;
      background-color: #fff;
      color: #389bfe;
      padding: 0 10px 0 8px;
      outline: none;
      font-size: 14px;
    }

    ::-webkit-input-placeholder {
      color: #389bfe;
    }
    ::-moz-placeholder {
      color: #389bfe;
    }
    :-moz-placeholder {
      color: #389bfe;
    }
    :-ms-input-placeholder {
      color: #389bfe;
    }

    span {
      position: absolute;
      font-size: 14px;
      color: #389bfe;
      left: 26px;
    }

    &.disabled {
      input {
        border: solid 1px #9ca9b5;
        color: #9ca9b5;
        cursor: auto;
      }
      ::-webkit-input-placeholder {
        color: #9ca9b5;
      }
      ::-moz-placeholder {
        color: #9ca9b5;
      }
      :-moz-placeholder {
        color: #9ca9b5;
      }
      :-ms-input-placeholder {
        color: #9ca9b5;
      }
      span {
        color: #9ca9b5;
      }
    }
  }
}
