.tags-list {
  display: flex;
  align-items: center;
  margin-top: 8px;

  .svg-icon {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }

  &:empty {
    display: none;
  }
}
