html {
  min-width: $layoutWidth;
}

body {
  height: 100vh;
}

body,
#root,
#router {
  display: flex;
  flex-direction: column;
  flex: 1;
}

mark {
  background-color: $colorMark;
  padding: 4px 0;
}

#app {
  flex: 1;
}

.container {
  width: $layoutWidth;
  margin: 0 auto;
}

.single-page-container {
  width: 600px;
  position: relative;
}

.single-page {
  background-color: $color08Opacity007;

  > .container {
    //box-shadow: 0 2px 9px 0 rgba(99, 114, 128, 0.2);
    margin-bottom: 60px;
    //background-color: $colorWhite;
    //border-radius: 4px;
  }

  .logo {
    background-image: url("./img/icons/albatros.svg");
    height: 70px;
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    width: 60px;
    margin: 0 auto;
  }

  footer {
    background-color: $color03;
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 30px;
    z-index: 2;
  }
}

.container-border {
  border: 1px solid $color05;
  border-radius: 0 0 $borderRadius $borderRadius;
  margin-bottom: 20px;
}

.sidebar-border {
  border-bottom: 1px solid $color05;
}

.content-item {
  padding: 0 25px 25px;
}

.left-sidebar {
  // border-right: 1px solid $color05;
  flex: 1.5 1 0%;
}

.right-content {
  flex: 6 1 0%;
}

.padding-content {
  padding: 25px;
}

.hide-scroll {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.overflow {
  overflow: hidden;
}

.hide-scroll-agreement {
  overflow: hidden;
}

.pre {
  white-space: pre-wrap;
}

.center-content {
  text-align: center;
}

.dotted-devider {
  margin: 0 5px;
  display: inline-block;
  position: relative;
  @include fontFamilyBold;
}

.lnk-dashed {
  text-decoration: none;
  border-bottom: 1px dashed;
  display: inline-block;

  &:hover {
    text-decoration: none;
    border-bottom: none;
  }
}

.emojiFont {
  font-size: $fontSizeEmoji;
}

.lnk-collapse {
  position: relative;
  text-decoration: underline;
  display: inline-block;

  &:hover {
    text-decoration: none;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: -13px;
    top: 6px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid $colorAction10;
    transform: rotate(-90deg);
    transition: transform $transitionTime;
  }

  &.active:after {
    transform: rotate(0);
  }
}

textarea {
  &:focus {
    outline: none;
  }

  &:disabled {
    border: 1px solid #e6e8eb;
    background-color: #f7f9fa;
    color: #c0c7ce;
    cursor: not-allowed;
  }
}

.lablе-box {
  font-size: $fontSize;
  color: $color09;
  line-height: 20px;
  display: flex;
  align-items: center;
  height: $blockHeight;
  @include fontFamilyMedium;
}

.upto-wrap {
  position: relative;
  display: none;

  .upto-wrap-inner {
    position: absolute;
    right: 50px;

    .btn-upto {
      position: absolute;
      bottom: 17px;
    }
  }
}

.switch-acc {
  display: flex;
  align-items: center;
  margin-right: 20px;
  padding: 6px 8px 7px;

  &:hover {
    background-color: #eaf7ff;
    border-radius: 3px;
    text-decoration: none;
  }

  .acc_switch {
    width: 16px;
    height: 14px;
    margin-right: 10px;
  }
}

.personal-info {
  position: relative;

  .personal-box {
    position: absolute;
    right: 0;
    top: -37px;
    display: flex;
    justify-content: center;

    .dropdown {
      .btn.btn-block {
        padding: 5px 10px 7px 10px;

        &:hover {
          background-color: $colorAction10;
          border-color: $colorAction10;

          .svg-icon {
            fill: $colorWhite;
          }
        }
      }

      .dropdown-content {
        left: auto;
        right: 0;
      }

      .menu-item {
        a {
          display: block;
        }
      }
    }

    .logout,
    .settings {
      margin-right: 9px;
      top: 1px;
    }

    .empty-verify-btn {
      border: none!important;
      pointer-events: none;
    }
  }

  .arrow-down {
    display: none;
  }

  .btn-light {
    padding: 3px 15px 7px 10px;
    margin-right: 10px;

    .working-status {
      top: 3px;
      margin-right: 5px;
    }
  }

  .btn-notification {
    margin-right: 10px;
  }
}

.title {
  font-size: $fontSizeTitle;
}

.title-md {
  font-size: 17px;
}

.title-devider {
  letter-spacing: 0.1px;
  color: $color07;
  position: relative;
  margin-bottom: 13px;

  span {
    background-color: $colorWhite;
    position: relative;
    z-index: 1;
    padding-right: 10px;
  }

  &:after {
    content: "";
    display: inline-block;
    height: 2px;
    width: 100%;
    background-color: $color13;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -1px;
  }

  &.text-warning {
    color: $colorBright2_10;

    &:after {
      background-color: $colorBright2_10;
    }
  }
}

.collapse {
  .row {
    margin: 0 0 5px;

    & > div:last-child {
      @include fontFamilyMedium;
    }
  }

  .group {
    margin: 20px 0;
  }
}

.row-border {
  padding: 0 0 20px;
  border-bottom: 1px solid $color05;
}

.bg-context {
  padding: 10px;
  border-radius: $borderRadius;
  text-align: center;
  font-size: $fontSizeXSm;
  @include fontFamilyMedium;
  line-height: 15px;
  background-color: $color12;
  border: solid 1px $color11;
}

.bg-danger {
  color: $colorBright2_10;
  background-color: $colorBrightOpacity055_2_10;
}

.bg-warning {
  color: $colorBright2_09;
  background-color: $colorBrightOpacity01_2_09;
}

.bg-success {
  color: $colorBright1_10;
  background-color: $colorBrightOpacity008_1_10;
}

.bg-info {
  background-color: $colorAction04;
  color: $color09;
}

.alert-danger {
  color: $color09;
  background-color: $colorBrightOpacity013_2_09;
  border: solid 1px $colorBright2_09;
  display: block;
  @include fontFamilyRegular;

  b {
    @include fontFamilyMedium;
  }
}

.file-icon {
  background-position: 15px center;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  word-break: break-all;

  &:hover {
    text-decoration: none;
  }

  &.pptx-icon {
    background-image: url("./img/icons/pptx-icon.svg");
  }

  &.docx-icon {
    background-image: url("./img/icons/docx-icon.svg");
  }

  &.pdf-icon {
    background-image: url("./img/icons/pdf-icon.svg");
  }

  &.paper-icon {
    background-image: url("./img/icons/paper.svg");
    background-position: 20px center;
  }
}

.voit-box {
  span {
    cursor: pointer;
    font-size: $fontSizeEmoji;
    margin: 3px;
  }
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: $colorBlackOpacity04;
  z-index: 2;

  &.overlay-tab {
    z-index: 0;
  }
}

.star-container {
  width: calc(20% - 3px);
  height: auto;
  display: inline-block;
  padding-right: 3px;
  box-sizing: content-box;
}

.stras_wrap {
  margin-bottom: 7px;
  .star-container {
    width: 8px;
  }
}

.learninglab {
  b {
    font-weight: 600;
  }

  .success-text {
    color: #14b479;
  }

  &__intro {
    margin-bottom: 40px;
  }

  &__title {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.28px;
    color: #637280;
    margin-bottom: 16px;
    line-height: 27px;
  }

  &__destail {
    margin-top: 20px;
  }

  &__desc {
    font-size: 15px;
    line-height: 1.56;
    letter-spacing: 0.05px;
    color: #4e4e4e;
    margin-bottom: 12px;
  }

  &__badge {
    width: 89px;
    height: 23px;
    border-radius: 4px;
    background-color: #e6e8eb;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.1px;
    padding: 4px 13px;

    &--not-started {
      color: #637280;
      background-color: #e6e8eb;
    }

    &--completed {
      color: #00af6f;
      background-color: #cff7e8;
    }

    &--in-progress {
      color: #2a86e1;
      background-color: #d6e9ff;
    }
  }

  &__disabled {
    color: #9ca9b5;
  }

  &__detail {
    font-size: 11px;
    line-height: 1.5;
    margin-top: 10px;
  }

  &__stages {
    display: flex;
    justify-content: center;
  }

  &__stage {
    padding-left: 27px;
    width: 71px;
    font-size: 11px;
    position: relative;

    &:first-child {
      .learninglab__fig {
        &:before {
          border-radius: 5px 0 0 5px;
        }
      }
    }

    &--notstart {
      color: rgba(99, 114, 128, 0.5);
      .svg-icon {
        fill: #e6e8eb;
      }

      .learninglab__fig {
        color: rgba(99, 114, 128, 0.5);
        &:before {
          background-color: #e6e8eb;
        }
      }
    }

    &--completed {
      .svg-icon {
        fill: #cff7e8;
      }

      .learninglab__fig {
        color: #14b479;
        &:before {
          background-color: #cff7e8;
        }
      }
    }

    &--progress {
      .svg-icon {
        fill: #ffd4cd;
      }

      .learninglab__fig {
        color: #fc694f;

        &:before {
          background-color: #ffd4cd;
        }
      }
    }
  }

  &__fig {
    position: relative;
    font-weight: 900;
    display: flex;
    align-items: center;

    &:before {
      content: "";
      display: flex;
      width: 30px;
      height: 6px;
      position: absolute;
      left: -28px;
    }

    .svg-icon {
      width: 44px;
      height: 26px;
    }

    &--stage {
      position: absolute;
      left: 32px;
      top: 35px;
    }

    &--text {
      display: inline-block;
      position: absolute;
      left: 3px;
      top: 7px;
      width: 39px;
      text-align: center;
    }
  }

  &__row {
    min-height: 95px;
    border-bottom: 1px solid #e9e9e9;
    color: #637280;
    padding: 20px 0;
  }

  .table {
    .table-header {
      padding: 10px 0;
    }
  }

  .row__title {
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0.12px;
    color: #000;
  }

  .arrow-left {
    display: inline-block;
    padding-left: 15px;
    position: relative;
    color: #fff;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: -10px;
      border: 4px solid transparent;
      border-left: 9px solid #2a86e1;
      transition: transform $transitionTime;
    }

    &.active {
      &:after {
        transform: rotate(100grad);
        top: -9px;
      }
      // &:after {
      //   border-left: 5px solid transparent;
      //   border-top: 9px solid #2a86e1;
      // }
    }
  }

  .accordion-trigger {
    display: inline-block;
    margin-bottom: 10px;
  }

  .accordion-content {
    padding-left: 15px;
  }

  .btn-learning {
    -webkit-appearance: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &.disabled {
      cursor: auto;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.upper-letter {
  &::first-letter {
    text-transform: uppercase;
  }
}

.text-light {
  color: $colorTextLightGrey;
}
