.frozen-container {
  text-align: center;
  width: 330px;
  padding: 20px !important;
  min-width: auto !important;
  margin: 0 auto;

  .frozen-icon {
    margin: 0 auto 25px;
    width: 50px;
  }

  .frozen-title {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .frozen-text {
    font-size: 15px;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 20px;

    &.frozen-text-bold {
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  .modal-icon {
    width: 54px;
    display: block;
    height: 54px;
    margin: 20px auto;
  }

  .frozen-footer {
    .frozen-footer-help {
      color: #9ca9b5;
    }
  }
}
