@import 'scss/_variables.scss';

.subjects-modal {

  &__steps {
    color: $colorText;
    @include fontFamilyBold;
    line-height: 1.5;
  }

  &__descriptions {
    line-height: 1.43;
    color: $colorText;
  }

  &__wrap-tooltip {
    white-space: pre !important;
    text-align: left;
  }

  &__items {
    .checkbox, .radiobutton {
      font-size: $fontSizeL;
      display: flex;
      align-items: center;
    }
  }

  &__footer {
    background-color: $color03;
    padding: 16px;
    margin: 30px -20px -30px;

    button {
      font-size: $fontSize!important;
    }
  }

  &__selected-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='53' height='13' viewBox='0 0 53 13'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg%3E%3Cg transform='translate(-1557 -310) translate(1557 310)'%3E%3Crect width='53' height='13' fill='%233CB328' rx='2'/%3E%3Ctext fill='%23FFF' font-family='Lato-Bold, Lato' font-size='10' font-weight='bold'%3E%3Ctspan x='4.055' y='10'%3EPRIMARY%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    width: 53px;
    height: 13px;
    display: block;
  }
}
