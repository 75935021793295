@import 'scss/_variables.scss';

.client-sessions-container {
  padding-top: 16px;

  &--loading {
    opacity: 0.7;
    pointer-events: none;
  }

  .client-sessions-list {
    &__title {
      margin-top: 16px;
      margin-bottom: 8px;

      font-size: 16px;
    }

    .session-item {
      padding: 8px 0 8px 0;

      color: $colorAction10;
    
      .tooltip-content {
        &.session-item__tooltip {
          top: auto!important;
          bottom: 20px!important;
          left: 0;
    
          white-space: normal;
          width: 226px;
          line-height: 20px;
        }
      }

      .session-container {

        &--live {
          a {
            color: $colorBright2_10;
          }
        }

        &--isOver {
          a {
            color: $colorExtraLight;
          }
        }

        &__date {
          min-width: 120px;

          a {
            width: 20%;
          }
        }

        &__copied {
          opacity: 0;
          top: 0;

          transition: all 300ms;

          position: absolute;

          color: $colorAction11;

          &--live {
            color: $colorBright2_10;
          }

          &--show {
            top: -70%;
            opacity: 1;
          }
        }
        
        .session-container-icon-block {
          margin-left: 16px;
  
          &__icon {
            width: 16px;
            height: 16px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
  
            margin-right: 8px;
  
            cursor: pointer;
  
            transition: opacity 300ms;

            position: relative;
  
            &:hover {
              opacity: 0.7;
            }
  
            &--copy {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%232a86e1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='9' y='9' width='13' height='13' rx='2' ry='2'%3E%3C/rect%3E%3Cpath d='M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1'%3E%3C/path%3E%3C/svg%3E");
            }
  
            &--edit {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%232a86e1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolygon points='16 3 21 8 8 21 3 21 3 16 16 3'%3E%3C/polygon%3E%3C/svg%3E");
            }

            &--delete {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%232a86e1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='3 6 5 6 21 6'%3E%3C/polyline%3E%3Cpath d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'%3E%3C/path%3E%3Cline x1='10' y1='11' x2='10' y2='17'%3E%3C/line%3E%3Cline x1='14' y1='11' x2='14' y2='17'%3E%3C/line%3E%3C/svg%3E");
            }
          }

          &__over-text {
            color: $colorExtraLight;
          }
        }
      }
    }
  }
}

@import './modal.scss';
