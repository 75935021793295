@import 'scss/_variables.scss';

.chat-closed-card {
  &__header {
    font-size: $fontSizeL;
    @include fontFamilyBold;
    
    color: $colorDark;
  }

  &__header-icon {
    width: 24px;
    height: 24px;

    background-repeat: no-repeat;
    background-position: center;

    margin-right: 8px;

    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.01' width='16' height='16' fill='%23D8D8D8'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.55513 15.8546H13.0199C13.8235 15.8546 14.4744 15.2034 14.4742 14.4001V6.83763C14.4742 6.03424 13.823 5.38308 13.0196 5.38308H12.7326C12.7325 5.34042 12.7316 5.29728 12.7306 5.25313L12.7306 5.25302V5.25297V5.25294C12.7296 5.20331 12.7285 5.15239 12.7285 5.09945C12.7285 2.36345 10.5343 0.145508 7.79828 0.145508C5.06301 0.145508 2.84531 2.36272 2.84434 5.09775H2.83513V5.38308H2.55513C1.75174 5.38308 1.10059 6.03424 1.10059 6.83763V14.4001C1.10059 15.2032 1.75174 15.8546 2.55513 15.8546ZM4.60725 5.09145C4.60798 3.32442 6.04071 1.89169 7.80822 1.89169C9.57598 1.89169 11.0092 3.3249 11.0094 5.09266C11.0094 5.18842 11.0031 5.27569 10.9949 5.36005V5.38308H4.60119V5.09145H4.60725ZM12.7374 7.71714C12.7374 7.39569 12.4768 7.13533 12.1556 7.13533H3.42083C3.09962 7.13533 2.83901 7.39569 2.83901 7.71714V13.5302C2.83901 13.8517 3.09962 14.1121 3.42083 14.1121H12.1556C12.4768 14.1121 12.7374 13.8514 12.7374 13.5302V7.71714ZM7.63828 12.3584H7.92919C8.32943 12.3584 8.65404 12.034 8.6538 11.6337V9.62357C8.6538 9.22333 8.32943 8.89896 7.92919 8.89896H7.63828C7.23804 8.89896 6.91368 9.22333 6.91368 9.62357V11.6337C6.91368 12.034 7.23804 12.3584 7.63828 12.3584Z' fill='%233D77FF'/%3E%3C/svg%3E%0A");

  }

  &__title {
    color: $colorText;

    margin-top: 8px;
  }
}
