.inbox_update_container {
  height: 640px;
  width: 600px;

  &__header {
    border-bottom: 1px solid #dadee2;
    margin-bottom: 10px;
    padding: 10px 0;

    &__title {
      color: #39444d;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 10px;
    }

    &__date {
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      margin-right: 7px;
    }
  }

  &__body {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-right: 10px;
    max-height: 540px;
    overflow-y: auto;

    ul,
    ol {
      list-style-position: inside;
    }

    ul {
      list-style-type: disc;
      list-style-position: inside;
    }

    img {
      width: 100%;
    }
  }

  .page-not-result {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .emoji {
      font-size: 40px;
      font-style: normal;
      margin-bottom: 10px;
    }
    h1 {
      font-size: 22px;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      margin-bottom: 10px;
      letter-spacing: 0.2px;
    }

    .notes {
      letter-spacing: 0.1px;
      font-size: 15px;
      color: #c0c7ce;
    }
  }
}
