.text-warning {
  color: $colorBright2_10;
}

.text-success {
  color: $colorBright1_09;
}

.success-text {
  color: #14b479
}

.text-danger {
  color: $colorBright2_08;
  font-size: $fontSizeSm;
}

.text-grey {
  color: $color07;
}

.text-modal {
  color: $colorTextDark;
}
