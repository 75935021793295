@import "scss/_variables.scss";

.estimation-modal-container {
  max-width: 360px;
  color: #637280;

  &--loading {
    opacity: 0.5;
    pointer-events: none;
  }

  &__header {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
  }

  &__subtitle {
    font-family: "Lato";
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 7px;
  }

  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  &__border {
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(192, 199, 206, 0.4);
  }

  &__value {
    font-size: 16px;
    line-height: 21px;
    text-align: right;
  }

  &__noties-wrap {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(192, 199, 206, 0.4);
    margin-bottom: 20px;

    .icon {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04IDE2QzEyLjQxODMgMTYgMTYgMTIuNDE4MyAxNiA4QzE2IDMuNTgxNzIgMTIuNDE4MyAwIDggMEMzLjU4MTcyIDAgMCAzLjU4MTcyIDAgOEMwIDEyLjQxODMgMy41ODE3MiAxNiA4IDE2Wk04IDE0LjY2NjdDMTEuNjgxOSAxNC42NjY3IDE0LjY2NjcgMTEuNjgxOSAxNC42NjY3IDhDMTQuNjY2NyA0LjMxODEgMTEuNjgxOSAxLjMzMzMzIDggMS4zMzMzM0M0LjMxODEgMS4zMzMzMyAxLjMzMzMzIDQuMzE4MSAxLjMzMzMzIDhDMS4zMzMzMyAxMS42ODE5IDQuMzE4MSAxNC42NjY3IDggMTQuNjY2N1oiIGZpbGw9IiMzYjQ2NGUiLz4KPHBhdGggZD0iTTguNjY2NjcgNEM4LjY2NjY3IDQuMzY4MTkgOC4zNjgxOSA0LjY2NjY3IDggNC42NjY2N0M3LjYzMTgxIDQuNjY2NjcgNy4zMzMzMyA0LjM2ODE5IDcuMzMzMzMgNEM3LjMzMzMzIDMuNjMxODEgNy42MzE4MSAzLjMzMzMzIDggMy4zMzMzM0M4LjM2ODE5IDMuMzMzMzMgOC42NjY2NyAzLjYzMTgxIDguNjY2NjcgNFoiIGZpbGw9IiMzYjQ2NGUiLz4KPHBhdGggZD0iTTcuMzMzMzMgNi42NjY2N0M3LjMzMzMzIDYuMjk4NDggNy42MzE4MSA2IDggNkM4LjM2ODE5IDYgOC42NjY2NyA2LjI5ODQ4IDguNjY2NjcgNi42NjY2N1YxMkM4LjY2NjY3IDEyLjM2ODIgOC4zNjgxOSAxMi42NjY3IDggMTIuNjY2N0M3LjYzMTgxIDEyLjY2NjcgNy4zMzMzMyAxMi4zNjgyIDcuMzMzMzMgMTJWNi42NjY2N1oiIGZpbGw9IiMzYjQ2NGUiLz4KPC9zdmc+Cg==);
      background-repeat: no-repeat;
      background-size: contain;
      width: 27px;
      height: 27px;
      margin-right: 8px;
    }
  }

  &__bt-wrap {
    text-align: right;
  }

  .btn-tooltip {
    text-align: left;
  }

  .checkbox-label {
    font-size: 14px;
    margin-bottom: 7px;
  }

  @import "./input-block.scss";

  @import "./comment-block.scss";

  .price-block {
    padding: 12px 0;
  }

  .estimation-btns-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @import "./info-block.scss";

  // .checkbox {
  //   .checkbox-icon {
  //     top: 4px;
  //   }
  // }
}

.estimation-order-countdown {
  font-size: $fontSizeXSm;

  position: absolute;
  left: 0;

  display: flex;
  align-items: center;

  top: -17px;

  white-space: nowrap;
}

.estimation-modal-btn {
  border: 1px solid #c0c7ce;
  background-color: #fff;
  color: #637280;
  height: 32px !important;
  display: inline-block;
  font-weight: bold;
  border-radius: 3px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  font-size: 13px;
  padding: 7px 10px;
  min-width: 300px;

  &--create-btn {
    border: 1px solid #2a86e1;
    background-color: #2a86e1;
    color: #fff;
    min-width: 100px;
  }

  &--loading {
    border: 1px solid #c0c7ce;
    background-color: #fff;
    color: #637280;
    opacity: 0.5;
    pointer-events: none;
  }
}
