.extend-ddl {
  &-icon {
    background: url('./img/icons/warning-icon.svg') no-repeat center center;
    height: 65px;
    width: 66px;
    margin: 0 auto;
  }
  &-title {
    color: #fc694f;
    font-family: Lato;
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
  }
  &-info {
    margin: 5px 0 20px;
    text-align: center;
    white-space: pre-wrap;
  }
  &-new-ddl {
    text-align: center;
    font-weight: bold;
  }
  & &-slider {
    margin: 40px 5% 20px;
    width: 90%;
  }
  &-new-price {
    font-weight: bold;
    text-align: center;
  }
  &-notice {
    font-size: 12px;
    color: #637280;
    text-align: center;
    white-space: pre-wrap;
    margin: 20px 0 30px;
  }
  &-btn {
    margin: 6px 0;
    color: #3099ff;
    font-weight: bold;
    &.btn-notification {
      color: #fff;
    }
  }
  &-tooltip-trigger {
    vertical-align: middle;
  }
  &-not-possible {
    margin-right: 5px;
  }
}
