$borderRadius: 3px;
$border: 1px solid $color05;
$padding: 10px;

.sticky-table {
    margin-bottom: $padding;

    .sticky-table-head {
        border: $border;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
        background-color: $colorWhite;

        &.sticky-table-head-fixed {
            border-radius: 0;
            border-top-color: transparent;
            box-shadow: 0 2px 2px $boxShadow;
        }

    }
    
    &.light {
      .sticky-table-head {
        border: none;
        border-bottom: $border;
      }
    }

    .sticky-table-body {
        border-top: none;
    }

    .page-not-result {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        min-height: 300px;
        border: 1px solid $color05;
        border-top: none;
        border-radius: 0 0 $borderRadius $borderRadius; 

        @include no-results;
    }
}
