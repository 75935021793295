.Toastify {
  position: absolute;
  top: 0;
  z-index: 1;
  right: 0;
  
  &--fixed {
    position: fixed;
    top: 50px;
  }
}

.Toastify__toast-container {
  &.orders-notifications-container {
    width: 277px;
    padding: 0;
    top: 0;
    position: absolute;
    z-index: 0;
    right: calc(-277px - 25px);

    &--wide {
      right: 15px;
    }

    .Toastify__toast {
      border-radius: 8px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
      border: solid 1px #dbdbdb;
      min-height: 84px;
      margin-bottom: 12px;
      padding: 12px;

      .Toastify__close-button {
        &--default {
          color: #6c7680;
          opacity: 1;
        }
      }

      .orders-notifications-body {
        color: $colorDark;
        max-width: 225px;
        font-size: 14px;

        &__header {
          @include fontFamilyBold;
          line-height: 1.36;

          opacity: 0.9;
          margin-bottom: 4px;
        }

        &__title {
          line-height: 1.29;
          opacity: 0.7;
        }

        &__link-block {
          display: flex;
          @include fontFamilyBold;
          align-items: center;
          font-size: 12px;
          margin-top: 4px;

          .svg-icon {
            width: 8px;
            height: 8px;
            fill: $colorAction10;
            margin-right: 4px;
          }
        }

      }
      &--default {
        color: $color10;
      }
    }
  }
}
