.preloading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.preloading-box {
    min-height: 528px;
    position: static;
    border: none;
    background-color: $colorWhite;
    border-radius: 0 0 $borderRadius $borderRadius;
    border-top: none;
    @include breakpoint(medium) {
      border: 1px solid $color05;
    }
  }

  p {
    color: $color06;
    justify-content: center;
    background: url("./img/icons/bars.svg") no-repeat center center;
    background-size: 45px auto;
    padding-top: 100px;
    width: 100%;
    height: 140px;
    margin-top: -40px;
    text-align: center;
    font-size: 2em;
  }
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.preloading-circle {
  position: relative;
  min-height: 18px;
  min-width: 18px;
  text-align: center;

  &::after {
    content: "";
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4wNDQ4IDEyLjU1NTJMMTIuODA0MyAxNy40NjRMMTEuMzU0IDE4TDkuNTk0NTMgMTMuMDkxMkwxMS4wNDQ4IDEyLjU1NTJaTTYuOTU1MzEgMTIuNTU1Mkw4LjQwNTU3IDEzLjA5MTJMNi42NDYwNyAxOEw1LjE5NTgxIDE3LjQ2NEw2Ljk1NTMxIDEyLjU1NTJaTTEyLjcyNzMgMTAuNDIyTDE3LjE4MjUgMTMuMDMzOUwxNi40MTA5IDE0LjM5MTFMMTEuOTU1NiAxMS43NzkyTDEyLjcyNzMgMTAuNDIyWk01LjI3MjcxIDEwLjQyMkw2LjA0NDM4IDExLjc3OTJMMS41ODkxNSAxNC4zOTExTDAuODE3NDg2IDEzLjAzMzlMNS4yNzI3MSAxMC40MjJaTTE3LjczMiA2Ljc4MjUzTDE4IDguMzI1ODdMMTIuOTMzNyA5LjIzMjk4TDEyLjY2NTcgNy42ODk2M0wxNy43MzIgNi43ODI1M1pNMC4yNjc5OTcgNi43ODI1M0w1LjMzNDMgNy42ODk2M0w1LjA2NjMgOS4yMzI5OEwwIDguMzI1ODdMMC4yNjc5OTcgNi43ODI1M1pNMTQuMTk1OCAxLjYzNTA5TDE1LjM3ODEgMi42NDI0M0wxMi4wNzEzIDYuNjQ0MTJMMTAuODg5IDUuNjM2NzhMMTQuMTk1OCAxLjYzNTA5Wk0zLjgwNDI4IDEuNjM1MDlMNy4xMTEwNyA1LjYzNjc4TDUuOTI4ODEgNi42NDQxMkwyLjYyMjAxIDIuNjQyNDNMMy44MDQyOCAxLjYzNTA5Wk05Ljc3MTY4IDBWNS4yMjM4M0g4LjIyODM0VjBIOS43NzE2OFoiIGZpbGw9IiMyQTg2RTEiLz4KPC9zdmc+Cg==");
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    width: 18px;
    height: 18px;
    animation: spinner 1.5s infinite linear;
    transform: rotate(0deg);
  }
}
