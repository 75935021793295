.calendar-container {
  display: inline-block;
  border-radius: 3px;
  background-color: #ffffff;;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
  // border: solid 1px #d3d3d3;
  padding: 15px 15px 10px;
  font-family: Lato;
}


.calendar-header {
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  color: #627281;
  margin-bottom: 10px;
}


.calendar-week_title {
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.2px;
  text-align: justify;
  color: #9ca9b6;
  white-space: nowrap;
}


.calendar-calendar_cell {
  box-sizing: border-box;
  display: inline-block;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
}

.calendar-calendar_day {
  color: #484848;
  cursor: pointer;
}

.calendar-calendar_day:hover {
  &:not(.calendar-disabled_day--un-chosen) {
    background-color: #3099ff;
    color: #fff
  }
}

.calendar-disabled_day {
  color: #c7c7c7;

  &--un-chosen {
    cursor: not-allowed;
  }
}

.calendar-chosen_day {
  background-color: #3099ff;
  color: #fff
}

.calendar-back_arrow, .calendar-forward_arrow {
  display: inline-block;
  cursor: pointer;
}

.calendar-back_arrow {
  transform: rotate(90deg);
  float: left;
}

.calendar-forward_arrow {
  float: right;
  transform: rotate(-90deg);
}

.calendar-date_part {
  margin-bottom: 15px;
}

.calendar-time_part {
  margin-bottom: 20px;

  &__tutor-zone {
    @include fontFamilyBold;
    color: $colorBlack;
  }
}

.calendar-time_item {
  display: inline-block;
  width: 65px;
  border-radius: 3px;
}

.calendar-time_input {
  background-color: #ffffff;
  border: solid 1px #dadee2;
  font-size: 14px;
  text-align: center;
  color: #627281;
  padding: 4px 0 4px 12px;
}


.calendar-time_input:focus, .calendar-time_input:active {
  outline: none;
}

.calendar-meridiem {
  background-color: #3099ff;
  font-size: 13px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  margin-left: 14px;
  padding: 5px 0;
  cursor: pointer;
}

.calendar-submit_button {
  position: relative;
  border-radius: 3px;
  background-color: #3099ff;
  height: 30px;
  cursor: pointer;
}

.calendar-submit_title {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #ffffff;
}

.container-arrow {
  display: inline-block;
}

.calendar-arrow {
  display: block;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-color: #3099ff;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
  margin-top: calc(-50% - 2px);

  &.active {
    border-color: #3099ff;
  }
}

