@import 'scss/_variables.scss';

.plugin-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;

  &__header {
    padding: 19px;
    background-color: #101010;

    &--name {
      @include fontFamilyBold;
      color: #fff;
      max-width: calc(100% - 40px);

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--controls {
      cursor: pointer;
    }
  }
}
