.nav {
    background-color: $colorWhite;
    border-bottom: 2px solid $color03;

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            display: inline-block;
            position: relative;

            a {
                color: $colorText;
                text-decoration: none;
                font-size: $fontSize;
                letter-spacing: .5px;
                display: block;
                padding: 13px 18px;
                transition: background-color $transitionTime;
                @include fontFamilyRegular;
                position: relative;

                &:hover {
                    color: $colorAction10;
                }

                &.active {
                    background-color: $color03;
                    color: $colorAction10;
                    @include fontFamilyMedium;
                }

                @include new-msg;

                .nav__icon {
                    position: absolute;
                    margin: 0;
                    right: 9px;
                    top: 5px;
                }
                
                .nav-emoji {
                  font-size: 11px;
                  position: absolute;
                  top: 8px;
                }
            }
        }
    }

    &.nav-sub {
        background-color: $color03;
        margin-bottom: 20px;

        ul {
            li {
                a {
                    font-size: $fontSizeSm;
                    padding: 12px 24px;

                    // &:after {
                    //     content: "";
                    //     display: block;
                    //     position: absolute;
                    //     height: 2px;
                    //     width: calc(100% - 40px);
                    //     background-color: $color03;
                    //     bottom: 0;
                    //     left: 20px;
                    //     transition: background-color $transitionTime;
                    //     border-radius: 0;
                    //     top: auto; 

                    //     &:hover {
                    //         background-color: $color06;
                    //     }

                    //     &.active {
                    //         background-color: $colorAction10;
                    //         border-bottom: 2px solid #2a86e1;
                    //     }
                    // }

                    &.new:before {
                        width: 5px;
                        height: 5px;
                    }

                    &.new.new--usd:before,
                    &.new.new--warnings:before {
                        width: 15px;
                        height: 15px;
                    }

                    &.new.new--usd:before,
                    &.new.new--usd:after {
                        animation: none;
                    }

                    &.new.new--usd:after {
                        content: none;
                    }
                    
                    &:hover:after {
                        background-color: $color06;
                    }

                    &.active {
                        @include fontFamilyMedium;
                        border-bottom: 2px solid #2a86e1;
                    }
                    // &.active:after {
                    //     background-color: $colorAction10;
                    // }
                }
            }
        }
    }
    
    &.sub-menu {
      border-bottom: 1px solid #e9e9e9;;

      a {
        &.active {
          background: none;
          border-bottom: 2px solid #2a86e1;
        }
      }
    }

    &.menu-no-border {
        border: none!important;
    }
}

.nav-left {
    li {
        margin-bottom: 5px;
    }
    
    a {
        display: inline-block;
        padding: 9px 10px 11px;
        color: $color09;
        text-decoration: none;
        line-height: 17px;

        &.active:hover,
        &.active {
            background-color: $colorAction09;
            color: $colorWhite;
            border-radius: $borderRadius;
            cursor: auto;
        }

        &:hover {
            color: $colorAction10;
        }
    }
}

.faq-nav {
    &-parent {
        display: inline-flex;
        padding-bottom: 15px;
        &-title {
            line-height: 20px;
            font-size: 16px;
            font-weight: 600;
        }
    }
}

.sub-nav-left {
    padding-left: 30px;
    a {
        color: $colorAction10;
    }
}
