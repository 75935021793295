.previews_scroll_container {
  height: 100%;
  overflow: scroll;
  padding-bottom: 86px;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #555;
  }

  .mini_previews_container {
    padding: 20px 0 20px 20px;
    display: flex;
    flex-wrap: wrap;

    .mini_preview_container {
      width: calc(50% - 22px);
      height: 130px;
      text-align: center;
      cursor: pointer;
      position: relative;

      border-radius: 8px;
      background-color: #42525d;

      margin-right: 22px;

      display: flex;
      align-items: center;
      justify-content: center;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: calc(100% - 1px);
        border: solid 4px transparent;
        top: -3;
        border-radius: 8px;
        z-index: -1;
      }

      &--active {
        &:after {
          border-color: #0085f5;
          background-color: #0085f5;
        }
      }

      &__download-btn {
        background-repeat: no-repeat;
        color: transparent;
        background-position: center;
        border: none;
        width: 26px;
        height: 26px;
        position: absolute;
        top: 10px;
        right: 11px;
        border-radius: 4px;
        cursor: pointer;
        transition: opacity .3s;
        padding: 0;

        &:hover {
          opacity: .8;
        }

        &--icon {
          display: block;
          width: 100%;
          height: 100%;
          background-color: #0000008c;
          mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzIzODNFNSI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xOSAxNWMuNTEzIDAgLjkzNi4zODYuOTkzLjg4M0wyMCAxNnYzYzAgLjUxMy0uMzg2LjkzNi0uODgzLjk5M0wxOSAyMEg1Yy0uNTEzIDAtLjkzNi0uMzg2LS45OTMtLjg4M0w0IDE5di0zYzAtLjU1Mi40NDgtMSAxLTEgLjUxMyAwIC45MzYuMzg2Ljk5My44ODNMNiAxNnYyaDEydi0yYzAtLjUxMy4zODYtLjkzNi44ODMtLjk5M0wxOSAxNXpNMTIgNGMuNTEzIDAgLjkzNi4zODYuOTkzLjg4M0wxMyA1djcuNTg1bDEuMjkzLTEuMjkyYy4zNi0uMzYuOTI4LS4zODggMS4zMi0uMDgzbC4wOTQuMDgzYy4zNi4zNi4zODguOTI4LjA4MyAxLjMybC0uMDgzLjA5NC0zIDNjLS4wMzUuMDM2LS4wNzMuMDY4LS4xMTIuMDk3bC0uMTEuMDcxLS4xMTQuMDU0LS4xMDUuMDM1LS4xNDkuMDNMMTIgMTZsLS4wNzUtLjAwMy0uMTI2LS4wMTctLjExMS0uMDMtLjExMS0uMDQ0LS4wOTgtLjA1Mi0uMDkyLS4wNjQtLjA5NC0uMDgzLTMtM2MtLjM5LS4zOS0uMzktMS4wMjQgMC0xLjQxNC4zNi0uMzYuOTI4LS4zODggMS4zMi0uMDgzbC4wOTQuMDgzTDExIDEyLjU4NVY1YzAtLjU1Mi40NDgtMSAxLTF6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNzQ4IC02MSkgdHJhbnNsYXRlKDQxNSA1MykgdHJhbnNsYXRlKDI4NSA4KSB0cmFuc2xhdGUoNDgpIi8+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K");
          -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzIzODNFNSI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xOSAxNWMuNTEzIDAgLjkzNi4zODYuOTkzLjg4M0wyMCAxNnYzYzAgLjUxMy0uMzg2LjkzNi0uODgzLjk5M0wxOSAyMEg1Yy0uNTEzIDAtLjkzNi0uMzg2LS45OTMtLjg4M0w0IDE5di0zYzAtLjU1Mi40NDgtMSAxLTEgLjUxMyAwIC45MzYuMzg2Ljk5My44ODNMNiAxNnYyaDEydi0yYzAtLjUxMy4zODYtLjkzNi44ODMtLjk5M0wxOSAxNXpNMTIgNGMuNTEzIDAgLjkzNi4zODYuOTkzLjg4M0wxMyA1djcuNTg1bDEuMjkzLTEuMjkyYy4zNi0uMzYuOTI4LS4zODggMS4zMi0uMDgzbC4wOTQuMDgzYy4zNi4zNi4zODguOTI4LjA4MyAxLjMybC0uMDgzLjA5NC0zIDNjLS4wMzUuMDM2LS4wNzMuMDY4LS4xMTIuMDk3bC0uMTEuMDcxLS4xMTQuMDU0LS4xMDUuMDM1LS4xNDkuMDNMMTIgMTZsLS4wNzUtLjAwMy0uMTI2LS4wMTctLjExMS0uMDMtLjExMS0uMDQ0LS4wOTgtLjA1Mi0uMDkyLS4wNjQtLjA5NC0uMDgzLTMtM2MtLjM5LS4zOS0uMzktMS4wMjQgMC0xLjQxNC4zNi0uMzYuOTI4LS4zODggMS4zMi0uMDgzbC4wOTQuMDgzTDExIDEyLjU4NVY1YzAtLjU1Mi40NDgtMSAxLTF6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNzQ4IC02MSkgdHJhbnNsYXRlKDQxNSA1MykgdHJhbnNsYXRlKDI4NSA4KSB0cmFuc2xhdGUoNDgpIi8+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K");
        }
      }

      .mini_preview_container__checkbox {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 15px;
        height: 15px;
        border-radius: 4px;
        border: solid 1px #98a7b3;
        outline: none;
        cursor: pointer;
      }

      .mini_preview_container_item_img_wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 8px;
      }

      @import './mini_preview_container_item.scss';

      .menu_container {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
  }
}
