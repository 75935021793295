.withdrawal-box {
  color: $colorText;
  padding: 0 15px;
  margin-bottom: 35px;

  .field-group {
    margin-bottom: 20px;
  }

  .field-val {
    color: $color09;
  }

  .radio-label {
    font-size: $fontSize;
    top: -4px;
  }

  .radiobutton {
    color: $color09;
    padding: 7px 0;

    &:last-child {
      margin-bottom: 5px;
    }
  }

  .input-text {
    width: 115px;
    height: 35px;
  }

  .text-danger {
    @include fontFamilyBold;
    margin-left: 25px;
  }
}
