.dnd-mode-container {
  position: fixed;
  bottom: -99px;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 21px;
  background-color: #e2eaed;
  padding: 13px 24px;
  transition: all $transitionTime;
  cursor: pointer;

  &--show {
    bottom: 44px;
    opacity: 1;
  }

}
