@import "scss/_variables.scss";

.order-tab {
  .modal-wrap {
    .order-report {
      color: $color09;
      font-size: $fontSizeL;

      &__issue__container {
        margin-bottom: 16px;
      }

      &__issue__note {
        font-weight: 900;
        margin: 20px 0;
      }

      &__issue {
        padding: 22px 21px 32px 20px;
        width: 388px;

        .modal-close {
          position: absolute;
          right: 0;
          top: 24px;
          z-index: 10;
        }

        .checkbox {
          font-size: $fontSizeL;
          display: flex;
          align-items: center;
          margin-bottom: 4px;
        }

        .title {
          font-size: $fontSizeL;
          font-weight: 900;
          line-height: 1.31;
          letter-spacing: 0.15px;
          color: $color09;
          margin-bottom: 8px;

          &__center {
            text-align: center;
          }

          &-container {
            margin-bottom: 16px;
          }
        }

        .sub-title {
          font-size: $fontSizeL;
          color: $color09;
          margin-bottom: 8px;
          opacity: 0.75;
          line-height: 1.4;
        }

        // .arrow-back {
        //   display: block;
        //   width: 16px;
        //   height: 14px;
        //   cursor: pointer;
        // }

        // .btn-arrow-back {
        //   position: absolute;
        //   left: 20px;
        //   top: 26px;
        // }

        .btn {
          &.btn-issue {
            white-space: normal;
            padding: 15px 10px;
            border-radius: 6px;
            background-color: $colorWhite;
            font-size: $fontSizeL;
            font-weight: bold;
            line-height: 1.31;
            height: 100%;
          }

          &.success {
            border: solid 1px $colorBright1_09;
            color: $colorBright1_09;
          }

          &.danger {
            border: solid 1px $colorBright2_10;
            color: $colorBright2_10;
          }
        }

        .btn-block {
          margin-bottom: 12px;

          &.active {
            border: solid 1px $colorAction09;
            background-color: $colorAction05;
            color: $colorAction09;
          }
        }

        .btn-list {
          padding: 10px 0 0;
          margin-bottom: 0;
        }

        .btn-list__item {
          margin-right: 12px;
          margin-bottom: 12px;
        }

        .note {
          position: relative;
          padding-left: 20px;
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
          margin-bottom: 20px;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            display: inline-block;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04IDE2QzEyLjQxODMgMTYgMTYgMTIuNDE4MyAxNiA4QzE2IDMuNTgxNzIgMTIuNDE4MyAwIDggMEMzLjU4MTcyIDAgMCAzLjU4MTcyIDAgOEMwIDEyLjQxODMgMy41ODE3MiAxNiA4IDE2Wk04IDE0LjY2NjdDMTEuNjgxOSAxNC42NjY3IDE0LjY2NjcgMTEuNjgxOSAxNC42NjY3IDhDMTQuNjY2NyA0LjMxODEgMTEuNjgxOSAxLjMzMzMzIDggMS4zMzMzM0M0LjMxODEgMS4zMzMzMyAxLjMzMzMzIDQuMzE4MSAxLjMzMzMzIDhDMS4zMzMzMyAxMS42ODE5IDQuMzE4MSAxNC42NjY3IDggMTQuNjY2N1oiIGZpbGw9IiMzYjQ2NGUiLz4KPHBhdGggZD0iTTguNjY2NjcgNEM4LjY2NjY3IDQuMzY4MTkgOC4zNjgxOSA0LjY2NjY3IDggNC42NjY2N0M3LjYzMTgxIDQuNjY2NjcgNy4zMzMzMyA0LjM2ODE5IDcuMzMzMzMgNEM3LjMzMzMzIDMuNjMxODEgNy42MzE4MSAzLjMzMzMzIDggMy4zMzMzM0M4LjM2ODE5IDMuMzMzMzMgOC42NjY2NyAzLjYzMTgxIDguNjY2NjcgNFoiIGZpbGw9IiMzYjQ2NGUiLz4KPHBhdGggZD0iTTcuMzMzMzMgNi42NjY2N0M3LjMzMzMzIDYuMjk4NDggNy42MzE4MSA2IDggNkM4LjM2ODE5IDYgOC42NjY2NyA2LjI5ODQ4IDguNjY2NjcgNi42NjY2N1YxMkM4LjY2NjY3IDEyLjM2ODIgOC4zNjgxOSAxMi42NjY3IDggMTIuNjY2N0M3LjYzMTgxIDEyLjY2NjcgNy4zMzMzMyAxMi4zNjgyIDcuMzMzMzMgMTJWNi42NjY2N1oiIGZpbGw9IiMzYjQ2NGUiLz4KPC9zdmc+Cg==);
            background-repeat: no-repeat;
            background-size: contain;
            width: 16px;
            height: 16px;
          }
        }

        // .binding {
        //   font-size: $fontSizeXSm;
        //   font-weight: bold;
        //   margin-bottom: 12px;

        //   span {
        //     color: $colorBright2_10;
        //   }
        // }

        .error {
          color: $colorBright2_10;
          font-size: $fontSizeXSm;
          font-weight: bold;
          margin-bottom: 5px;
          width: 100%;
        }

        .issue-comment {
          position: relative;

          .cnt-symbols {
            position: absolute;
            right: 12px;
            bottom: 20px;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 0.08px;
            color: #24a652;

            &.text-danger {
              color: $colorBright2_10;
            }
          }
        }

        .input-container {
          position: relative;
          display: inline-flex;

          span {
            position: absolute;
            font-size: $fontSizeMd;
            color: $colorAction09;
            left: 23px;
            top: 7px;
          }
        }

        input {
          width: 56px;
          height: 30px;
          border-radius: 3px;
          border: solid 1px $colorAction09;
          background-color: $colorWhite;
          color: $colorAction09;
          padding: 0 10px 0 8px;
          &:focus,
          &:hover,
          &:visited {
            outline: none;
          }
        }

        textarea {
          width: 100%;
          height: 80px;
          border-radius: 3px;
          border: solid 1px $color06;
          background-color: $colorWhite;
          margin-bottom: 12px;
          padding: 9px 12px;
          resize: none;
        }

        .modal-btns {
          margin-bottom: 24px;
        }

        .btn-group {
          button {
            & + button {
              margin-left: 12px;
            }
          }
        }

        .slider {
          padding: 8px 10px;
          margin-bottom: 10px;

          .rc-slider-handle-1 {
            display: none;
          }
          .rc-slider-handle {
            margin-top: -10px;
            margin-left: 0;
            transform: none;
            width: 20px;
            height: 20px;
          }

          .rc-slider-track {
            background-color: transparent;
          }

          .rc-slider-rail {
            background-image: linear-gradient(to left, #ff0017, #ffdf00 48%, #07861a);
            background-color: transparent;
          }
        }
      }
    }
  }
}

.price_value {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px $color06;
  align-items: center;
  margin-bottom: 20px;
  padding: 14px 0 23px;

  div {
    span {
      font-size: $fontSizeMd;
      letter-spacing: 0.12px;
      color: #5e5e5e;
      margin: 0 5px;
      &:first-child {
        margin-left: 0;
      }
    }
  }

  .suggest_price {
    font-size: $fontSizeL;
  }

  ::-webkit-input-placeholder {
    color: $colorAction09;
    @include fontFamilyRegular;
  }
  ::-moz-placeholder {
    color: $colorAction09;
    @include fontFamilyRegular;
  }
  :-moz-placeholder {
    color: $colorAction09;
    @include fontFamilyRegular;
  }
  :-ms-input-placeholder {
    color: $colorAction09;
    @include fontFamilyRegular;
  }
}

.total-info {
  margin-bottom: 10px;

  p {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    span {
      font-size: $fontSizeL;
    }
  }
}

.container-feedback {
  .table-row {
    color: #030303;
    align-items: flex-start;
    .arrow-right-brd {
      top: 3px;
    }
  }

  .solved-block {
    display: none;
    p {
      margin-bottom: 0;
    }
  }

  .suggestion-title {
    font-weight: bold;
    margin-bottom: 4px;

    &.success-title {
      color: $colorBright1_09;
    }
    &.danger-title {
      color: $colorBright2_10;
    }
  }

  .feedback-suggestion {
    display: inline-flex;
    font-size: $fontSizeMd;
    max-width: 215px;
    overflow: hidden;
    white-space: nowrap;
    line-height: 18px;
    margin-right: 3px;

    span {
      color: #686868;
      margin-right: 3px;
    }
  }

  .suggestion-wrap {
    a {
      display: inline-flex;
    }

    &.active {
      .feedback-suggestion {
        display: inline;
        max-width: none;
        overflow: auto;
        white-space: normal;
      }

      .solved-block {
        display: block;
        margin-top: 17px;
      }
    }
  }
}

.field_required {
  color: $colorBright2_10;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 20px;
  position: absolute;
  top: 82px;
}

.issue_error {
  border: 1px solid $colorBright2_10 !important;
}
