@import 'scss/_variables.scss';

.subcategory-item {

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &__header {
    padding: 9px 12px 10px !important;
    border-radius: 4px;
    background-color: rgba(230, 234, 239, 0.4);
    cursor: pointer;

    font-size: 14px;
    @include fontFamilyBold;

    color: $color09;

    .arrow-down {
      transform: rotate(-50grad);
      border-color: #2a86e1;
      transition: transform .3s;
      margin-right: 8px;

      &.active {
        transform: rotate(45deg);
      }
    }
  }

}
