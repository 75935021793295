.availability-dropdown {
  @include breakpointMax(medium) {
    padding-top: 16px;
  }
  .menu {
    padding: 10px 0;
  }

  .dropdown-content {
    right: 5px;
    left: auto;
    margin-right: 100%;
    top: -5px;
  }
  
  .menu-item {
    font-size: 16px;
    padding: 6px 25px 6px 15px;
    border-radius: 0;
    @include breakpointMax(medium) {
      line-height: 1.5;
    }
    &.active {
      background-color: #2a86e1;
      color: #fff;
      position: relative;

      @include breakpointMax(medium) {
        background-color: #e5f1fe;
        color: $colorAction10;
        border-radius: 4px;
      }

      &:after {
        content: "";
        display: block;
        background-image: url("./img/icons/selected-item.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        width: 12px;
        height: 10px;
        right: 6px;
        top: 0;
        bottom: 0;
        margin: auto;
        @include breakpointMax(medium) {
          background-image: url("./img/icons/selected-item_blue.svg");
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
