.container_filter_tags {
  display: flex;
  align-items: center;

  &__title {
    color: #637280;
    margin-right: 10px;
  }

  &__item {
    margin-right: 5px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 15px;
    background: none;
    padding: 8px 10px;
    cursor: pointer;
    background-color: #eceff1;
    color: #3b464e;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.15px;
    text-transform: capitalize;

    &--active {
      background-color: #E1EFFD;
      color: #2A86E1;
    }
  }
}
