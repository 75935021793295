.active-warnings-title {
  font-size: 14px;
  font-weight: 900;
  color: #4e4e4e;
  margin: 35px 0 20px;
}
.active-warnings-table {
  &-header {
    font-weight: bold;
    color: #627281;
  }
  &-row {
    color: #4e4e4e;

    &:nth-of-type(2n) {
      border-radius: 3px;
      background-color: #f7fbfc;
    }
  }
  &-header,
  &-row {
    font-size: 14px;
    min-height: 30px;
    height: auto;
    display: flex;
    &-type,
    &-description,
    &-created,
    &-closed {
      display: inline-block;
      padding: 6px 20px;
    }
    &-type {
      width: 25%;
    }
    &-description {
      width: 45%;
      text-overflow: ellipsis;
      max-width: 386px;
      overflow: hidden;
    }
    &-created {
      width: 15%;
    }
    &-closed {
      width: 15%;
    }
  }
}

.warning-collapces {
  margin-top: 35px;
  &-learnmore {
    font-weight: normal;
    margin-left: 15px;
    &-icon {
      vertical-align: bottom;
      margin-left: 5px;
    }
  }
  &-header {
    position: relative;
    font-size: 14px;
    font-weight: 900;
    color: #4e4e4e;
    border-bottom: solid 2px #e6e8eb;
    padding-bottom: 15px;
    margin-bottom: 15px;
    &-type,
    &-rate,
    &-count {
      display: inline-block;
    }
    &-type {
      width: 50%;
    }
    &-count {
      position: absolute;
      right: 24px;
      top: 0px;
    }
  }
}

.warning-collapse-table {
  &-header {
    font-weight: bold;
  }
  &-row {
    &:nth-of-type(2n) {
      background-color: #f7fbfc;
    }
    &:last-of-type {
      border-bottom-width: 1px;
      border-radius: 0 0 3px 3px;
    }
    &-status.active {
      color: #fc694f;
    }
    &-created.active,
    &-closed.active {
      color: #030303;
    }
    &-status.active,
    &-closed.orders-leave {
      font-weight: bold;
    }

  }
  &-header,
  &-row {
    font-size: 14px;
    min-height: 30px;
    height: auto;
    display: flex;
    color: #627281;
    text-transform: capitalize;
    border-color: #3099ff;
    border-style: solid;
    border-width: 0;
    border-left-width: 1px;
    border-right-width: 1px;
    &-order,
    &-created,
    &-closed,
    &-status {
      display: inline-block;
      padding: 6px 20px;
      user-select: none;
    }
    &-order {
      width: 37%;
    }
    &-created {
      width: 25%;
    }
    &-closed {
      width: 25%;
    }
    &-status {
      width: 13%;
    }
  }
}
