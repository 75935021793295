@import "scss/_variables.scss";

.chat {
  display: flex;
  margin-bottom: 15px;

  &__message {
    color: $colorText;
    line-height: 1.57;
    width: 80%;
  }

  &__author {
    font-weight: 600;
    line-height: 1.57;
    letter-spacing: 0.1px;
    color: $colorText;
    width: 18%;
    font-size: $fontSizeMd;
  }

  &__events {
    white-space: break-spaces;
  }

  &__files {
    display: block;
  }

  &__space {
    padding: 20px 0;
  }
  &__time {
    color: $color07;
    font-size: $fontSizeXSm;
  }
}
