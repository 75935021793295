.mobile-bar {
  padding: 12px 12px 12px 0;
  background-color: #000000;
  margin-top: 1px;

  .mini_preview_container {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    background-color: #4d5458;
    margin: 0 8px;
    outline: none;
    position: relative;

    &--active {
      &:after {
        content: '';
        position: absolute;
        width: calc(100% - 2px);
        height: calc(100% - 4px);
        top: -1px;
        border: 3px solid #2383e5;
        border-radius: 4px;
        left: -3px;
      }
    }

    >*,
    .mini_preview_container_item {
      display: block;
      width: 100%;
      height: 100%;
    }

    @import './mini_preview_container_item.scss';
  }

  .slick-slider {
    .slick-arrow {
      display: none!important;
    }
  }

  .slick-list {
    margin: 0 -5px;
  }

  .slick-slide > div {
    padding: 0 5px;

    &:last-child {
      margin-right: 5px;
    }
  }
}
