.tooltip {
  display: inline-block;
  position: relative;

  @include breakpointMax(medium) {
    padding: 10px;
    margin: -10px;
  }

  .tooltip-content {
    padding: 0;
    background-color: $colorText;
    border-radius: $borderRadius;
    color: $colorWhite;
    position: absolute;
    top: calc(100% + 5px);
    font-size: $fontSizeSm;
    line-height: 1.5;
    letter-spacing: 0.1px;
    z-index: 1;
    opacity: 0;
    height: 0;
    width: 0;
    text-align: left;
    overflow: hidden;
  }

  &.tooltip__icon {
    .tooltip-content {
      border-radius: 3px;
      box-shadow: 0 2px 9px 0 rgba(99, 114, 128, 0.28);
      background-color: #ffffff;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0.1px;
      color: #637280;

      h4 {
        font-weight: bold;
        letter-spacing: 0px;
        margin-bottom: 5px;
      }
    }
  }

  .tooltip-high-trigger,
  .tooltip-trigger {
    display: block;
  }

  .tooltip-high-trigger:hover > .tooltip-content,
  .tooltip-high-trigger.hover > .tooltip-content,
  .tooltip-trigger:hover + .tooltip-content,
  .tooltip-trigger.hover + .tooltip-content {
    display: block;
    min-width: 200px;
    height: auto;
    opacity: 1;
    transition: opacity 0.5s;
    padding: 10px 15px;
    white-space: normal;
  }

  &.tooltip-vmiddle {
    vertical-align: middle;
  }

  &.tooltip-top {
    .tooltip-content {
      top: auto!important;
      bottom: calc(100% + 5px)!important;
      display: none;
    }
  }

  &.tooltip-right {
    .tooltip-content {
      right: 0;
    }
  }

  &.tooltip-table {
    .tooltip-trigger {
      width: 75px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }
  }
}

.tooltip-high-trigger:hover > .tooltip-content,
.tooltip-high-trigger.hover > .tooltip-content {
  display: block;
  min-width: 200px;
  height: auto;
  opacity: 1;
  transition: opacity 0.5s;
  padding: 10px 15px;
  white-space: normal;
}

.tooltip-template {
  .question {
    position: relative;
    top: 1px;
  }
}
