.btn-upload {
    position: relative;

    input[type="file"] {
        position: absolute;
        width: 1px;
        height: 1px;
        left: -1000px;
        display: none;
        outline: none;
        opacity: 0;
        cursor: pointer;
    }
}
