.input-text {
  @include border-btn;
  height: $blockHeight;
  color: $color09;
  padding-left: 10px;

  @include breakpointMax(medium) {
    height: 44px;
    font-size: $fontSizeL;
    padding-top: 6px;
    border-radius: 4px;
  }

  &.is-error {
    background-color: $colorBrightOpacity2_10;
    border: solid 1px $colorBright2_10;
  }

  &.is-error-only-border {
    border: solid 1px $colorBright2_10;
  }

  &:disabled,
  &.disabled {
    color: $color06;
    cursor: not-allowed;
  }
}

::-webkit-input-placeholder {
  color: $color06;
  @include fontFamilyRegular;
}
::-moz-placeholder {
  color: $color06;
  @include fontFamilyRegular;
}
:-moz-placeholder {
  color: $color06;
  @include fontFamilyRegular;
}
:-ms-input-placeholder {
  color: $color06;
  @include fontFamilyRegular;
}
