.zoom_container {
  position: relative;

  .tools {
    position: absolute;

    left: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%);
    
    button {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }

    .no_padding {
      padding: 0;
    }

    .btn_icon {
      background-repeat: no-repeat;
      color: transparent;
      background-position: center;
      border: none;
      width: 26px;
      height: 26px;
    }

    .download_btn {
      background-color: #0000008c;
      mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzIzODNFNSI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xOSAxNWMuNTEzIDAgLjkzNi4zODYuOTkzLjg4M0wyMCAxNnYzYzAgLjUxMy0uMzg2LjkzNi0uODgzLjk5M0wxOSAyMEg1Yy0uNTEzIDAtLjkzNi0uMzg2LS45OTMtLjg4M0w0IDE5di0zYzAtLjU1Mi40NDgtMSAxLTEgLjUxMyAwIC45MzYuMzg2Ljk5My44ODNMNiAxNnYyaDEydi0yYzAtLjUxMy4zODYtLjkzNi44ODMtLjk5M0wxOSAxNXpNMTIgNGMuNTEzIDAgLjkzNi4zODYuOTkzLjg4M0wxMyA1djcuNTg1bDEuMjkzLTEuMjkyYy4zNi0uMzYuOTI4LS4zODggMS4zMi0uMDgzbC4wOTQuMDgzYy4zNi4zNi4zODguOTI4LjA4MyAxLjMybC0uMDgzLjA5NC0zIDNjLS4wMzUuMDM2LS4wNzMuMDY4LS4xMTIuMDk3bC0uMTEuMDcxLS4xMTQuMDU0LS4xMDUuMDM1LS4xNDkuMDNMMTIgMTZsLS4wNzUtLjAwMy0uMTI2LS4wMTctLjExMS0uMDMtLjExMS0uMDQ0LS4wOTgtLjA1Mi0uMDkyLS4wNjQtLjA5NC0uMDgzLTMtM2MtLjM5LS4zOS0uMzktMS4wMjQgMC0xLjQxNC4zNi0uMzYuOTI4LS4zODggMS4zMi0uMDgzbC4wOTQuMDgzTDExIDEyLjU4NVY1YzAtLjU1Mi40NDgtMSAxLTF6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNzQ4IC02MSkgdHJhbnNsYXRlKDQxNSA1MykgdHJhbnNsYXRlKDI4NSA4KSB0cmFuc2xhdGUoNDgpIi8+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K");
      -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzIzODNFNSI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xOSAxNWMuNTEzIDAgLjkzNi4zODYuOTkzLjg4M0wyMCAxNnYzYzAgLjUxMy0uMzg2LjkzNi0uODgzLjk5M0wxOSAyMEg1Yy0uNTEzIDAtLjkzNi0uMzg2LS45OTMtLjg4M0w0IDE5di0zYzAtLjU1Mi40NDgtMSAxLTEgLjUxMyAwIC45MzYuMzg2Ljk5My44ODNMNiAxNnYyaDEydi0yYzAtLjUxMy4zODYtLjkzNi44ODMtLjk5M0wxOSAxNXpNMTIgNGMuNTEzIDAgLjkzNi4zODYuOTkzLjg4M0wxMyA1djcuNTg1bDEuMjkzLTEuMjkyYy4zNi0uMzYuOTI4LS4zODggMS4zMi0uMDgzbC4wOTQuMDgzYy4zNi4zNi4zODguOTI4LjA4MyAxLjMybC0uMDgzLjA5NC0zIDNjLS4wMzUuMDM2LS4wNzMuMDY4LS4xMTIuMDk3bC0uMTEuMDcxLS4xMTQuMDU0LS4xMDUuMDM1LS4xNDkuMDNMMTIgMTZsLS4wNzUtLjAwMy0uMTI2LS4wMTctLjExMS0uMDMtLjExMS0uMDQ0LS4wOTgtLjA1Mi0uMDkyLS4wNjQtLjA5NC0uMDgzLTMtM2MtLjM5LS4zOS0uMzktMS4wMjQgMC0xLjQxNC4zNi0uMzYuOTI4LS4zODggMS4zMi0uMDgzbC4wOTQuMDgzTDExIDEyLjU4NVY1YzAtLjU1Mi40NDgtMSAxLTF6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNzQ4IC02MSkgdHJhbnNsYXRlKDQxNSA1MykgdHJhbnNsYXRlKDI4NSA4KSB0cmFuc2xhdGUoNDgpIi8+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K");
    }
  }
}
