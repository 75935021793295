@import 'scss/_variables.scss';

.subjects-group-form {

  &__col {
    padding: 0 5px!important;
  }

  &__dropdown {
    max-width: 157px;

    .dropdown-content {
      max-width: 157px;

      .menu.menu-selectable {
        padding: 8px 2px!important;
      }
    }
  }

  &__dropdown_items {
    font-size: 13px!important;
  }

  &__dropdown-title {
    @include fontFamilyBolder;
    color: $colorText;
  }
}
