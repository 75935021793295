@keyframes fromTopToBottom {
  0% {
    bottom: calc(-100% + 100px);
    opacity: 0;
  }
  100% {
    bottom: calc(-100% + 40px);
    opacity: 1;
  }
}

.order-table-notification {
  position: fixed;
  bottom: calc(58px + 8px);
  border-radius: 21px;
  background-color: $colorBright1_09;
  font-size: $fontSizeMd;
  letter-spacing: 0.12px;
  color: #fff;
  padding: 12px 20px;
  left: 50%;
  transform: translateX(-50%);
  animation: fromTopToBottom $transitionTime;
  cursor: pointer;

  @include breakpoint(medium) {
    position: absolute;
    bottom: calc(-100% + 40px);
  }
}


