.dropdown {
    position: relative;
    color: $colorText;
    height: $blockHeight;

    .btn-block {
        text-align: left;
        padding: 7px 25px 7px 10px;
    }

    .dropdown-content {
        position: absolute;
        left: 0;
        top: calc(100% + 10px);
        background-color: $colorWhite;
        border: 1px solid $color06;
        box-shadow: 0 2px 4px 0 $boxShadow;
        border-radius: $borderRadius;
        z-index: 2;
        width: 100%;

        @include breakpoint(medium) {
            width: unset;
        }

    }

    &.dropdown-up {
        .dropdown-content {
            top: auto;
            bottom: calc(100% + 10px);
        }
    }
    &.dropdown-right {
        .dropdown-content {
            left: auto;
            right: 0;
        }
    }

    @include dropdown-footer;

    .btn .arrow-down {
        position: absolute;
        right: 9px; 
        top: 10px;
    }

    &.dropdown-fluid {
        position: static;
        width: 100%;

        .dropdown-content {
            width: calc(100% - 100px);
            left: 50px;
            top: calc(100% - 40px);
        }

        .menu {
            column-count: 5;
            padding: 20px 10px;
        }

        .checkbox {
            margin: 0 0 15px;
            padding: 0;
        }
    }

    .lnk-dropdown {
        display: inline;
        border: none;
        background-color: transparent;
        color: $colorAction10;
        font-size: 13px;
        letter-spacing: 0.1px;
        border-radius: $borderRadius;
        cursor: pointer;
        
        .arrow-down {
            display: none;
        }

        &:hover {
            text-decoration: underline;
            background-color: $colorAction04;
        }

        &.text-warning {
            font-size: 13px;
            color: $colorBright2_10;
        }
    }

    .hover-dropdown {
        color: #2a86e1;
        display: inline-flex;
        align-items: center;
        height: 28px;
        width: calc(100% + 11px);
        justify-content: space-between;
    }
}
