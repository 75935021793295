.info-block {
  padding: 12px 0;

  .info-block-size {
    display: flex;
    align-items: center;

    &__label {
      font-weight: bold;
      font-size: $fontSizeSm;

      @include breakpoint(medium) {
        font-size: $fontSizeXL;
      }
  
    }

    &__value {
      margin-left: 8px;
    }
  }

  &__hint {
    color: $colorText;
    font-size: $fontSizeXSm;
  }
}
