@import 'scss/_variables.scss';

.task-instructions-card {

  &__header {
    font-size: $fontSizeL;
    @include fontFamilyBold;
    
    color: $colorDark;
  }

  &__transform-btns {
    @include fontFamilyBold;
    border-bottom: none!important;
    cursor: pointer;
  }

  .task-instructions-card-files-container {
    flex-wrap: wrap!important;
  }

  .task-instructions-card-file {
    background: #FFFFFF;
    border: 1px solid #EBEEF5;
    box-shadow: 0px 4px 8px rgba(86, 93, 107, 0.1);
    border-radius: 16px;
    padding: 4px;
    width: calc(50% - 4px);
  }
}
