.filter-orders {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e8eb;

  .btm-filter {
    font-size: 11px;
    display: inline-block;
    border-radius: 15px;
    border: solid 1px #9ca9b5;
    padding: 7px 12px;
    color: #3b464e;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.5s;

    &--fill-btn {
      border-color: #085db2;
      background-color: #085db2;
      color: #fff;
    }

    &:hover {
      border-color: #085db2;
      background-color: #085db2;
      color: #fff;
    }

    &.active {
      border-color: #2a86e1;
      background-color: #2a86e1;
      color: #fff;
      padding: 8px 30px 8px 8px;
    }

    &:disabled:hover,
    &:disabled,
    &.disabled:hover,
    &.disabled {
      border: 1px solid #e6e8eb;
      background-color: #f7f9fa;
      color: #c0c7ce;
      cursor: not-allowed;
    }
  }

  .dropdown {
    &.is-open {
      > .btm-filter {
        border-color: #085db2;
        background-color: #085db2;
        color: #fff;
      }
    }
  }

  .filter-container {
    display: flex;
    padding: 20px;
    align-items: center;
  }

  .filter-item-division {
    background-color: #DADEE2;
    width: 1px;
    height: 100%;
    margin-left: 16px;
    margin-right: 16px;
  }

  .filter-item {
    .checkbox-label:first-letter {
      text-transform: uppercase
    }

    position: relative;
    & + .filter-item {
      margin-left: 6px;
    }

    .dropdown {
      .dropdown-content {
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
      }
      .dropdown-footer {
        justify-content: flex-end;
        border: none;
        border-top: solid 1px #e6e8eb;
        border-radius: 0;
      }
    }

    .btn {
      &.btn-empty {
        display: flex;
        align-items: center;
        height: 100%;
        cursor: pointer;

        &:hover {
          color: #2383e5;
        }
      }

      &.btn-filter-search {
        border: none;

        .search {
          fill: #3b464e;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .total {
    display: flex;
    align-items: center;
    height: 100%;
    color: #637280;
    font-size: 13px;
    b {
      display: inline-block;
      margin: 0 5px;
    }
  }

  .container-item {
    padding: 15px 20px;
  }

  .filter-item__subj {
    max-height: 440px;
    // max-width: 779px;
    display: flex;
  }

  .subj-col {
    width: 265px;
    border-right: solid 1px #c0c7ce;
    padding: 15px 20px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .subj-col__last {
    width: 350px;
    border-right: none;
  }

  .subj-list {
    margin-bottom: 20px;
  }

  .title-filter {
    font-size: 16px;
    font-weight: bold;
    color: #3b464e;
    margin-bottom: 20px;
  }

  .title-filter__sm {
    line-height: 12px;
    font-size: 10px;
    letter-spacing: 0.67px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .title-category {
    font-size: 13px;
    font-weight: bold;
    color: #9ca9b5;
    margin-bottom: 10px;
  }

  .no-result {
    display: flex;
    height: 100%;
    align-items: center;
    padding-top: 120px;
    flex-direction: column;
    text-align: center;
    color: #3b464e;

    .desc {
      opacity: 0.6;
      font-size: 13px;
      line-height: 1.5;
      letter-spacing: 0.11px;
      line-height: 19px;
    }
    .title {
      opacity: 0.6;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.13px;
      margin-bottom: 4px;
      line-height: 16px;
    }

    .icon-sad {
      width: 22px;
      height: 22px;
      margin-bottom: 11px;
    }
  }

  .search-wrap .input-text {
    border-radius: 15px;
    outline: none;
  }
  .form-search {
    .btn {
      border-radius: 15px;
    }
  }

  .icon-reset {
    width: 18px;
    height: 18px;
    fill: #fff;
  }

  .btn-reset {
    position: absolute;
    right: 10px;
    top: 0;
  }

  .switch-filter {
    display: flex;
    align-items: center;

    + .switch-filter {
      margin-left: 25px;
    }

    &__name {
      color: #6c7680;
      font-size: 12px;
      margin-right: 5px;
    }

    &.active {
      .switch-filter__name {
        color: #2383e5;
      }
    }
  }

  .cnt {
    margin: 0 3px;
  }
}
