.session-details-modal {
  color: #637280;

  &--title {
    font-weight: 900;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.15px;
    color: #3b464e;
    margin-bottom: 20px;
  }

  &--disable {
    opacity: 0.5;
    pointer-events: none;
  }

  .session-details-modal-form {
    color: #637280;

    &__field {
      margin-right: 5px;
    }

    &__value {
      color: #3b464e;
    }

    &__label {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
    }

    &__border {
      border-bottom: 1px solid rgba(192, 199, 206, 0.4);
      padding-bottom: 10px;
    }

    &__subtitle {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      margin-right: 10px;
    }

    &__note {
      display: flex;
      align-items: center;

      &__icon {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8Y2lyY2xlIGN4PSI0IiBjeT0iNCIgcj0iNCIgZmlsbD0iIzIzODNFNSIvPgo8cGF0aCBkPSJNNC4zODY0NyAxLjcyMTU2VjMuNTcyMDJDNC4zODY0NyAzLjc2ODI4IDQuMzc2NzcgMy45NjEzMSA0LjM1NzM1IDQuMTUxMUM0LjMzNzk0IDQuMzM4NzMgNC4zMTIwNiA0LjUzODIzIDQuMjc5NzEgNC43NDk1OUgzLjczOTQ2QzMuNzA3MTEgNC41MzgyMyAzLjY4MTIyIDQuMzM4NzMgMy42NjE4MSA0LjE1MTFDMy42NDI0IDMuOTYxMzEgMy42MzI3IDMuNzY4MjggMy42MzI3IDMuNTcyMDJWMS43MjE1Nkg0LjM4NjQ3Wk0zLjUwOTc3IDUuOTU5NUMzLjUwOTc3IDUuODkyNjQgMy41MjE2MyA1LjgyOTAyIDMuNTQ1MzUgNS43Njg2M0MzLjU3MTIzIDUuNzA4MjUgMy42MDU3NCA1LjY1NjQ4IDMuNjQ4ODcgNS42MTMzNUMzLjY5NDE2IDUuNTcwMjIgMy43NDcgNS41MzU3MSAzLjgwNzM5IDUuNTA5ODNDMy44Njc3OCA1LjQ4Mzk1IDMuOTMyNDggNS40NzEwMSA0LjAwMTUgNS40NzEwMUM0LjA2ODM1IDUuNDcxMDEgNC4xMzE5OCA1LjQ4Mzk1IDQuMTkyMzcgNS41MDk4M0M0LjI1Mjc1IDUuNTM1NzEgNC4zMDQ1MiA1LjU3MDIyIDQuMzQ3NjUgNS42MTMzNUM0LjM5MDc4IDUuNjU2NDggNC40MjUyOSA1LjcwODI1IDQuNDUxMTcgNS43Njg2M0M0LjQ3NzA1IDUuODI5MDIgNC40ODk5OSA1Ljg5MjY0IDQuNDg5OTkgNS45NTk1QzQuNDg5OTkgNi4wMjg1MiA0LjQ3NzA1IDYuMDkzMjIgNC40NTExNyA2LjE1MzYxQzQuNDI1MjkgNi4yMTE4NCA0LjM5MDc4IDYuMjYyNTIgNC4zNDc2NSA2LjMwNTY2QzQuMzA0NTIgNi4zNDg3OSA0LjI1Mjc1IDYuMzgyMjIgNC4xOTIzNyA2LjQwNTk0QzQuMTMxOTggNi40MzE4MiA0LjA2ODM1IDYuNDQ0NzYgNC4wMDE1IDYuNDQ0NzZDMy45MzI0OCA2LjQ0NDc2IDMuODY3NzggNi40MzE4MiAzLjgwNzM5IDYuNDA1OTRDMy43NDcgNi4zODIyMiAzLjY5NDE2IDYuMzQ4NzkgMy42NDg4NyA2LjMwNTY2QzMuNjA1NzQgNi4yNjI1MiAzLjU3MTIzIDYuMjExODQgMy41NDUzNSA2LjE1MzYxQzMuNTIxNjMgNi4wOTMyMiAzLjUwOTc3IDYuMDI4NTIgMy41MDk3NyA1Ljk1OTVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        width: 8px;
        height: 8px;
        margin-right: 3px;
      }

      &__text {
        font-weight: 400;
        font-size: 10px;
        line-height: 10px;
        letter-spacing: 0.1px;
      }
    }

    &__btn-wrap {
      text-align: right;
      .btn {
        width: 100px;
      }
      .btn-tooltip {
        text-align: left;
      }

      .disabled-only-opacity:hover,
      .disabled-only-opacity {
        border: 1px solid #c0c7ce;
        background-color: #fff;
        color: #637280;
        pointer-events: none;
        cursor: auto;
      }
    }

    &__meet-input {
      min-width: 60%;
      width: 60%;
      color: #2a86e1;
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.1px;
    }

    .dropdown {
      height: auto;
    }

    .lnk-dropdown {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-decoration-line: underline;
      color: #2a86e1;
    }
  }

  .dropdown .btn-block {
    font-size: 14px;
    line-height: 14px;
  }

  .calendar {
    width: 15px;
    height: 15px;
    position: absolute;
    left: 10px;
    top: 6px;
  }
}
