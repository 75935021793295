.proposed-modal-list {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -7px 32px;

  @include breakpointMax(medium) {
    margin: 20px 0 4px;
  }

  .proposed-order {
    width: 290px;
    height: 162px;
    padding: 12px;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e1e1e1;
    background-color: #ffff;
    margin: 7px;
    text-align: left;
    transition: all .3s;

    @include breakpointMax(medium) {
      margin: 0 0 16px;
      width: 100%;
    }

    &:hover {
      cursor: pointer;
      border-color: $colorAction10;
    }

    &--select * {
      color: #fff!important;
      font-weight: 400!important;
    }

    &--select {
      background-color: $colorAction10;
      cursor: pointer;

      .deadline-price-container__price {
        font-weight: 900!important;
      }

      .tooltip-content {
        color: $colorText!important;

        h4 {
          color: $colorText!important;
          font-weight: bold!important;
        }
      }
    }

    &--empty {
      background-color: #f7f9fa;
    }

    &__title {
      font-size: $fontSizeL;
      color: $colorAction10;
      @include fontFamilyBold;
      margin-bottom: 12px;
    }

    .tag-subject-container {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      min-height: 26px;

      .tags-list {
        margin: 0;
        height: 26px;
        padding-top: 3px;
      }

      &__subject {
        font-size: $fontSizeMd;
        @include fontFamilyBold;
        color: $colorDark;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        @include breakpointMax(medium) {
         font-size: $fontSizeL;
        }

      }
    }

    &__order-extended {
      line-height: 1.43;
      font-size: $fontSizeMd;
      color: $colorTextDark;
      margin-bottom: 12px;
    }

    .deadline-price-container {
      display: flex;
      line-height: 1.43;
      font-size: $fontSizeMd;
      justify-content: space-between;
      align-items: flex-end;

      &__deadline {
        color: $colorTextDark;
      }

      &__diff-text {
        @include fontFamilyBold;
        color: $colorDark;

        &.warning {
          color: $colorBright2_10;
        }
      }

      &__price {
        @include fontFamilyBolder;
        color: $colorDark;
      }
    }
  }
}
