.line-height-empty {
  line-height: normal !important;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-1-2 {
  line-height: 1.2;
}

.line-height-1-5 {
  line-height: 1.5;
}

.line-height-1-7 {
  line-height: 1.7;
}

.line-height-2 {
  line-height: 2;
}

