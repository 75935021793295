.ta-right {
  text-align: right;
}

.ta-right-flex {
  text-align: right;
  justify-content: flex-end;
}

.ta-center-flex {
  text-align: center;
  justify-content: center;
}

.ta-left {
  text-align: left;
}

.ta-center {
  text-align: center !important;
}

.centered {
  text-align: center;
}
