.star-container {
  width: calc(20% - 3px);
  height: auto;
  display: inline-block;
  padding-right: 3px;
  box-sizing: content-box;
}

.stras_wrap {
  margin-bottom: 7px;
  white-space: nowrap;
  + span {
    white-space: nowrap;
  }
  @include breakpoint(medium) {
    white-space: normal;
    + span {
      white-space: normal;
    }
  }
  .star-container {
    width: 8px;
  }
}
