@import 'scss/_variables.scss';

.step-container {

  border-bottom: none!important;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  .order-tab-collapse-head {
    cursor: pointer;
    height: auto!important;
  }

  &__check-icon {
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    margin-right: 5px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.867 0.956004C8.47227 0.547942 6.9845 0.589118 5.61447 1.0737C4.24445 1.55828 3.06165 2.46169 2.23366 3.65593C1.40567 4.85017 0.974473 6.27469 1.00117 7.72764C1.02787 9.18059 1.5111 10.5883 2.3824 11.7513C3.2537 12.9143 4.46889 13.7737 5.85579 14.2076C7.24269 14.6415 8.73098 14.628 10.1098 14.169C11.4886 13.71 12.688 12.8287 13.538 11.6501C14.3881 10.4715 14.8457 9.0552 14.846 7.602M5.186 7.14L7.5 9.453L14.5 2.499' stroke='%23E6EAEF' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    
    &--checked {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4363_26524)'%3E%3Cpath d='M9.867 0.955974C8.47227 0.547911 6.9845 0.589087 5.61447 1.07367C4.24445 1.55825 3.06165 2.46166 2.23366 3.6559C1.40567 4.85014 0.974473 6.27466 1.00117 7.72761C1.02787 9.18056 1.5111 10.5883 2.3824 11.7513C3.2537 12.9143 4.46889 13.7737 5.85579 14.2076C7.24269 14.6415 8.73098 14.628 10.1098 14.169C11.4886 13.71 12.688 12.8287 13.538 11.6501C14.3881 10.4714 14.8457 9.05517 14.846 7.60197M5.186 7.13997L7.5 9.45297L14.5 2.49897' stroke='%2300AF6F' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4363_26524'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    }
  }

  &__header {
    color: $colorText;
    font-size: $fontSizeSm;
    @include fontFamilyMedium;

    &--uncheck {
      color: $color09;
    }
  }

  &__arrow {
    width: 10px;
    height: 5px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 5L0 5L5 0L10 5Z' fill='%23389BFE'/%3E%3C/svg%3E%0A");
    transition: transform .3s;
    transform: rotate(180deg);
    
    &--active {
      transform: rotate(0deg);
    }

    &--disabled {
      opacity: 0.5;
    }
  }

  &__item {
    padding-top: 4px;
    margin-left: 20px;
  }

  &__tooltip {
    width: 150px!important;
    white-space: normal!important;
  }
}
