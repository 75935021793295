.btn-list {
  margin-bottom: 12px;
 &__item {
   height: 25px;
    border-radius: 12.5px;
    border: solid 1px #389bfe;
    background-color: #ffffff;
    color: #389bfe;
    padding: 3px 8px 4px;
    margin-right: 5px;
    cursor: pointer;

    &.active {
      background-color: #389bfe;
      color: #fff;
    }
    &.disabled {
      background-color: #fff;
      color: #5e5e5e;
      border: solid 1px #5e5e5e;
      opacity: 0.5;
    }
 } 
}
