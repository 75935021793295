.container_tags {
  &__item {
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    border: none;
    border-radius: 4px;
    background: none;
    padding: 4px 8px;
    margin: 0 5px 5px;
    font-size: 12px;
    text-transform: capitalize;

    &--active {
      color: #fff;
    }

    span {
      display: block;
      margin-left: 5px;
    }
  }
}
