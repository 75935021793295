@import 'scss/_variables.scss';

.story-icons-container {
  gap: 20px;
  padding: 10px 0;

  &.row {
    flex-wrap: wrap;
  }

  &__icon {
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      margin: 0;
    }
  }
}
