@import 'scss/_variables.scss';

.template-modal {

  &__header {
    @include fontFamilyBold;
    margin-top: -22px;
    position: relative;
    max-width: 331px;

    &:after {
      content: "";
      width: calc(100% + 69px);
      position: absolute;
      background-color: #E6E8EB;
      height: 1px;
      left: -28px;
      bottom: -14px;
    }
  }

  &__text {
    margin: 42px 0px 20px 0;
    overflow: auto;
  }
}
