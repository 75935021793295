$tabwidth: 600px;
$footerHeight: 27px;

.order-tab {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: $tabwidth;
  background-color: $colorWhite;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  z-index: 2;
  font-size: $fontSizeMd;
  animation: rightElements 0.5s ease-in-out;

  &--on-top {
    z-index: 13;
  }

  @include breakpoint(medium) {
    box-shadow: 0 0 20px 0 $boxShadow;
  }

  @keyframes rightElements {
    0% {
      right: -600px;
    }
    100% {
      right: 0;
    }
  }

  .order-tab-error {
    display: flex;
    height: 100%;
    width: 100%;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;

    p {
      color: $color06;
    }

    .emoji {
      font-size: $fontSizeTitle;
      margin-bottom: 10px;
    }

    .title {
      font-size: $fontSizeXL;
    }
  }

  .order-tab-loading {
    display: flex;
    height: 100%;
    width: 100%;
    text-align: center;
    align-items: center;

    p {
      color: $color06;
      justify-content: center;
      background: url("./img/icons/bars.svg") no-repeat center center;
      background-size: 45px auto;
      padding-top: 100px;
      width: 100%;
      height: 140px;
      margin-top: -40px;
    }
  }

  .order-line-loading {
    background: url("./img/icons/bars.svg") no-repeat 0 0;
    color: $color06;
    padding-left: 30px;
    background-size: 20px auto;
  }

  .order-tab-head {
    min-height: 50px;
    padding: 0 30px 0;
    box-shadow: 0 2px 9px 0 rgba(99, 114, 128, 0.28);
    position: relative;

    @include breakpoint(medium) {
      flex: 0 0 auto;
    }

    .person-info-rf-name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      max-width: 305px;
    }

    > .row {
      position: relative;
      align-items: center;
      min-height: 50px;
    }

    .client-score {
      display: flex;
      margin-left: 20px;

      h5,
      .hide-score {
        font-size: 13px;
        letter-spacing: 0.11px;
        margin-right: 8px;
        color: rgba(59, 70, 78, 0.5);
      }

      .hide-score {
        margin-left: 5px;
      }
    }

    .favorite-wrap {
      width: 30px;
    }

    .saved-star {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    .btn-close {
      position: absolute;
      right: -15px;
      top: 50%;
      margin-top: -15px;
      background: none;
    }

    .finger {
      position: absolute;
      top: 1px;
      transition: stroke $transitionTime;
      left: 0;
    }

    .order-report {
      margin: 0 30px 0 0;
      padding-left: 30px;
      position: relative;

      .dropdown > .btn {
        border: none;
        padding: 0;
        line-height: 30px;
      }

      &:hover {
        .dropdown > .btn {
          color: $colorAction09;
        }
      }

      .arrow-down {
        display: none;
      }

      .dropdown-content {
        width: 250px;
        border-radius: $borderRadius;
        box-shadow: 0 2px 4px 0 $colorBlackOpacity07;
        border: solid 1px $color06;
        padding: 10px;
        left: auto;
        right: 0;

        .btn-block {
          padding: 0;
          text-align: center;
          margin-bottom: 10px;
          transition: background-color $transitionTime;

          &.active,
          &:hover {
            color: $colorAction10;
            background-color: $colorAction03;
            border: 1pxsolid $colorAction09;
          }
        }

        .btn-light,
        .btn-bright {
          padding: 0;
        }

        .btn-bright {
          margin-left: 10px;
        }

        textarea {
          width: 100%;
          resize: none;
          padding: 10px 12px;
          height: 140px;
          margin-bottom: 10px;

          &.is-error {
            border: 1px solid $colorBright2_10;
          }
        }
      }

      .text-success {
        .check {
          position: relative;
          top: 3px;
          margin-right: 5px;
          fill: $colorBright1_09;
          width: 15px;
          height: 15px;
        }
      }
    }

    .issue-banner {
      height: 37px;
      background-color: #ecfff8;
      width: 600px;
      text-align: center;
      padding: 10px;
      margin: 0 -30px;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.12px;
      color: #00b369;
      line-height: 20px;
    }
  }

  .order-tab-top {
    display: flex;
    padding: 15px 0;
  }

  .order-detail-head {
    padding: 0 30px;
  }

  .order-detail-body {
    padding: 20px 30px;
    // overflow: hidden;
  }

  .detail-info {
    margin-bottom: 20px;
    .row {
      padding: 8px;
      letter-spacing: 0.1px;
      @include fontFamilyMedium;

      > div:first-child {
        color: $colorText;
        @include fontFamilyRegular;
      }
      > div:last-child {
        word-break: break-word;
      }
    }
    .row:nth-child(odd) {
      background-color: $color03;
      border-radius: $borderRadius;
    }

    .tooltip-template.tooltip-high-trigger:hover > .tooltip-content {
      min-width: 160px;
      width: 160px;
      word-break: break-word;
      @include breakpointMax(medium) {
        right: 0;
        top: 35px;
      }
    }

    ul, li {
      list-style: none;
    }
  }

  .order_description {
    padding: 8px 12px;
    background: #f7f9fa;
    border-radius: 8px;
  }

  .order-tab-container {
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1 1 auto;
    color: $color09;

    @include breakpointMax(medium) {
      background-color: #fff;
    }

    &--center-items {
      align-items: center;
      justify-content: center;

      display: flex;
    }

    .order-tab-collapse {
      border-bottom: 1px solid $color05;

      .order-tab-collapse-head {
        cursor: pointer;
        height: 40px;
        position: relative;
        @include fontFamilyMedium;
        display: flex;
        align-items: center;

        &--on-drag {
          background-color: #dff2fd;

          &:after {
            content: '';
            position: absolute;
            width: calc(100% - 4px);
            height: calc(100% - 5px);
            top: 0;
            left: 0;
            border: dashed 2px #0096eb;
            border-radius: 4px;
          }

          .svg-icon, .arrow-down {
            opacity: 0;
          }
        }

        .svg-icon {
          margin-right: 10px;
        }

        .arrow-down {
          position: absolute;
          top: 16px;
          right: 0;
          transform: rotate(-50grad);
          transition: transform $transitionTime;

          &.active {
            transform: rotate(50grad);
            border-color: #2a86e1;
          }
        }

        .order-tab-head-name {
          display: inline-block;
          line-height: 40px;

          @include breakpointMax(medium) {
            line-height: 14px;
            max-width: 250px;
          }
        }
      }

      .order-tab-collapse-body {
        padding: 0 0 0 22px;

        @include breakpointMax(medium) {
          padding: 0;
        }

        &.order-file-list {
          color: $color07;
          padding: 0 0 0 15px;

          @include breakpointMax(medium) {
            padding: 0;
          }

          .order-file {
            border-radius: 2px;
            border: solid 1px #ebebeb;
            position: relative;

            &:hover {
              .open-preview-icon {
                opacity: 1;
                pointer-events: all;
              }
            }

            .order-file-name {
              display: flex;
              align-items: center;

              padding-left: 28px;

              @include breakpointMax(medium) {
                padding: 0;
                flex: auto;
                width: 100%;
                white-space: nowrap;
                max-width: 250px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
              }

            }
            .open-preview-icon {
              width: 24px;
              height: 24px;
              display: block;
              background-image: url('./img/icons/open-file-preview.svg');
              background-repeat: no-repeat;
              background-position: center;
              opacity: 0;
              pointer-events: none;

            }
          }
          label.checkbox {
            padding: 0;
            display: flex;
            height: 22px;
            align-items: center;
            position: absolute;
            top: 50%;
            transform: translateY(-56%);
            left: 6px;
            z-index: 1;
          }
          


          .order-file-date {
            font-size: $fontSizeXSm;
            line-height: 16px;
            color: $color07;

            @include breakpointMax(medium) {
              padding: 0;
              text-align: left;
              flex: auto;
            }

          }

          .row {
            padding: 5px 6px;
            cursor: pointer;
            transition: background-color $transitionTime;
            margin-top: 8px;

            &:hover {
              background-color: $colorAction04;
              border-radius: $borderRadius;
              text-decoration: none;

              .order-file-date {
                color: $colorText;
              }
            }
          }
        }

        &.order-client-info {
          .row {
            padding: 8px;
            letter-spacing: 0.1px;
            @include fontFamilyMedium;

            > div:first-child {
              color: $colorText;
              @include fontFamilyRegular;
            }
            > div:last-child {
              word-break: break-all;
            }
          }
          .row:nth-child(odd) {
            background-color: $color03;
            border-radius: $borderRadius;
          }
        }

        .order-tab-content {
          padding-bottom: 20px;

          .btn-delete-file {
            position: relative;
            top: 1px;
            margin-left: 5px;
          }

          .upload-file-item {
            padding: 5px 6px;
            border-radius: 2px;
            border: solid 1px #ebebeb;
            transition: background-color $transitionTime;
            cursor: pointer;

            &__file-name {
              padding-left: 28px;
              
              @include breakpointMax(medium) {
                width: 100%;
                position: relative;
                padding: 0;
              }
            }

            &:hover {
              background-color: $colorAction04;
              border-radius: $borderRadius;
              text-decoration: none;

              .order-file-date {
                color: $colorText;
              }

              .open-preview-icon {
                opacity: 1;
                pointer-events: all;
              }
            }

            &:not(:first-child) {
              margin-top: 8px;
            }

            .download-all {
              float: right;
              margin-top: -12px;
              margin-bottom: 8px;
            }
  
            .open-preview-icon {
              width: 24px;
              height: 24px;
              display: block;
              background-image: url('./img/icons/open-file-preview.svg');
              background-repeat: no-repeat;
              background-position: center;
              opacity: 0;
              pointer-events: none;

            }

            .order-file-date {
              font-size: $fontSizeXSm;
              line-height: 16px;
              color: $color07;

              @include breakpointMax(medium) {
                padding: 0;
                text-align: left;
                flex: auto;
              }
            }

            a {
              word-break: break-all;
              white-space: normal;

              span {
                display: inline-block;
                max-width: 135px;
                vertical-align: top;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
        
                @include breakpointMax(medium) {
                  max-width: 160px;
                  width: 100%;
                  &.no-plag {
                     max-width: 230px;
                     width: 100%;
                  }
                }
              }

              .ta-right {
                @include breakpointMax(medium) {
                  padding: 0;
                }
              }
            }

            .plagreport {
              margin-right: 5px;
              position: relative;
              top: 1px;
            }
          }
          label.checkbox {
            padding: 0;
            display: flex;
            position: absolute;
            top: 50%;
            transform: translateY(-56%);
            left: 6px;
            z-index: 1;
          }
        }

        .order-comment {
          white-space: pre-wrap;
          line-height: 1.5;
          letter-spacing: 0.1px;
          padding-left: 3px;
        }
      }
    }

    .additional-comment {
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 0.12px;
      color: #38464f;
      p {
        white-space: pre-wrap;
        word-break: break-all;
      }
    }

    .comment-title {
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 1px;
      color: #99a9b7;
      padding: 15px 0;
      text-transform: uppercase;
    }

    .order-info-statuses {
      margin-bottom: 6px;
    }

    .order-info-chat-container {
      padding-top: 20px;
      position: relative;

      &:before {
        content: '';
        width: calc(100% + 60px);
        height: 1px;
        background-color: #e6e8eb;
        position: absolute;
        top: 20px;
        left: -30px;
        z-index: 2;
      }
    }

    .order-info-help-format {
      li {
        &:before {
          content: "";
          background-image: url("data:image/svg+xml,%3Csvg width='4' height='4' viewBox='0 0 4 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='2' cy='2' r='2' fill='black'/%3E%3C/svg%3E%0A");
          min-width: 5px;
          height: 5px;
          display: block;
          background-repeat: no-repeat;
          margin-right: 5px;
          margin-top: 3px;
          background-size: contain;
        }
      }

      &__item {
        line-height: 22px;
        @include fontFamilyBold;
        max-width: 279px;
      }
    }

    /* order-detail */
    .order-info-wrap {
      color: $colorText;
    }

    .order-number {
      line-height: 22px;
      font-size: 14px;
      display: inline-block;
      vertical-align: top;
      letter-spacing: 0.12px;
      color: #637280;
    }

    h1 {
      font-size: $fontSizeTitle;
      margin-bottom: 15px;
      @include fontFamilyMedium;
      letter-spacing: 0.2px;
      word-break: break-all;
    }

    .order-info-wrap {
      padding-bottom: 15px;

      .dropdown-content {
        left: auto;
        right: 0;
      }
    }

    .order-info {
      .clock {
        fill: $colorWhite;
        top: 0;
      }
      .error {
        color: $colorBright2_10;
        display: inline;

        .clock {
          fill: $colorBright2_10;
        }
      }
    }

    .order-info li {
      margin-bottom: 5px;
      // white-space: pre;
      line-height: 1.57;
      letter-spacing: 0.1px;
      color: #637280;

      &.size-order {
        color: #3b464e;
        white-space: normal;

        display: flex;
        // align-items: center;
      }
      .english-type {
        padding: 0 4px;
        display: inline-block;
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }

    .tooltip-trigger {
      &.tooltip-trigger-size {
        margin-left: 5px;
        position: relative;
        top: 2px;
      }

      &:hover + .tooltip-content {
        top: 20px;
        bottom: auto;
        min-width: 160px;

        &__300 {
          min-width: 300px;
        }

        &.order-tooltip {
          right: auto;
          left: 100%;
          white-space: normal;
          text-align: left;

          &--right {
            right: 0;
            left: auto;
          }

          &--left {
            left: 0;
            right: auto;
          }

          &--w160 {
            max-width: 160px;
          }

          li {
            color: $colorText;
            line-height: 1.4;

            @include breakpoint(medium) {
              color: #fff;
            }

            &:not(:last-child) {
              margin-bottom: 8px;
            }
          }
        }

        &.output-format-tooltip {
          width: 300px;
          white-space: normal;
          right: auto;
          top: 24px;

          &--right {
            right: 0;
          }

          li {
            line-height: 22px;
            color: #fff;

            background-color: transparent;
            border: none;
            padding: 0;
            font-weight: normal;
            margin-bottom: 5px;
        
            &:before {
              content: "";
              background-image: url("data:image/svg+xml,%3Csvg width='4' height='4' viewBox='0 0 4 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='2' cy='2' r='2' fill='white'/%3E%3C/svg%3E%0A");
              min-width: 5px;
              height: 5px;
              display: block;
              background-repeat: no-repeat;
              margin-right: 6px;
              background-size: contain;
            }
          }

          &--estimate {
            li {
              &:before {
                margin-top: 8px;
              }
            }
          }
        }

        &.size-order-tooltip {
          width: 270px;

          li {
            list-style-type: disc;
            list-style-position: inside;
          }
        }

        &.btn-tooltip {
          top: 35px;
          text-align: left;
        }

        &.top-btn-tooltip {
          top: unset;
          bottom: calc(100% + 5px);
          text-align: left;
        }

        &.tooltip-with-list {
          ul {
            list-style: disc;
            padding: 4px 15px;
          }
        }
      }
    }

    .order-cost {
      font-size: $fontSizeTitleSm;
      @include fontFamilyBold;
      letter-spacing: 0.2px;
      display: block;
      color: $color09;
      margin-top: 10px;
    }

    /* ./order-detail */
    .order-tab-detail {
      line-height: 1.73;
      font-size: $fontSize;
      white-space: pre-wrap;
      padding: 15px 0 0 0;
      word-break: break-all;

      img {
        width: 300px;
        height: 300px;
        display: block;
        margin: 0 auto;
        cursor: zoom-in;
      }

      div, span, pre, td, tr , b {
        font-size: $fontSize!important;
        line-height: 1.73!important;
    
        font-family: 'Lato', sans-serif!important;
        font-weight: normal!important;
        display: block!important;
      }
    }

    .order-tab-actions {
      margin: 15px 0;

      &.my-reserve {
        .row {
          flex-wrap: nowrap;

          @include breakpoint(medium) {
            flex-wrap: wrap;
          }
        }
      }

      .order-tab-actions-info {
        color: #9ca9b5;

        white-space: nowrap;

        @include breakpoint(medium) {
          white-space: normal;
        }

        &.my-reserve {
          color: $colorBright1_09;
        }
      }
      @include breakpointMax(medium) {
        & button:not(.mb15) {
          &:not(:last-child) {
            margin-bottom: 15px;
          }
        }
      }

      .text-success {
        font-size: $fontSizeSm;
        margin-right: 15px;
      }

      .views-count {
        @include fontFamilyBold;
      }
    }

    .bg-context-wrap {
      display: flex;
      justify-content: flex-end;
      .bg-context {
        // width: 250px;
        display: inline-block;
        padding: 0;
        background-color: transparent;
        border: none;
      }
    }
    .desc-instruction {
      line-height: 18px;
      font-style: italic;
      font-weight: bold;
      font-size: 12px;
      display: flex;
      align-items: center;
    }

    .notes {
      font-size: 12px;
      color: $color07;
      letter-spacing: 0.1px;

      b {
        @include fontFamilyMedium;
      }
    }

    .date-uplode {
      font-size: $fontSizeSm;
      line-height: 1.15;
      letter-spacing: 0.1px;
      color: $color07;
    }

    .upload-btns {
      justify-content: flex-end;

      .btn-upload {
        margin-left: 3px;
      }
    }
  }

  .order-tab-collapse-footer-fixed {
    z-index: 1;
    .btn-show-all {
      background-color: $color03;
      margin-right: -20px;
    }
  }

  .order-tab-collapse-footer {
    .btn-show-all.active .arrow-down-xs {
      transform: rotate(200grad);
    }
  }

  .order-tab-footer {
    background-color: $color03;
    flex: 0 0 auto;

    @include breakpointMax(medium) {
      & .static-bottom {
        position: fixed;
        bottom: 0;
        z-index: 2;
      }
    }

    .footer-container {
      padding: 0 30px;
    }

    a {
      font-size: $fontSize;
    }

    .open-new-window {
      fill: $colorText;
      top: 2px;
      margin-right: 5px;
    }

    .lnk-new-window {
      color: $colorText;
      font-size: $fontSizeXSm;
      transition: color $transitionTime;

      &:hover {
        color: $colorAction10;
        text-decoration: none;

        .open-new-window {
          fill: $colorAction10;
        }
      }
    }

    .dropdown-up {
      .btn-block {
        border: none;
        font-size: 13px;
        color: $colorAction10;
        background-color: transparent;
        padding: 0;
      }
      .arrow-down {
        display: none;
      }
    }
  }

  .grade-info {
    margin: 15px 0 20px;
    align-items: center;
  }

  .grade_sum {
    display: inline-flex;
    align-items: center;
    width: 65%;

    h3 {
      margin-right: 10px;
    }
    span {
      margin-left: 10px;
    }
  }

  .grade-box {
    padding: 15px 0 20px;

    .grade-box-head {
      border-bottom: 1px solid $color05;
      // margin-bottom: 20px;
    }

    .grade-comment {
      h4 {
        font-size: $fontSizeSm;
        color: $color07;
        letter-spacing: 0.1px;
        margin-bottom: 10px;
      }

      p {
        font-size: $fontSizeMd;
        line-height: 1.64;
        text-align: left;
        color: $color09;
        margin-bottom: 15px;
        word-wrap: break-word;
        white-space: pre-line;
      }
    }

    .upload-file-box {
      margin-bottom: 20px;
    }

    .files_title {
      font-size: 15px;
      font-weight: 900;
      color: #4e4e4e;
      margin-bottom: 15px;
    }

    .upload-file-item {
      margin-bottom: 10px;

      .delete-file {
        margin-left: 5px;
      }
    }

    .forder-file-date {
      font-size: 12px;
      line-height: 16px;
      color: $color07;
    }

    .cnt {
      position: absolute;
      right: 35px;
      top: 11px;
    }

    .person-info-box .person-info-item {
      border: none;

      > div:first-child {
        border: 1px solid $color11;
        border-radius: $borderRadius;
      }

      &.active {
        > div:first-child {
          border: 1px solid $color11;
          border-radius: $borderRadius $borderRadius 0 0;
        }
      }

      .radio-icon {
        position: static;
        margin-top: 0;
      }

      .radio-label {
        margin-left: 0;
      }

      .radiobutton {
        padding-left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ReactCollapse--content {
        padding: 0;
        overflow: scroll;

        @include breakpoint(medium) {
          overflow: auto;
        }
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      border-radius: 0 0 3px 3px;
      border: 1px solid $colorAction06;
      border-top: none;
      width: 100%;

      td,
      th {
        border-bottom: 1px solid $colorAction06;
        border-right: 1px solid $colorAction06;
        padding: 12px 10px;
        text-align: center;
      }

      th {
        background-color: $colorAction04;
        vertical-align: top;
        font-size: 13px;

        &:first-child {
          text-align: left;
          padding-left: 15px;
          vertical-align: middle;
        }
      }

      td {
        &:first-child {
          text-align: left;
          padding-left: 15px;
        }

        &:last-child {
          .radio-label {
            margin-left: 15px;
          }
        }
      }

      tr {
        th:last-child,
        td:last-child {
          border-right: none;
        }
      }

      tr:last-child {
        border-radius: 0 0 3px 3px;

        td {
          border-bottom: none;
        }

        td:first-child {
          border-radius: 3px;
        }
        td:last-child {
          border-radius: 3px;
        }
      }

      .tooltip .tooltip-trigger {
        position: relative;
        display: inline-block;
        top: 2px;
        margin-left: 5px;
        width: 15px;
        height: 15px;
        background-position: 0 0;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><g fill="none" fill-rule="evenodd" transform="translate(1 1)"><circle cx="6.5" cy="6.5" r="6.5" fill="#EBF7FF" stroke="#389BFE"/><path fill="#389BFE" d="M4.643 3.494a3.35 3.35 0 0 1 .359-.275 2.576 2.576 0 0 1 .913-.378 2.8 2.8 0 0 1 .573-.055c.28 0 .534.038.764.115.23.077.426.187.59.33.163.143.29.316.38.518.09.203.135.429.135.68 0 .244-.035.455-.106.633-.07.178-.159.333-.265.465a2.006 2.006 0 0 1-.35.342l-.358.272c-.112.085-.21.169-.291.25a.495.495 0 0 0-.147.278l-.111.703h-.814l-.082-.785a.638.638 0 0 1 .065-.398c.062-.114.146-.218.25-.313.105-.095.221-.186.35-.274.128-.089.248-.185.36-.29.113-.104.207-.221.283-.351a.91.91 0 0 0 .113-.465.703.703 0 0 0-.246-.547.81.81 0 0 0-.277-.152 1.113 1.113 0 0 0-.349-.053 1.556 1.556 0 0 0-.788.196c-.088.05-.163.094-.224.134a.308.308 0 0 1-.163.06.263.263 0 0 1-.251-.144l-.313-.496zm.91 5.68a.74.74 0 0 1 .208-.516.729.729 0 0 1 .52-.212c.103 0 .198.02.286.058a.71.71 0 0 1 .388.385c.039.09.058.185.058.285a.709.709 0 0 1-.214.515.732.732 0 0 1-.518.207.753.753 0 0 1-.29-.055.697.697 0 0 1-.438-.667z"/></g></svg>');
        transition: fill 0.5s;
        cursor: pointer;

        &:hover {
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><g fill="none" fill-rule="evenodd" transform="translate(1 1)"><circle cx="6.5" cy="6.5" r="6.5" fill="#389BFE" stroke="#389BFE"/><path fill="#ffffff" d="M4.643 3.494a3.35 3.35 0 0 1 .359-.275 2.576 2.576 0 0 1 .913-.378 2.8 2.8 0 0 1 .573-.055c.28 0 .534.038.764.115.23.077.426.187.59.33.163.143.29.316.38.518.09.203.135.429.135.68 0 .244-.035.455-.106.633-.07.178-.159.333-.265.465a2.006 2.006 0 0 1-.35.342l-.358.272c-.112.085-.21.169-.291.25a.495.495 0 0 0-.147.278l-.111.703h-.814l-.082-.785a.638.638 0 0 1 .065-.398c.062-.114.146-.218.25-.313.105-.095.221-.186.35-.274.128-.089.248-.185.36-.29.113-.104.207-.221.283-.351a.91.91 0 0 0 .113-.465.703.703 0 0 0-.246-.547.81.81 0 0 0-.277-.152 1.113 1.113 0 0 0-.349-.053 1.556 1.556 0 0 0-.788.196c-.088.05-.163.094-.224.134a.308.308 0 0 1-.163.06.263.263 0 0 1-.251-.144l-.313-.496zm.91 5.68a.74.74 0 0 1 .208-.516.729.729 0 0 1 .52-.212c.103 0 .198.02.286.058a.71.71 0 0 1 .388.385c.039.09.058.185.058.285a.709.709 0 0 1-.214.515.732.732 0 0 1-.518.207.753.753 0 0 1-.29-.055.697.697 0 0 1-.438-.667z"/></g></svg>');
        }
      }

      .tooltip .tooltip-trigger > .tooltip-content,
      .tooltip .tooltip-trigger + .tooltip-content {
        bottom: calc(100% + 3px);
        top: auto;
        left: 100%;
        padding: 0;
        z-index: auto;
      }

      .tooltip .tooltip-trigger:hover > .tooltip-content,
      .tooltip .tooltip-trigger:hover + .tooltip-content {
        padding: 10px 15px;
        font-size: $fontSizeSm;
        @include fontFamilyMedium;
        letter-spacing: 0.1px;
        line-height: 16px;
      }
    }
  }

  .container_fix {
    border: solid 1px #dadee2;
    border-radius: 3px;
  }

  .container_fix_header {
    background-color: #f7f9fa;
    border-bottom: solid 1px #dadee2;
    padding: 11px 15px;
    position: relative;

    h5 {
      font-weight: 900;
      color: #637280;
      font-size: 14px;
      display: inline-block;
    }

    .tooltip .tooltip-trigger {
      position: relative;
      display: inline-block;
      top: 2px;
      margin-left: 5px;
      width: 15px;
      height: 15px;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><g fill="none" fill-rule="evenodd" transform="translate(1 1)"><circle cx="6.5" cy="6.5" r="6.5" fill="#EBF7FF" stroke="#389BFE"/><path fill="#389BFE" d="M4.643 3.494a3.35 3.35 0 0 1 .359-.275 2.576 2.576 0 0 1 .913-.378 2.8 2.8 0 0 1 .573-.055c.28 0 .534.038.764.115.23.077.426.187.59.33.163.143.29.316.38.518.09.203.135.429.135.68 0 .244-.035.455-.106.633-.07.178-.159.333-.265.465a2.006 2.006 0 0 1-.35.342l-.358.272c-.112.085-.21.169-.291.25a.495.495 0 0 0-.147.278l-.111.703h-.814l-.082-.785a.638.638 0 0 1 .065-.398c.062-.114.146-.218.25-.313.105-.095.221-.186.35-.274.128-.089.248-.185.36-.29.113-.104.207-.221.283-.351a.91.91 0 0 0 .113-.465.703.703 0 0 0-.246-.547.81.81 0 0 0-.277-.152 1.113 1.113 0 0 0-.349-.053 1.556 1.556 0 0 0-.788.196c-.088.05-.163.094-.224.134a.308.308 0 0 1-.163.06.263.263 0 0 1-.251-.144l-.313-.496zm.91 5.68a.74.74 0 0 1 .208-.516.729.729 0 0 1 .52-.212c.103 0 .198.02.286.058a.71.71 0 0 1 .388.385c.039.09.058.185.058.285a.709.709 0 0 1-.214.515.732.732 0 0 1-.518.207.753.753 0 0 1-.29-.055.697.697 0 0 1-.438-.667z"/></g></svg>');
      transition: fill 0.5s;
      cursor: pointer;

      &:hover {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><g fill="none" fill-rule="evenodd" transform="translate(1 1)"><circle cx="6.5" cy="6.5" r="6.5" fill="#389BFE" stroke="#389BFE"/><path fill="#ffffff" d="M4.643 3.494a3.35 3.35 0 0 1 .359-.275 2.576 2.576 0 0 1 .913-.378 2.8 2.8 0 0 1 .573-.055c.28 0 .534.038.764.115.23.077.426.187.59.33.163.143.29.316.38.518.09.203.135.429.135.68 0 .244-.035.455-.106.633-.07.178-.159.333-.265.465a2.006 2.006 0 0 1-.35.342l-.358.272c-.112.085-.21.169-.291.25a.495.495 0 0 0-.147.278l-.111.703h-.814l-.082-.785a.638.638 0 0 1 .065-.398c.062-.114.146-.218.25-.313.105-.095.221-.186.35-.274.128-.089.248-.185.36-.29.113-.104.207-.221.283-.351a.91.91 0 0 0 .113-.465.703.703 0 0 0-.246-.547.81.81 0 0 0-.277-.152 1.113 1.113 0 0 0-.349-.053 1.556 1.556 0 0 0-.788.196c-.088.05-.163.094-.224.134a.308.308 0 0 1-.163.06.263.263 0 0 1-.251-.144l-.313-.496zm.91 5.68a.74.74 0 0 1 .208-.516.729.729 0 0 1 .52-.212c.103 0 .198.02.286.058a.71.71 0 0 1 .388.385c.039.09.058.185.058.285a.709.709 0 0 1-.214.515.732.732 0 0 1-.518.207.753.753 0 0 1-.29-.055.697.697 0 0 1-.438-.667z"/></g></svg>');
      }
    }

    .tooltip .tooltip-trigger + .tooltip-content {
      bottom: calc(100% + 3px);
      top: auto;
      left: 100%;
      padding: 0;
      z-index: auto;
    }

    .tooltip .tooltip-trigger:hover + .tooltip-content {
      padding: 10px 15px;
      font-size: $fontSizeSm;
      @include fontFamilyMedium;
      letter-spacing: 0.1px;
      line-height: 16px;
    }
  }

  .container_fix_cost {
    position: absolute;
    font-weight: 900;
    letter-spacing: 0.1px;
    color: #fc694f;
    right: 15px;
    top: 14px;
  }

  .container_fix_body {
    padding: 14px 15px 13px;

    .checkbox-label {
      white-space: normal;
    }
  }

  .icon-time {
    width: 15px;
    height: 15px;
    margin-bottom: -2px;
    margin-right: 5px;
    stroke: #9ca9b5;

    display: none;

    @include breakpoint(medium) {
      display: inline-block;
    }

    &--green {
      stroke: $colorBright_1_07;
    }
  }
}

.uploader-box {
  margin-bottom: 10px;

  .upload-item {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    line-height: 1.07;
    letter-spacing: 0.1px;
    margin-bottom: 10px;

    a {
      text-decoration: none;
    }

    .delete-file {
      margin-left: 5px;
    }

    .plagreport {
      margin-right: 5px;
    }
  }
}

.upload-file-progress {
  position: relative;
  display: inline-block;
  padding: 3px 0;
}

.bg-progress {
  display: inline-block;
  position: absolute;
  height: 100%;
  background-color: $colorAction06;
  z-index: 0;
  left: 0;
  top: 0;
}

.file-name-progress {
  position: relative;
  z-index: 1;
}

.single-page-container {
  .tooltip-trigger {
    &:hover + .tooltip-content {
      &.size-order-tooltip {
        bottom: auto;
      }
    }
  }
}
