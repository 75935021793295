.mt2 {
  margin-top: 2px;
}

.mt4 {
  margin-top: 4px;
}

.mt8 {
  margin-top: 8px;
}

.mt10 {
  margin-top: 10px;
}

.mt12 {
  margin-top: 12px;
}

.mt15 {
  margin-top: 15px;
}

.mt18 {
  margin-top: 18px;
}

.mt20 {
  margin-top: 20px;
}

.mt24 {
  margin-top: 24px;
}

.mt30 {
  margin-top: 30px;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb4 {
  margin-bottom: 4px;
}

.mb8 {
  margin-bottom: 8px;
}


.mb10 {
  margin-bottom: 10px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb14 {
  margin-bottom: 14px;
}

.mb16 {
  margin-bottom: 15px;
}


.mb20 {
  margin-bottom: 20px;
}

.mb24 {
  margin-bottom: 24px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mr3 {
  margin-right: 3px;
}

.mr4 {
  margin-right: 4px;
}

.mr8 {
  margin-right: 8px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr12 {
  margin-right: 12px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr40 {
  margin-right: 40px;
}

.ml0 {
  margin-left: 0;
}

.ml4 {
  margin-left: 4px;
}

.ml8 {
  margin-left: 8px;
}

.ml10 {
  margin-left: 10px;
}

.ml12 {
  margin-left: 12px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.ml40 {
  margin-left: 40px;
}
