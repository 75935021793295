.colors-container {
  display: flex;
  flex-direction: column;
  gap: 0 10px;

  &__color {
    width: 20px;
    height: 20px;
    border: 1px solid black;
  }

  &__name {
    min-width: 220px;
    margin-bottom: 20px;
  }
}
