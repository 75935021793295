@import 'scss/_variables.scss';

.frame_container {
  width: 824px;
  opacity: 0;
  position: absolute;
  height: 100vh;
  z-index: -1;

  @include breakpointMax(medium) {
    width: 336px;
    height: 100%;
  }
  
  &--show {
    opacity: 1;
    z-index: 1;
    @include breakpointMax(medium) {
      position: relative;
    }
  }
}

