.reject-btn-container {
  a {
    color: $colorBright1_10;
  }
  &--penalty {
    a {
      color: $colorBright2_08;
    }
  }

  .tooltip-container {
    margin-left: 5px;
    top: 2px;

    .svg-icon {
      transition: none;
    }
  }

  .reject-btn-container-modal {
    position: relative;
    &--sending {
      opacity: .5;
      pointer-events: none;
    }
    &__penalty-header {
      text-align: center;

      .penalty-header-text {
        font-size: $fontSizeTitleSm;
        color: $colorBright2_10;
        text-transform: uppercase;
      }
    }
    &__no-penalty-header {
      font-size: $fontSize;
      line-height: 1;
      color: $colorBlack;
      white-space: initial;
      text-align: center;
      margin-bottom: 8px;

      &--title {
        margin-top: 8px;
        font-size: $fontSizeXSm;
      }
    }

    .modal-text {
      white-space: initial;
      text-align: start;
    }

    &__btn-block {
      & button {
        margin-bottom: 8px;
        color: $colorText;
        &.active {
          background-color: $colorAction03;
        }
        &.btn-penalty {
          border-color: $colorBright2_10;
          color: $colorBright2_10;
          background-color: transparent;
          &:hover {
            background-color: $colorBright2_07;
            color: #fff;
          }
          &.active {
            background-color: $colorBright2_07;
            color: #fff;
          }
        }
      }
    }

    &__reject-message {
      resize: none;
      width: 100%;
      min-height: 140px;
      border-radius: 3px;
      border: 1px solid $colorAction10;
      padding: 10px 12px;
      color: $colorText;
      margin-top: 20px;

      &:focus {
        outline: none;
      }
    }

    &__actions-block {
      margin-top: 20px;

      button {
        font-size: $fontSizeL;
        line-height: $fontSizeL;

        &.btn-error {
          background-color: $colorBright2_10;
          color: #fff;
          margin-bottom: 8px;
          &:disabled,
          &:disabled:hover {
            opacity: .5;
            font-size: $fontSizeL;
            line-height: $fontSizeL;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  @include breakpointMax(medium) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 4px;

    a {
      white-space: nowrap;
    }
  }
}
