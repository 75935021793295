.suspicious-alert {
  text-align: center;
  background-color: #fef0ed;
  font-size: 15px;
  font-weight: 900;
  color: #fc694f;
  padding: 10px;

  position: relative;


  &--animate {
    @include keyframeMarginTop(var(--marginAnim));
    @include breakpointMax(medium) {
      position: fixed!important;
      bottom: 0!important;
      padding: 36px 12px 12px 12px!important;
      z-index: 1000;
    }
  }

  &--medium-weight {
    @include fontFamilyMedium;
  }

  &--animate-show {
    margin-top: 0;
  }

  &--blue-bg {
    background-color: #EBF7FF;
  }

  &--black-text {
    color: $colorDark;
  }

  &--extra-padding {
    padding: 32px 12px;
  }

  .suspicious-alert-text-container {
    &__text {
      max-width: 880px;

      margin: auto;

      @include fontFamilyRegular;

      margin: auto;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  &__close-block {
    position: absolute;
    right: 21px;
    top: 12px;

    padding: 20px;
    margin: -20px;

    @include breakpoint(medium) {
      right: 15px;
      top: 10px;
    }

  }

  &__tutor-link {
    text-decoration: underline;

    @include breakpointMax(medium) {
      display: block;
      width: 100%;
    }
  }
}
