.progress-container {
  margin-top: 35px;

  aside {
    color: $colorText;
    position: relative;
  }

  .sidebar-border {
    padding-bottom: 24px;
    margin-bottom: 24px;

    &:last-child {
      border-bottom: none;
    }
  }

  .writer-group {
    font-size: $fontSizeXL;
    letter-spacing: 0.1px;
    margin-bottom: 5px;
  }

  .writer-group-notes {
    font-size: $fontSizeSm;
    color: $colorText;

    b {
      margin-left: 3px;
    }
  }

  h3 {
    letter-spacing: 0.1px;
    font-size: $fontSizeMd;
    @include fontFamilyBold;
    margin-bottom: 15px;
  }

  .aside-title {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    letter-spacing: 0.05px;
    color: #3b464e;
    margin-bottom: 8px;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .writer-group-head {
    font-size: $fontSizeXSm;
    margin-bottom: 5px;

    .lnk-dashed {
      height: 14px;
    }

    .text-success {
      color: $colorBright1_10;
      @include fontFamilyMedium;
    }
  }

  .writer-group-row {
    padding: 5px 0;
  }

  .tooltip {
    margin-left: 5px;
  }

  .tooltip-trigger {
    height: 14px;
    display: block;
  }

  .lnk-more {
    font-size: $fontSizeXSm;
    letter-spacing: 0.1px;
    display: inline-block;
    margin-top: 20px;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .title {
    @include fontFamilyBold;
    color: $colorText;
    padding: 25px 0 15px 40px;
    margin-bottom: 0;
  }

  .system-update,
  .money-income {
    margin: 0 10px;
  }

  .btn-success {
    cursor: default;
    text-transform: uppercase;
    line-height: 19px;
    font-size: $fontSizeXs;
    @include fontFamilyMedium;
    letter-spacing: 0.1px;

    &:hover {
      background-color: $colorBrightOpacity1_09;
    }
  }

  .tooltip-content {
    .row {
      margin: 7px 0;
    }
  }
}

.benefit-banner {
  margin-top: 28px;
  padding: 10px 10px 10px 90px;
  border-radius: 8px;
  background-color: #dcf1f4;
  background-image: url(./img/icons/banner35.svg);
  background-repeat: no-repeat;
  background-size: contain;

  h4 {
    font-size: 17px;
    font-weight: 900;
    line-height: 1.06;
    letter-spacing: 0.12px;
    color: #fe7800;
    margin-bottom: 8px;
  }

  p {
    font-size: 13px;
    line-height: 1.38;
    letter-spacing: 0.09px;
    color: #3b464e;
  }

  .text-success {
    font-weight: 900;
    color: #14a0b4;
  }
}

.bonuses-dashboard {
  border-radius: 3px;
  background-color: #12ca86;
  padding: 17px 20px;
  color: #fff;
  margin: 40px 0 0;
  font-size: 15px;
  line-height: 1.33;
  letter-spacing: 0.3px;
  text-align: center;

  &:before {
    content: "";
    display: block;
    background-image: url(./img/icons/dollar-icon.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    height: 45px;
    margin-bottom: 10px;
  }
}

.row_info {
  font-size: 14px;

  &-title {
    display: flex;
    padding: 6px 15px;
    cursor: pointer;
    border: solid 1px #d6e9ff;
    border-radius: 3px;

    &:hover {
      background-color: #f3f8fd;
      border: solid 1px #8dbdff;
    }
  }
  &.active {
    .row_info-title {
      background-color: #2a86e1;
      color: #fff;
      border-radius: 3px 3px 0 0;
      border: 1px solid #2a86e1;
    }
    h5,
    .active_cnt {
      color: #fff;
    }
  }

  h5 {
    font-size: 14px;
    letter-spacing: 0.1px;
    color: #4e4e4e;
    margin: 0;
    width: 50%;
  }

  .cnt {
    position: absolute;
    right: 35px;
    top: 8px;
  }

  .active_cnt {
    color: red;
  }

  .fade {
    color: #9ca9b5;
  }

  background-color: $colorWhite;
  border-radius: 3px;
  position: relative;
  margin-bottom: 15px;

  .arrow_down {
    position: absolute;
    right: 10px;
    top: 15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5.5px 0 5.5px;
    border-color: #3099ff transparent transparent transparent;
    transform: translate(0, -50%) rotate(-90deg);

    &.active {
      transform: none;
      top: 13px;
      border-color: #fff transparent transparent transparent;
    }
    &.fade {
      border-top-color: #9ca9b5;
    }
  }

  .button_link {
    background: none;
    border-color: transparent;
  }
}

.scores-content,
.warning-content {
  padding: 30px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.05px;
  color: #4e4e4e;

  b {
    font-weight: 600;
  }

  .description {
    margin-bottom: 16px;
  }

  &-newbie-tooltip {
    position: absolute;
    top: 80px;
    right: 20px;
  }
  &-nextlvl-tooltip-container {
    position: absolute;
    top: 37px;
    right: 0;
  }
  .level-description &-nextlvl-tooltip-container &-nextlvl-tooltip {
    z-index: 1;
  }

  .ul-list {
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: 16px;
  }
}

.scores-table {
  .table-header {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.12px;
    color: #627281;
    margin-bottom: 10px;

    .row {
      padding: 6px 20px;
    }
  }

  .table-row {
    padding: 6px 20px;

    &:nth-child(odd) {
      border-radius: 3px;
      background-color: #f7fbfc;
    }
  }

  .scores-lnk {
    &:hover {
      color: #2383e5;
      cursor: pointer;
    }
  }

  .scores-view {
    font-size: 14px;
    letter-spacing: 0.13px;
    color: #2a86e1;
    padding-left: 20px;
    background-image: url(./img/icons/mark-as-seen.svg);
    background-position: left center;
    background-repeat: no-repeat;
    cursor: pointer;

    &.active {
      color: #9ca9b5;
      background-image: url(./img/icons/seen.svg);
      cursor: auto;
    }

    &.cancelled {
      background: none;
      color: #9ca9b5;
      cursor: auto;
    }
  }

  .scores-comment {
    display: block;
    white-space: nowrap;
    max-width: 290px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
