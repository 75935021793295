@import 'scss/_variables.scss';

.info-modal {
  color: $colorDark;

  @include breakpointMax(medium) {
    padding: 38px 20px 30px 28px;
  }

  .info-modal-container {
    &__header {
      font-size: $fontSizeXL;
  
      @include fontFamilyMedium;
  
      margin-bottom: 24px;

      &--error {
        color: $colorBright2_10;
      }
    }
  
    &__text {
      font-size: $fontSizeL;
  
      line-height: 24px;
  
      margin-bottom: 28px;
    }

    &__text-light {
      font-size: $fontSizeMd;

      line-height: 20px;

      margin-bottom: 28px;
      margin-top: -18px;
    }

    &__sm-text-light {
      font-size: $fontSizeMd;
      line-height: 24px;
      color: $colorTextLightGrey;
    }

    &__info-orange-icon {
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 20C16.4183 20 20 16.4183 20 12C20 7.58173 16.4183 4 12 4C7.58172 4 4 7.58173 4 12C4 16.4183 7.58172 20 12 20ZM12 18.6667C15.6819 18.6667 18.6667 15.6819 18.6667 12C18.6667 8.31811 15.6819 5.33334 12 5.33334C8.3181 5.33334 5.33333 8.31811 5.33333 12C5.33333 15.6819 8.3181 18.6667 12 18.6667Z' fill='%23FFA65D'/%3E%3Cpath d='M12.6667 8C12.6667 8.36819 12.3682 8.66667 12 8.66667C11.6318 8.66667 11.3333 8.36819 11.3333 8C11.3333 7.63181 11.6318 7.33334 12 7.33334C12.3682 7.33334 12.6667 7.63181 12.6667 8Z' fill='%23FFA65D'/%3E%3Cpath d='M11.3333 10.6667C11.3333 10.2985 11.6318 10 12 10C12.3682 10 12.6667 10.2985 12.6667 10.6667V16C12.6667 16.3682 12.3682 16.6667 12 16.6667C11.6318 16.6667 11.3333 16.3682 11.3333 16V10.6667Z' fill='%23FFA65D'/%3E%3C/svg%3E%0A");
    }
  
    &__info-orange-text {
      font-size: $fontSizeXL;
  
      color: #FFA65D;
  
      @include fontFamilyBold;

      @include breakpointMax(medium) {
        max-width: 243px;
      }

      &--fs-14 {
        font-size: $fontSizeMd;
      }
    }
  }

  &__close_icon {
    position: absolute;
    right: 0;
    top: -26px;

    @include breakpointMax(medium) {
      top: 10px;
      right: 10px;
    }
  }

  .btn-next {
    @include breakpointMax(medium) {
      width: 100%;
    }
  }

}

