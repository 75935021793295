@import 'scss/_variables.scss';

.personal-info-section {
  &__warning-text {
    color: $colorBright2_10;
    font-size: 12px;

    @include fontFamilyBolder;
  }
}
