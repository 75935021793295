@import 'scss/_variables.scss';

.poll-modal {
  letter-spacing: 0.15px;

  &--loading {
    opacity: .8;
    pointer-events: none;
  }

  &.modal-container.modal-sm {
    width: 333px !important;
    min-width: 328px !important;
    padding: 22px 32px;

    @include breakpointMax(medium) {
      padding: 22px 16px;
      width: 335px !important;
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    }
  }

  .title {
    line-height: 1.31;
    margin-bottom: 23px;

    @include breakpointMax(medium) {
      font-size: 18px;
      margin-bottom: 16px;
      line-height: 1.33;
      font-weight: 900!important;
    }
  }

  &__second-title {
    line-height: 1.5;
    color: #3b464e;
    margin-bottom: 20px;
    font-size: $fontSizeL;

    @include breakpointMax(medium) {
      margin-bottom: 16px;
      color: $color10;
    }
  }

  &__input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    @include breakpointMax(medium) {
      margin-bottom: 12px;
      justify-content: center;
    }

    .input-label {
      @include fontFamilyBolder;
      line-height: 1.5;
      text-align: left;

      @include breakpointMax(medium) {
        margin-right: 36px;
        min-width: 83px;
        text-align: left;
      }
    }

    .input-el {
      max-width: 140px;
      position: relative;

      &--single {
        margin: 0 auto;

        @include breakpointMax(medium) {
          margin-top: 4px;
        }
      }

      .input-text {
        outline: none;
        max-width: inherit;
      }

      &:after {
        content: 'pages';
        font-size: 14px;
        color: $color06;
        letter-spacing: 0.12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 7px;
      }
    }

  }

  .poll-info-block {
    padding: 12px 23px;
    background-color: $colorBrightOpacity1_09;
    margin-bottom: 30px;
    text-align: center;
    font-size: $fontSizeMd;
    letter-spacing: 0.12px;
    border-radius: 8px;
    white-space: nowrap;

    @include breakpointMax(medium) {
      max-width: 264px;
      margin: 16px auto 20px;
    }

    &__title {
      @include fontFamilyBolder;
      margin-bottom: 8px;
      color: $colorText;

      @include breakpointMax(medium) {
        font-size: $fontSizeL;
        @include fontFamilyBold;
        color: $colorTextDark;
      }
    }

    &__result {
      color: #5e5e5e;

      &:not(:last-child) {
        margin-bottom: 2px;
      }

      span {
        color: $colorBright1_09;
        @include fontFamilyBold;
      }
    }
  }

  .poll-footer-block {
    margin-bottom: 20px;

    &__title {
      @include fontFamilyBolder;
      color: $colorText;
      line-height: 1.5;
      letter-spacing: 0.13px;
      font-size: $fontSizeSm;

      @include breakpointMax(medium) {
        color: $colorTextDark;
        @include fontFamilyBold;
        font-size: $fontSizeMd;
      }
    }

    &__text {
      line-height: 1.33;
      letter-spacing: 0.11px;
      color: $color09;
      font-size: $fontSizeXSm;

      @include breakpointMax(medium) {
        color: #667580;
        font-size: $fontSizeMd;
        line-height: 1.43;
      }
    }
  }

  .btn {
    background-color: $colorAction09;
    color: #fff;
    font-size: $fontSizeMd;
    line-height: $fontSizeMd;
    letter-spacing: 0.11px;

    @include breakpointMax(medium) {
      height: 44px;
      font-size: $fontSizeL;
      background-color: #2383e5;
      @include fontFamilyBolder;
      max-width: 264px;
      margin: 0 auto;
    }

  }

  &__icon {
    width: 22px;
    height: 21px;
    display: inline-block;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: -2px;

    &--hi {
      background-image: url('../img/hi-icon.png');
    }

    &--paper {
      background-image: url('../img/paper.png');
    }
  }
}
