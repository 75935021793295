@import 'scss/_variables.scss';

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
  left: -99999px;
  top: 0;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 30px;
  height: 20px;
  border: 1px solid #bdbdbd;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 2px;
  width: 15px;
  height: 15px;
  border-radius: 45px;
  transition: 0.2s;
  background: #bdbdbd;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 16px);
  background: #fff;
}

.react-switch-checkbox:checked + .react-switch-label {
  background: #2383e5;
  border-color: #2383e5;
}
