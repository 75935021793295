.writer-warnings {
  display: flex;
  color: #e5264b;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.03px;
  align-items: center;
  border-radius: 4px;
  background-color: rgba(229, 38, 75, 0.15);
  padding: 1px 6px;
  margin-left: 11px;

  &:hover {
    text-decoration: none;
  }
}

.client-avg {
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.04px;
  color: #637280;
  margin-left: 6px;
}

.writer-level {
  display: inline-flex;
  align-items: center;
  margin: 0 10px 0 15px;
  border-left: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  padding-left: 10px;
  padding-right: 15px;

  &-item {
    margin: 0 2.5px;
    font-weight: 700;
    width: 21px;
    height: 21px;
    line-height: 21px;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    top: 1px;

    &-a {
      background-image: url("./img/icons/group-a.svg");
    }
    &-a-plus {
      background-image: url("./img/icons/group-a-plus.svg");
    }
    &-b {
      background-image: url("./img/icons/group-b.svg");
    }
    &-c {
      background-image: url("./img/icons/group-c.svg");
    }
    &-d {
      background-image: url("./img/icons/group-d.svg");
    }
    &-f {
      background-image: url("./img/icons/group-f.svg");
    }
  }

  &-ungroup {
    font-size: 14px;
    color: #3b464e;
    background: no-repeat center left;
    padding-left: 24px;
    display: inline-block;
    height: 22px;
    line-height: 22px;
  }

  &-newbie {
    background-image: url("./img/icons/level-newbie.svg");
    &-outsider {
      filter: grayscale(100%);
      opacity: 0.7;
    }
  }

  &-beginner {
    background-image: url("./img/icons/level-beginner.svg");
    &-outsider {
      filter: grayscale(100%);
      opacity: 0.7;
    }
  }

  .tooltip-header {
    .tooltip-container {
      .tooltip-trigger:hover + .tooltip-content {
        $tooltipContentWidth: 265px;
        line-height: 1.36;
        letter-spacing: 0.04px;
        color: #c0c7ce;
        width: $tooltipContentWidth;
        white-space: normal;
        border-radius: 5px;
        left: calc(-1 * ((#{$tooltipContentWidth} - 20px) / 2));
        
        .success {
          color: #0ec9e4;
        }

        .active-warnings {
          color: #f9be2d;
          font-weight: 900;
        }
      }
    }
  }

  .bage-pro {
    font-size: 11px;
    font-weight: 900;
    letter-spacing: 0.03px;
    color: #14a0b4;
    padding: 2px 4px;
    display: inline-block;
    background-color: rgba(20, 160, 180, 0.15);
    border-radius: 4px;
    margin-left: 10px;
  }
}

.level-description {
  padding-left: 33px;
  position: relative;
  padding-bottom: 25px;
  color: #030303;

  // b {
  //   font-weight: 600;
  // }

  p,
  ul {
    font-size: 14px;
  }

  h2 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.05px;
    color: #b1b5b9;
    text-transform: capitalize;
  }
  &.active h2 {
    color: #3b464e;
  }

  h3 {
    color: #b1b5b9;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.04px;
    font-weight: normal;
    margin-bottom: 12px;
  }

  &.active h3 {
    color: #030303;
  }

  h4 {
    color: #b1b5b9;
    font-size: 13px;
    font-weight: 900;
    line-height: 1.46;
    letter-spacing: 0.04px;
    margin-bottom: 4px;
  }

  &.active h4 {
    color: #637280;
  }

  &-label {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #e6e8eb;
    position: absolute;
    display: block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    left: -4px;
    top: 3px;
  }

  &.active &-label {
    height: 25px;
    width: 25px;
    left: -9px;
    top: -4px;
  }

  &.active.label-a-plus .level-description-label {
    background-image: url("./img/icons/group-a-plus.svg");
  }

  &.active.label-a .level-description-label {
    background-image: url("./img/icons/group-a.svg");
  }
  &.active.label-b .level-description-label {
    background-image: url("./img/icons/group-b.svg");
  }
  &.active.label-c .level-description-label {
    background-image: url("./img/icons/group-c.svg");
  }
  &.active.label-d .level-description-label {
    background-image: url("./img/icons/group-d.svg");
  }
  &.active.label-f .level-description-label {
    background-image: url("./img/icons/group-f.svg");
  }

  // .label-a h2 {
  //   color: #12ca86;
  // }
  // .label-b h2 {
  //   color: #ffb500;
  // }
  // .label-c h2 {
  //   color: #f77500;
  // }
  // .label-d h2 {
  //   color: #f81313;
  // }
  // .label-f h2 {
  //   color: #ab211e;
  // }

  &-border {
    background: #e6e8eb;
    position: absolute;
    left: 3px;
    width: 2px;
    height: 100%;
    top: 3px;
  }

  &-list {
    list-style-type: disc;
    list-style-position: outside;
    padding-top: 10px;
    margin: 0 0 15px 30px;
    font-size: 13px;
    letter-spacing: 0.04px;
    color: #b1b5b9;

    li {
      line-height: 1.46;

      .line-through {
        text-decoration: line-through;
      }
    }
  }

  &.active .level-description-list {
    color: #637280;
  }

  .terms-wrap {
    display: flex;
    margin-bottom: 22px;
  }

  .terms {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.04px;
    color: #0084ff;
    border-bottom: 1px dashed #0084ff;
    height: 14px;
    cursor: pointer;

    &:hover {
      border-bottom: none;
    }
  }

  .tooltip-content {
    font-size: 12px;
    line-height: 1.25;
    letter-spacing: 0.04px;
    text-align: center;
    color: #d7dadb;

    b {
      font-weight: 600;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: #637280 transparent transparent transparent;
      bottom: -5px;
      z-index: 1;
      left: 50%;
      margin-left: -10px;
    }
    b {
      color: #fff;
    }
  }

  .tooltip-trigger {
    position: relative;
    cursor: pointer;

    &:hover {
      & + .tooltip-content {
        width: auto;
        height: auto;
        opacity: 1;
        padding: 10px 15px;
        font-size: 12px;
        min-height: 36px;
        top: -107px;
        overflow: visible;
      }
    }
  }

  .icon-question {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEzIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLjc3NikiPgogICAgICAgIDxjaXJjbGUgY3g9IjUuODMxIiBjeT0iNS44MzEiIHI9IjUuMzMxIiBzdHJva2U9IiMwMDg0RkYiLz4KICAgICAgICA8cGF0aCBmaWxsPSIjMDA4NEZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik00LjA1NCAzLjMxMmMuMTA1LS4wOTMuMjE5LS4xOC4zNDMtLjI2M2EyLjQ2NSAyLjQ2NSAwIDAgMSAuODc0LS4zNjIgMi42OCAyLjY4IDAgMCAxIC41NDgtLjA1M2MuMjY4IDAgLjUxMS4wMzcuNzMuMTEuMjIuMDc0LjQwOS4xOC41NjUuMzE2LjE1Ny4xMzcuMjc4LjMwMi4zNjQuNDk2LjA4Ni4xOTMuMTMuNDEuMTMuNjUgMCAuMjMzLS4wMzQuNDM1LS4xMDIuNjA2LS4wNjguMTctLjE1Mi4zMTktLjI1My40NDVhMS45MiAxLjkyIDAgMCAxLS4zMzUuMzI3bC0uMzQzLjI2Yy0uMTA3LjA4Mi0uMi4xNjEtLjI3OS4yNGEuNDczLjQ3MyAwIDAgMC0uMTQuMjY1bC0uMTA2LjY3M2gtLjc4bC0uMDc4LS43NTJhLjYxLjYxIDAgMCAxIC4wNjMtLjM4Yy4wNi0uMTA5LjE0LS4yMDkuMjQtLjMuMDY2LS4wNi4xMzgtLjExOS4yMTQtLjE3N2wuMTItLjA4NWMuMTIyLS4wODUuMjM4LS4xNzcuMzQ1LS4yNzcuMTA4LS4xLjE5OC0uMjEyLjI3LS4zMzZhLjg3MS44NzEgMCAwIDAgLjEwOC0uNDQ1LjY3Mi42NzIgMCAwIDAtLjIzNS0uNTIzLjc3NS43NzUgMCAwIDAtLjI2NS0uMTQ1IDEuMDY1IDEuMDY1IDAgMCAwLS4zMzQtLjA1IDEuNDg5IDEuNDg5IDAgMCAwLS43NTQuMTg2bC0uMTUxLjA4OWEyLjY1MiAyLjY1MiAwIDAgMC0uMDYzLjA0LjI5NS4yOTUgMCAwIDEtLjE1Ny4wNTguMjUxLjI1MSAwIDAgMS0uMjQtLjEzOWwtLjI5OS0uNDc0em0uODcxIDUuNDMzYS43MDkuNzA5IDAgMCAxIC4xOTgtLjQ5My42OTcuNjk3IDAgMCAxIC40OTgtLjIwM2MuMDk4IDAgLjE5LjAxOS4yNzQuMDU2YS42NzguNjc4IDAgMCAxIC4zNzEuMzY5LjY4My42ODMgMCAwIDEgLjA1Ni4yNzEuNjc4LjY3OCAwIDAgMS0uMjA2LjQ5My43LjcgMCAwIDEtLjQ5NS4xOTkuNzIuNzIgMCAwIDEtLjI3Ni0uMDUzLjY2Ny42NjcgMCAwIDEtLjQyLS42Mzl6Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=");
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    width: 12px;
    height: 12px;
    margin-right: 3px;
    background-size: contain;
  }

  .description-wrap {
    font-size: 13px;
    line-height: 1.4;
    letter-spacing: 0.04px;
    position: relative;
    color: #d74662;

    .title-description {
      font-weight: 900;
    }

    &:before {
      background-size: contain;
      background-repeat: no-repeat;
      content: "";
      display: block;
      position: absolute;
      height: 24px;
      width: 24px;
      top: -1px;
      left: -41px;
    }
  }

  &.label-pro .description-wrap {
    color: #2197a7;
    &:before {
      background-image: url("./img/icons/flash-pro.svg");
    }
  }

  &.label-standard .description-wrap {
    color: #9a6b32;
    &:before {
      background-image: url("./img/icons/flash-standard.svg");
    }
  }

  &.label-outsider .description-wrap {
    color: #d74662;
    &:before {
      background-image: url("./img/icons/flash-outsider.svg");
    }
  }

  &-bage {
    margin-top: 12px;
    border-radius: 4px;
    background-color: #d74662;
    padding: 3px 8px;
    font-size: 13px;
    font-weight: 900;
    letter-spacing: 0.04px;
    color: #ffffff;
    display: inline-block;
  }

  &.label-pro .level-description-bage {
    background-color: #2197a7;
  }

  &.label-standard .level-description-bage {
    background-color: #9a6b32;
  }

  &.label-outsider .level-description-bage {
    background-color: #d74662;
  }

  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    h2 {
      margin-right: 10px;
    }
    span {
      font-size: 18px;
      font-weight: 900;
      letter-spacing: 0.05px;
      color: #29a678;
      margin-right: 10px;
    }
  }
}

.right-header {
  display: flex;
}

.personal-info .personal-box {
  .header-last-warning {
    display: flex;
    align-items: center;
    position: relative;

    button {
      border: none;
      padding: 0;
      background: none;
    }

    .dropdown {
      .btn {
        border: none;
        padding: 0;
        cursor: auto;

        &:hover {
          background: none;
        }
      }
    }

    .dropdown-content {
      box-shadow: 0px 2px 4px 0px #00000040;
      // border: solid 1px #c0c7ce;
      background-color: #ffffff;
      width: 360px;
      padding: 0;
      position: absolute;
      top: 25px;
      right: -8px;
      z-index: 1;
      border-radius: 4px;

      // &:after,
      // &:before {
      //   content: "";
      //   display: block;
      //   position: absolute;
      //   right: 9%;
      //   margin-right: -6px;
      //   height: 0;
      //   width: 0;
      //   border: 6px solid transparent;
      //   border-bottom-color: #c0c7ce;
      //   bottom: 100%;
      // }

      // &:after {
      //   border: 5px solid transparent;
      //   border-bottom-color: #fff;
      //   margin-right: -5px;
      // }
    }

    .dropdown-content-header {
      padding: 20px 20px 12px 20px;
      border-bottom: 1px solid #ECEFF1;
      font-size: 20px;
      @include fontFamilyBolder;
    }

    .dropdown-content-footer {
      padding: 12px 0px 20px 0px;
      gap: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__text {
        font-size: $fontSizeXSm;
      }
    }

    .last-warning {
      white-space: normal;
      max-height: 430px;
      overflow: auto;

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    
      &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
      }
    
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #ECEFF1;
        height: 20px;
      }
    
      &::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 10px;
      }

      li {
        padding: 12px 20px;
        font-size: 14px;
        line-height: 1.29;
        display: flex;
        align-items: flex-start;
        transition: background .3s;
        cursor: pointer;
        gap: 0 12px;

        a:not(.warning-danger__error-text) {
          color: #3b464e;
          // max-width: 210px;

          &:hover {
            text-decoration: none;
          }
        }

        &:hover {
          background: #E1EFFD!important;
        }

        b {
          font-weight: 900;
        }

        &.new_notice {
          background: #F3F8FD;
        }

        &.warning-success {
          .svg-icon {
            fill: #27b659;
          }

          b {
            color: #27b659;
            font-size: 13px;
          }
        }


        &.warning-default {
          b {
            font-size: 13px;
          }
        }

        .icon-wrap {
          display: block;
          height: 20px;
          width: 20px;
        }

        .svg-icon {
          width: 20px;
          height: 20px;
        }

        &.warning-danger {
          .svg-icon {
            fill: #fc694f;
          }
          b {
            color: #fc694f;
            font-size: 13px;
          }
          a.warning-danger__error-text {
            color: inherit;
            text-decoration: none;
          }
        }
        .notice_date {
          color: #637280;
          font-size: 12px;
        }
        .order_link_notice {
          color: #39A6F1!important;
          text-decoration: underline!important;
        }
      }
    }

    .cnt-warnings {
      position: relative;
      display: block;

      .cnt {
        position: absolute;
        font-size: 11px;
        font-weight: 900;
        letter-spacing: 0.15px;
        text-align: center;
        color: #ffffff;
        border-radius: 5px;
        border: solid 1.5px #ffffff;
        background-color: #ff3c3c;
        top: -7px;
        left: 10px;
        line-height: 13px;
        padding: 0 3px;
        cursor: pointer;
      }
    }

    .icon-bell {
      display: block;
      background-image: url("./img/icons/bell.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 18px;
      height: 21px;
      cursor: auto;
    }

    &.active {
      .icon-bell {
        cursor: pointer;
        background-image: url("./img/icons/bell-active.svg");
        &.bell-call-anim {
          animation: bellcall 1000ms ease-in-out;
        }
      }
    }
  }

  @keyframes bellcall {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(25deg);
    }
    30% {
      transform: rotate(-25deg);
    }
    45% {
      transform: rotate(15deg);
    }
    58% {
      transform: rotate(-10deg);
    }
    70% {
      transform: rotate(5deg);
    }
    87.5% {
      transform: rotate(-2deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}
