@import 'scss/_variables.scss';

.close-terms-of-use-icon {
  position: absolute;
  right: 32px;
  top: 42px;
}

.terms-of-use-container {
  &__header {
    font-size: $fontSizeXL;

    @include fontFamilyMedium;

    margin-bottom: 24px;
  }

  &__page-count {
    font-size: $fontSizeMd;

    @include fontFamilyMedium;

    color: $colorText;

    margin-bottom: 4px;
  }

  .terms-of-use-container-slide {
    min-height: 468px;

    .mb12 {
      margin-bottom: 12px;
    }

    .mb20 {
      margin-bottom: 20px;
    }

    .mt12 {
      margin-top: 12px;
    }

    .column_gap_24 {
      gap: 0 24px;
    }

    &__header {
      font-size: $fontSizeTitle;
      @include fontFamilyMedium;

      margin-bottom: 20px;

      line-height: 32px;

      &--no-margin {
        margin-bottom: 0;
      }
    }

    &__title {
      font-size: $fontSizeXL;

      color: $colorText;
      
      margin-top: 4px;

      margin-bottom: 24px;

      line-height: 28px;
    }

    &__main-text {
      font-size: $fontSizeL;

      line-height: 24px;
    }

    &__min-460 {
      min-width: 460px;
    }

    &__list {
      padding-left: 8px;
      li {
          display: flex;

          &:not(:last-child) {
            margin-bottom: 4px;

            &.dbl-margin {
              margin-bottom: 20px;

              font-size: $fontSizeTitleSm;
              line-height: 28px;
            }

            &.mid-margin {
              margin-bottom: 12px;
            }

            &.sm-margin {
              margin-bottom: 8px;
            }
          }

          &:before {
              content: "\2022";
              color: $colorText;
              display: block;
              width: 8px;
              height: 8px;
              margin-right: 12px;
            }
      }
  }
  }
}
