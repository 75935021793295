@import 'scss/_variables.scss';

.preview_files_main_container {
  position: relative;
  height: 100vh;

  @include breakpointMax(medium) {
    width: 100vw;
    height: 100vh;
    margin-left: -16px;
    background-color: #5c6b75;
  }

  &__mobile-body {
    padding: 13px;
    display: flex;
    height: calc(100vh - 194px);
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: scroll;

    img {
      max-width: 336px;
      max-height: 474px;
    }
  }

}

.preview_container {
  height: 100%;
  margin-left: 330px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-shadow: inset -1px 0 0 0 #919aa2;
  background-color: #667580;
  padding-top: 40px;
  position: relative;

  img {
    max-width: 1000px;
    max-height: 700px;
  }

  .grab_btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: #13873c;
    height: 40px;
    bottom: 90px;
    z-index: 2;
    cursor: pointer;
    transition: background-color .3s;

    &:hover {
      background-color: #1b9245;
    }

    .grab_btn_icon {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      margin-top: 3px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23FFF'%3E%3Cg%3E%3Cg%3E%3Cpath d='M20.21 16.421c2.094 0 3.79 1.696 3.79 3.79C24 22.304 22.304 24 20.21 24c-2.093 0-3.789-1.696-3.789-3.79 0-2.093 1.696-3.789 3.79-3.789zm-18.947.632c.699 0 1.263.566 1.263 1.263v.631h.632c.698 0 1.263.566 1.263 1.264 0 .697-.565 1.263-1.263 1.263H1.263C.565 21.474 0 20.908 0 20.21v-1.895c0-.697.565-1.263 1.263-1.263zm11.223 1.894c.699 0 1.263.566 1.263 1.264 0 .697-.564 1.263-1.263 1.263H8.987c-.697 0-1.263-.566-1.263-1.263 0-.698.566-1.264 1.263-1.264h3.5zm-.068-13.335l.08.139 3.79 7.579c.312.624.059 1.383-.565 1.695-.58.29-1.275.092-1.621-.437l-.074-.128-.282-.565H8.99l-.282.565c-.29.579-.965.839-1.56.623l-.135-.058c-.58-.29-.839-.965-.623-1.56l.058-.135 3.79-7.58c.44-.881 1.656-.928 2.179-.138zM1.263 7.725c.699 0 1.263.566 1.263 1.263v3.498c0 .648-.487 1.182-1.115 1.255l-.148.008C.565 13.75 0 13.183 0 12.486V8.988c0-.697.565-1.263 1.263-1.263zm18.948 0c.698 0 1.263.566 1.263 1.263v3.498c0 .697-.565 1.263-1.263 1.263-.699 0-1.264-.566-1.264-1.263V8.988c0-.697.565-1.263 1.264-1.263zM11.368 9.14l-1.114 2.228h2.229L11.368 9.14zM3.158 0C3.856 0 4.42.566 4.42 1.263s-.565 1.263-1.263 1.263h-.632v.632c0 .697-.564 1.263-1.263 1.263C.565 4.421 0 3.855 0 3.158V1.263C0 .566.565 0 1.263 0h1.895zM20.21 0c.698 0 1.263.566 1.263 1.263v1.895c0 .697-.565 1.263-1.263 1.263-.699 0-1.264-.566-1.264-1.263v-.632h-.631c-.699 0-1.263-.566-1.263-1.263S17.617 0 18.316 0h1.895zm-7.725 0c.699 0 1.263.566 1.263 1.263s-.564 1.263-1.263 1.263H8.987c-.697 0-1.263-.566-1.263-1.263S8.29 0 8.987 0h3.5z' transform='translate(-731 -712) translate(715 704) translate(16 8)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }

    .grab_btn_text {
      font-size: 16px;
      font-weight: bold;
      color: #fff;
    }
  }
}


@import './stateContainer.scss';
@import './loader.scss';
