.inbox_update_modal {
  width: 400px;

  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 20px;

    span {
      margin-right: 5px;
    }
  }

  &__icon {
    font-size: 18px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
