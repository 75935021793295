@import 'scss/_variables.scss';

.common-textarea-wrapper {
  position: relative;

  .common-textarea {
    background: $colorWhite;
    border: 1px solid #C0C7CE;
    border-radius: $borderRadius;
    width: 100%;
    resize: vertical;
  
    padding: 5px 5px 5px 10px;
  
    color: #030303;
  
    font-size: $fontSizeMd;
  
    &--no-resize {
      resize: none;
    }

    &--both-resize {
      resize: both;
    }
  }

  .common-textarea-counter {
    font-size: $fontSizeMd;
    line-height: 14px;
    letter-spacing: 0.12px;
    @include fontFamilyMedium;
    color: #14B479;

    position: absolute;
    right: 10px;
    bottom: 10px;

    &--red {
      color: #FC694F;
    }
  }

}

