#iconMap {
  display: none;
}

.svg-icon {
  display: inline-block;
  z-index: 0;
  transition: fill $transitionTime;
  position: relative;

  .nav--sub .active & {
    fill: $colorText;
  }

  &.hide {
    fill: $colorWhite;
  }
}

.icon_cnt_warning {
  width: 8px;
  height: 14px;
  margin-right: 3px;
}

.star {
  width: 13px;
  height: 12px;
  fill: $color06;
  top: 1px;
  margin-right: 0;

  .active & {
    fill: $colorText;
  }

  @include breakpoint(medium) {
    margin-right: 5px;
  }
}

.clock {
  width: 11px;
  height: 11px;
  fill: $color06;
  top: 1px;
  margin-right: 0;

  .active & {
    fill: $colorText;
  }

  .active & {
    fill: $colorText;
  }

  &.error {
    fill: $colorBright2_08;
  }

  &.warning {
    fill: $colorBright2_10;
  }

  &.success {
    fill: $colorBright1_09;
  }

  @include breakpoint(medium) {
    margin-right: 5px;
  }
}

.email-notification {
  width: 11px;
  height: 8px;
  fill: $colorBright2_10;
}

.selecter {
  width: 9px;
  height: 5px;
  fill: $colorText;
  top: -1px;
  cursor: pointer;

  .active & {
    transform: rotate(180deg);
  }
}

.saved-star {
  width: 17px;
  height: 16px;
  fill: $colorWhite;
  cursor: pointer;
  stroke: $color00;
  transition: all $transitionTime;
  display: block;

  .btn:hover & {
    stroke: $colorBright2_09;
  }

  :disabled &:hover {
    fill: $colorWhite;
    stroke: $color00;
  }

  .active:hover & {
    fill: $colorWhite;
    stroke: $colorBright2_09;
  }

  .table-row:hover & {
    stroke: $color07;
  }

  .table-row:hover .btn:hover & {
    stroke: $colorBright2_09;
  }

  .btn.active & {
    fill: $colorBright2_09;
    stroke: none;
  }
}

.open-new-window {
  width: 12px;
  height: 12px;
  fill: $colorAction10;
  position: relative;
  top: 1px;
  margin-left: 10px;
  transition: opacity $transitionTime;
}

.search {
  width: 15px;
  height: 15px;
  fill: $colorExtraLight;
}

.close {
  width: 15px;
  height: 15px;
  fill: $colorText;
}

.arrow-up {
  fill: $colorAction09;
  width: 26px;
  height: 26px;

  &:hover {
    fill: $colorWhite;
  }
}

.arrow-right-brd {
  width: 6px;
  height: 10px;
  transform: translateX(0);
  transform: rotate(0);

  &.active {
    transform: translateX(50%);
    transform: rotate(90deg);
    transition: all 0.2s ease;
  }
}
.arrow-bottom {
  width: 10px;
  height: 6px;
  transform: translateX(-50%);
  transform: rotate(-90deg);
  transition: transform 1s ease;
  &.active {
    transform: rotate(0);
  }
}

.user {
  width: 15px;
  height: 17px;
  fill: $color06;
}

.working-status {
  width: 15px;
  height: 15px;
  fill: $colorAction08;

  &.inactive {
    fill: #9ca9b5;
  }
}

.active-notification {
  width: 30px;
  height: 30px;
  fill: $colorWhite;
}

.combined-shape {
  width: 14px;
  height: 11px;
  fill: $colorBrightOpacity2_10;
  stroke: $colorBright2_10;

  .ReactCollapse--content &,
  .table-header & {
    fill: transparent;
    stroke: transparent;
  }
}

.icon-block {
  width: 12px;
  height: 16px;
  margin-right: 5px;
}
.icon-detail {
  width: 12px;
  height: 16px;
  margin-right: 10px;
}

.client-info {
  width: 12.3px;
  height: 16px;
  fill: transparent;
  stroke: $color07;
  top: 2px;
}

.icon-dashboard {
  height: 16px;
  width: 18px;
}

.additional {
  width: 12px;
  height: 15px;
  fill: $color07;
  top: 2px;
}

.clip {
  width: 15px;
  height: 14px;
  fill: $color07;
  top: 2px;
}

.papers {
  width: 10px;
  height: 13px;
  fill: transparent;
  stroke: $color07;
  top: 1px;
}

.comments, .comments-with-strokes {
  width: 14px;
  height: 13px;
  fill: transparent;
  stroke: $color07;
  top: 2px;
}

.folder {
  width: 17px;
  height: 14px;
  fill: transparent;
  stroke: $color07;
  top: 2px;
}

.question {
  fill: $colorAction09;
  width: 14px;
  height: 14px;

  &--red {
    fill: $colorBright2_08;
  }
}

.money-income {
  width: 13px;
  height: 16px;
  fill: $colorBright2_09;
}

.system-update {
  width: 13px;
  height: 15px;
  fill: $colorAction08;
}

.check {
  width: 11px;
  height: 11px;
}

.check-empty {
  width: 12px;
  height: 9px;
  fill: $colorAction09;
}

.check-fill {
  width: 12px;
  height: 12px;

  &.error {
    fill: $colorBright2_08;
  }

  &.success {
    fill: $colorBright1_09;
  }

  &.warning {
    fill: $colorBright2_10;
  }
}

.smile {
  width: 21px;
  height: 21px;
  fill: transparent;
  stroke: $colorAction09;

  ellipse {
    fill: $colorAction09;
  }
}

.plagreport {
  width: 13px;
  height: 13px;
  background: $colorBright2_10;
  fill: $colorWhite;
  border-radius: $borderRadius;
}

.plagreport-success,
.plagreport-in-progress {
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  position: relative;
  top: 1px;
}

.plagreport-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEzIDEyIj4NCiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPg0KICAgICAgICA8cGF0aCBmaWxsPSIjMDBCMDZEIiBkPSJNMTAuOTE3IDEyaC04YTIgMiAwIDAgMS0yLTJWMmEyIDIgMCAwIDEgMi0yaDhhMiAyIDAgMCAxIDIgMnY4YTIgMiAwIDAgMS0yIDIiLz4NCiAgICAgICAgPHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNNC4zMiA1LjI3bC0uOTkuOTkgMi44NjYgMi44NjUgNC45NzMtNC45NzMtLjk5LS45OS0zLjk4MyAzLjk4M3oiLz4NCiAgICA8L2c+DQo8L3N2Zz4NCg==");
}
.plagreport-in-progress {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEzIDEyIj4NCiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPg0KICAgICAgICA8cGF0aCBmaWxsPSIjOUNBOUI1IiBkPSJNMTAuMzQzIDEyaC04YTIgMiAwIDAgMS0yLTJWMmEyIDIgMCAwIDEgMi0yaDhhMiAyIDAgMCAxIDIgMnY4YTIgMiAwIDAgMS0yIDIiLz4NCiAgICAgICAgPHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNNi44MDggMi42ODNoLTEuNHY0LjI5TDcuOTM1IDkuNWwuOTktLjk5LTIuMTE3LTIuMTE3eiIvPg0KICAgIDwvZz4NCjwvc3ZnPg0K");
}

.delete-file {
  width: 16px;
  height: 16px;
  fill: $color04;
  border-radius: 50%;

  path,
  circle {
    transition: fill $transitionTime;
  }

  &:hover {
    circle {
      fill: $colorBright2_10;
    }

    path {
      fill: $colorWhite;
    }
  }
}

.finger {
  width: 19px;
  height: 17px;
  fill: transparent;
  stroke: $color06;
}

.error {
  width: 16px;
  height: 12px;
  fill: $colorBright2_05;
}

.watch {
  width: 9px;
  height: 13px;
  fill: $color07;
}

.skype {
  width: 13px;
  height: 13px;
  fill: $color06;
}

.devider {
  width: 11px;
  height: 18px;
  fill: $color12;
}

.settings {
  width: 13px;
  height: 13px;
  fill: $color07;
}

.logout {
  width: 12px;
  height: 13px;
  fill: $color07;
}

.hot-recommended {
  width: 11px;
  height: 14px;
}

.icon-exclamation {
  width: 14px;
  height: 14px;
}

.icon-table-blue-info {
  width: 8px;
  height: 8px;
}
