
.help-container {
    color: $colorText;

    .title {
        @include fontFamilyBold;
        margin-bottom: 20px;

        &.title-search {
            span {
                @include fontFamilyRegular;
                display: inline-block;
                margin-right: 5px;
            }
        }
    }

    .markable-text {
      p {
          font-size: $fontSizeSm;
          line-height: 1.54;
          letter-spacing: 0.1px;
          margin-bottom: 20px;
      }
    }

    li,
    p {
        font-size: $fontSize;
        line-height: 24px;
        letter-spacing: 0.1px;
        max-width: 860px;
    }
    .accordion-item {
      p {
        margin-bottom: 20px;
      }
    }
    .guide-list {
        .btn {
            font-size: $fontSizeXs;
            text-transform: uppercase;
            position: absolute;
            right: 15px;
            top: 50%;
            margin-top: -12px;
            line-height: 19px;

            &:disabled {
                font-size: $fontSizeXs;
                text-transform: uppercase;
            }
        }

        .guide-item {
            display: block;
            padding: 15px 15px 15px 45px;
            box-shadow: inset 0 -1px 0 0 $color05;
            position: relative;

            &:hover {
                text-decoration: none;
                background-color: $colorAction03;

                .btn {
                    background-color: $colorAction10;
                    color: $colorWhite ;
                }
            }
        }

    }

    .help-faq-text {
        font-size: $fontSizeMd;
        .indent {
            padding-left: 30px;
            line-height: 22px;
        }
        .double-indent {
            padding-left: 75px;
            line-height: 22px;
        }
    }

    .faq-icon {
        display: inline-block;
        width: 32px;
        height: 21px;
        background-repeat: no-repeat;
        background-position: left center;
    }
    .faq-account {
        background-image: url('./img/icons/faq-account.svg');
    }
    .faq-orders {
        background-image: url('./img/icons/faq-orders.svg');
    }
    .faq-contact {
        background-image: url('./img/icons/faq-contact.svg');
    }
}

.left-sidebar {

    .search-wrap {
        position: relative;
        margin-bottom: 15px;

        .search {
            position: absolute;
            left: 10px;
            top: 7px;
        }

        .btn-close {
            position: absolute;
            right: 0;
            top: 1px;
            background: transparent;
        }

        .input-text {
            padding-left: 30px;
            width: 100%;
        }
    }

}

.right-content {
    .title {
        margin-bottom: 10px;
    }
}
