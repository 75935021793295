.btn {
  @include border-btn;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  font-size: $fontSizeSm;
  padding: 7px 10px;
  color: $colorText;
  height: $blockHeight;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  &.btn-empty {
    border: none;
    padding: 0;
    background-color: transparent;
    height: auto;
  }

  &.btn-block {
    display: block !important;
    width: 100% !important;
    position: relative;
  }

  &.btn-show-next {
    display: block;
    width: 100%;
    border: none;
    border-radius: $borderRadius;
    height: $blockHeight;
    background-color: $colorAction04;
    color: $colorAction10;
    transition: background-color $transitionTime;
    @include fontFamilyMedium;

    &:hover {
      background-color: $colorAction06;
    }
  }

  &.btn-default {
    border: solid 1px #2a86e1;
    background-color: #ebf7ff;
    color: #2a86e1;
    text-decoration: none;
  }

  &.btn-default.transparent {
    border: solid 1px #2a86e1;
    background-color: transparent;
    color: #2a86e1;
    text-decoration: none;
  }

  &.btn-show-all {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    height: $blockHeight;
    color: $colorAction10;
    transition: background-color $transitionTime;
    font-size: $fontSizeXSm;

    .arrow-down-xs {
      margin-left: 5px;
    }
  }

  &.btn-filter-search {
    padding: 6px 7px 4px;
  }

  &.btn-close {
    border: none;
    opacity: 0.6;
    transition: opacity $transitionTime;
  }

  &.btn-close:hover {
    opacity: 1;
  }

  &.btn-light {
    border: 1px solid $colorAction10;
    background-color: $colorWhite;
    color: $colorAction10;
    transition: background-color $transitionTime;

    &:hover {
      background-color: $colorAction03;
    }

    &.custom-hover:hover {
      background-color: $colorAction10;
      color: $colorWhite;
    }

    &:disabled:hover,
    &:disabled,
    &.disabled:hover,
    &.disabled {
      border: 1px solid $color05;
      background-color: $color03;
      color: $color06;
      cursor: not-allowed;
    }
  }

  .order-file-list &.btn-download-all {
    float: right;
    margin: 5px 0 -10px;
  }

  .chat-box .chat-message &.btn-download-all {
    float: right;
    margin: 5px 15px;
    padding: 5px 15px;
    border-radius: 3px;
  }

  &:disabled:hover,
  &:disabled,
  &.disabled:hover,
  &.disabled {
    color: $colorAction08;
    width: auto;
    font-size: $fontSizeSm;
    text-decoration: none;
    cursor: not-allowed;
  }

  &.btn-bright-success {
    border: 1px solid $colorBright_1_07;
    background-color: $colorBright_1_07;
    color: $colorWhite;
    transition: border-color, background-color $transitionTime;
    vertical-align: top;

    &:hover {
      border-color: $colorBrightOpacity80_1_07;
      background-color: $colorBrightOpacity80_1_07;
      text-decoration: none;
    }

    &:disabled,
    &:disabled:hover,
    &.disabled,
    &.disabled:hover {
      border: 1px solid $color05;
      background-color: $color03;
      color: $color06;
      cursor: not-allowed;
    }
  }

  &.btn-grey {
    border: 1px solid #EDEBEB;
    background-color: #EDEBEB;
    color: $colorDark;
    transition: border-color, background-color $transitionTime;
    vertical-align: top;

    &:hover {
      border-color: #F2F3F5;
      background-color: #F2F3F5;
      text-decoration: none;
    }

    &:disabled,
    &:disabled:hover,
    &.disabled,
    &.disabled:hover {
      border: 1px solid #F7F9FA;
      background-color: #F7F9FA;
      color: $colorDark;
      cursor: not-allowed;
    }
  }

  &.btn-bright {
    border: 1px solid $colorAction10;
    background-color: $colorAction10;
    color: $colorWhite;
    transition: border-color, background-color $transitionTime;

    &:hover {
      border-color: $colorAction09;
      background-color: $colorAction09;
      text-decoration: none;
    }

    &:disabled,
    &:disabled:hover,
    &.disabled,
    &.disabled:hover {
      border: 1px solid $color05;
      background-color: $color03;
      color: $color06;
      cursor: not-allowed;
    }

    &.disabled-only-opacity {
      opacity: 0.5;
      cursor: not-allowed;
    }

    @media (hover: none) {
      background-color: $colorAction10!important;
      border: 1px solid $colorAction10!important;
    }

  }

  &.btn-transparent {
    background-color: transparent;
    transition: background-color $transitionTime;

    &:hover {
      background-color: #ebf7ff;
    }
  }

  &.btn-error {
    border: 1px solid $colorBright2_10;
    background-color: $colorWhite;
    color: $colorBright2_10;
    transition: background-color $transitionTime;

    &:hover {
      background-color: $colorBrightOpacity2_10;
    }

    &:disabled {
      border: 1px solid #e6e8eb;
      background-color: #f7f9fa;
      color: #c0c7ce;
      cursor: not-allowed;
    }
  }

  &.btn-error-inverse {
    border: none;
    background-color: $colorBright2_10;
    color: $colorWhite;
    transition: background-color $transitionTime;

    &:hover {
      background-color: $colorBrightOpacity2_10_06;
    }

    @media (hover: none) {
      background-color: $colorBright2_10!important;
    }

  }

  &.btn-upto {
    padding: 0;
    height: 28px;
    width: 28px;
    background-color: $colorAction04;
    border: 1px solid $colorAction09;
    border-radius: $borderRadius;

    &:hover {
      background-color: $colorAction09;

      .arrow-up {
        fill: $colorWhite;
      }
    }
  }

  &.btn-profile {
    width: 30px;
    padding: 5px 0 0 1px;
    transition: border-color 0.5s;

    &:hover {
      border-color: $colorAction09;

      .user {
        fill: $colorAction09;
      }
    }
  }

  &.btn-notification {
    width: 30px;
    height: $blockHeight;
    background-color: $colorBright2_10;
    transition: background-color $transitionTime;

    &:hover {
      background-color: $colorBright2_07;
    }
  }

  &.btn-complited {
    cursor: auto;
    background-color: $color05;
    color: $colorText;

    &--pointer {
      cursor: pointer;
    }
  }

  &.btn-accepted {
    cursor: auto;
    background-color: $colorBrightOpacity1_09;
    color: $colorBright1_09;
    border-color: $colorBrightOpacity1_09;
    font-size: 15px;
    padding: 5px 10px 7px;
  }

  &.btn-ok {
    border: 1px solid $colorBrightOpacity1_09;
    background-color: $colorBrightOpacity1_09;
    color: $colorBright1_09;
    transition: background-color $transitionTime;

    &:hover {
      background-color: $colorBrightOpacity20_1_09;
    }
  }

  &.btn-success {
    border: 1px solid $colorBright1_10;
    background-color: $colorBrightOpacity1_09;
    color: $colorBright1_09;
    transition: background-color $transitionTime;

    &:hover {
      background-color: $colorBrightOpacity20_1_09;
    }
  }

  &.btn-back {
    color: $colorAction09;
    border-radius: 100px;
    border: none;
    padding: 0 20px;
    line-height: 28px;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: 0;
    transition: background-color $transitionTime;

    &:hover {
      background-color: $colorAction05;
    }
  }

  .btn-delete-file {
    width: 16px;
    height: 16px;
  }

  &.btn-xs,
  &.btn-xs:disabled,
  &.btn-xs.disabled {
    width: 60px;
  }

  &.btn-8-4,
  &.btn-8-4:disabled,
  &.btn-8-4.disabled {
    width: 80px;
    height: 40px;
  }

  &.btn-100-4,
  &.btn-100-4:disabled,
  &.btn-100-4.disabled {
    width: 100%;
    height: 40px;
  }

  &.btn-30square {
    width: 30px;
    height: 30px;
  }

  &.btn-70,
  &.btn-70:disabled,
  &.btn-70.disabled {
    width: 70px;
  }

  &.btn-sm,
  &.btn-sm:disabled,
  &.btn-sm.disabled {
    width: 100px;
  }

  &.btn-md,
  &.btn-md:disabled,
  &.btn-md.disabled {
    width: 110px;
  }

  &.btn-md2,
  &.btn-md2:disabled,
  &.btn-md2.disabled {
    width: 120px;
  }

  &.btn-xl,
  &.btn-xl:disabled,
  &.btn-xl.disabled {
    width: 150px;
  }

  &.btn-big,
  &.btn-big:disabled,
  &.btn-big.disabled {
    height: 40px;
    width: 160px;
    font-size: $fontSize;
  }

  &.btn-extra,
  &.btn-extra:disabled,
  &.btn-extra.disabled {
    height: 44px;
    width: 200px;
    font-size: $fontSizeL;
  }

  &.btn-small,
  &.btn-small:disabled,
  &.btn-small.disabled {
    height: 20px;
    font-size: $fontSizeSm;
    padding: 0 10px;
  }
}

.btn-toggle-group {
  border-radius: $borderRadius;

  .btn {
    border: none;
    color: $colorAction09;
    line-height: 17px;
    font-size: $fontSizeMd;
    @include fontFamilyMedium;
    border-top: 1px solid $color07;
    border-bottom: 1px solid $color07;
    border-left: 1px solid $color07;
    border-radius: 0;
    padding: 0 8px;

    @include breakpointMax(medium) {
      &--no-bottom-border {
        border-bottom: 0;
      }

      &--first {
        border-radius: $borderRadius 0 0 0!important;
      }

      &--second {
        border-radius: 0 $borderRadius 0 0;
        border-right: 1px solid $color07;
      }

      &--third {
        border-radius: 0 0 0 $borderRadius;
      }

      &--last {
        border-radius: 0 0 $borderRadius 0!important;
      }

      &--right {
        border-right: 1px solid $color07;
      }
    }

    @include breakpoint(medium) {
      padding: 0;
    }

    &:first-child {
      border-radius: $borderRadius 0 0 $borderRadius;
    }

    &:last-child {
      border-radius: 0 $borderRadius $borderRadius 0;
      border-right: 1px solid $color07;
    }

    &.active {
      background-color: $color07;
      color: $colorWhite;
      cursor: default;
    }

    span {
      position: relative;
      display: inline-block;

      &:before {
        content: "";
        display: block;
        width: 5px;
        height: 5px;
        background-color: transparent;
        border-radius: 50%;
        position: absolute;
        top: 1px;
        right: -10px;
        transition: background-color 0.5s;
      }

      &.standalone {
        &:before {
          right: -5px;
        }
      }

      &.new:before {
        background-color: $colorBright2_10;
      }
    }
  }
}

.btn-link {
  display: inline;
  padding: 0;
  border: none;
  border-bottom: 1px dashed #389bfe;
  color: #389bfe;
  font-size: 14px;
  height: auto;
}
