.modal-wrap {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $colorDarkOpacity;

  &.disabled {
    opacity: 0.8;
    pointer-events: none;
  }

  &.wrap-upper {
    z-index: 12;
  }

  @include breakpoint(medium) {
    padding: 0;
  }

  &.center-items {
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-container {
      margin-top: 0;
    }
  }

  $borderRadiusModal: 3px;
  .modal-container {
    position: relative;
    margin-top: 10vh;
    z-index: 11;
    border-radius: $borderRadiusModal;
    padding: 22px 20px 30px 20px;
    box-shadow: 0 2px 4px 0 $colorBlackOpacity25;
    background-color: $colorWhite;
    min-width: 400px;
    color: $color09;

    &.modal-wide {
      width: 550px;
    }

    &.modal-sm {
      width: 400px;
    }

    &.modal-lg {
      width: 1000px;
    }

    &.modal-mobile {
      width: 100%;
    }

    .modal-hi-icon {
      background: url("img/icons/hi-icon.png");
      width: 40px;
      height: 40px;
      display: block;
    }

    .modal-info-orange-icon {
      min-width: 24px;
      height: 24px;
      display: block;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 18.6667C15.6819 18.6667 18.6667 15.6819 18.6667 12C18.6667 8.3181 15.6819 5.33333 12 5.33333C8.3181 5.33333 5.33333 8.3181 5.33333 12C5.33333 15.6819 8.3181 18.6667 12 18.6667Z' fill='%23FFA65D'/%3E%3Cpath d='M12.6667 8C12.6667 8.36819 12.3682 8.66667 12 8.66667C11.6318 8.66667 11.3333 8.36819 11.3333 8C11.3333 7.63181 11.6318 7.33333 12 7.33333C12.3682 7.33333 12.6667 7.63181 12.6667 8Z' fill='%23FFA65D'/%3E%3Cpath d='M11.3333 10.6667C11.3333 10.2985 11.6318 10 12 10C12.3682 10 12.6667 10.2985 12.6667 10.6667V16C12.6667 16.3682 12.3682 16.6667 12 16.6667C11.6318 16.6667 11.3333 16.3682 11.3333 16V10.6667Z' fill='%23FFA65D'/%3E%3C/svg%3E%0A");
    }

    .modal-info-orange-text {
      font-size: $fontSizeL;
      color: #ffa65d;
    }

    .modal-info {
      font-size: $fontSize;
      margin-bottom: 20px;
      @include fontFamilyMedium;
    }

    .btn-group {
      text-align: right;

      .btn + .btn {
        margin-left: 10px;
      }
    }

    &--narrow {
      width: 550px;
      max-height: 480px;
      min-height: 480px;
      overflow-y: auto;
      transform: translateY(calc(100% - (480px / 1.5)));
      display: block;
      padding: 38px 20px 30px 28px;
      overflow: hidden;
      border-radius: 3px;

      &--400 {
        width: 400px;
      }

      &--center {
        transform: unset;
      }

      .agreement-box {
        max-height: 480px;
        min-height: 480px;
        padding: 0;
        display: block;
        overflow: hidden;
        box-sizing: border-box;
      }

      &--auto-height {
        min-height: auto;

        .agreement-box {
          min-height: auto;
        }
      }

      &--auto-max-height {
        max-height: none;
      }
    }

    .modal-textarea {
      width: 100%;
      min-height: 70px;
      padding: 5px 5px 5px 10px;
      color: #3b464e;
    }

    @include breakpointMax(medium) {
      min-width: 100%;
      width: 100% !important;
      margin-bottom: 1px;

      &--with-padding {
        width: calc(100% - 30px) !important;
        min-width: calc(100% - 30px) !important;
      }
    }
  }

  .modal-icon {
    margin: 15px 0 25px;
  }

  .btn-close {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .title {
    @include fontFamilyBold;

    &--green {
      color: $colorBright_1_07;
    }
  }

  .modal-item {
    margin-bottom: 10px;
  }
  .modal-text {
    letter-spacing: 0.1px;
    color: $colorText;
    line-height: 20px;
    margin-bottom: 30px;
  }

  .modal-note {
    color: $color07;
    font-size: $fontSizeSm;
  }

  .modal-btn {
    margin-bottom: 30px;
    text-align: center;
  }

  .preloading.preloading-box {
    border: none;
    position: absolute;
    min-height: 0;
    border-radius: $borderRadiusModal;
  }
}

.modal-no-scroll {
  overflow: hidden;
}

.modal-upload {
  width: 570px;
  margin-bottom: 20px;
  position: relative;

  h1 {
    font-size: 22px;
    font-weight: 900;
    letter-spacing: 0.2px;
    color: $color09;
    padding: 0 0 15px;
    border-bottom: solid 1px $color07;
  }

  a {
    color: $color07;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.1px;

    &:hover {
      color: $colorBright2_10;
      text-decoration: none;
    }
  }

  .col-file-name {
    width: 200px;
  }

  .file-name-progress {
    font-weight: normal;
  }

  .file-progress {
    position: relative;
    width: 200px;
    height: 10px;
    border: solid 1px $colorAction09;
    border-radius: $borderRadius;
    box-shadow: 0 2px 9px 0 rgba(99, 114, 128, 0.28);
  }

  .file-progress-percent {
    margin-left: 13px;
    font-size: 13px;
    letter-spacing: 0.1px;
    color: $color09;
    width: 50px;
  }

  $color: $colorAction09;
  $transparent: rgba(0, 0, 0, 0);
  $angle: -45deg;

  @keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0;
    }
    to {
      background-position: 0 0;
    }
  }

  .bg-progress {
    background-color: $colorAction06;
    background-size: 1rem 1rem;
    border-radius: $borderRadius;
    background-image: linear-gradient(
      $angle,
      $color 25%,
      transparent 25%,
      transparent 50%,
      $color 50%,
      $color 75%,
      transparent 75%,
      transparent
    );
    animation: progress-bar-stripes 1s linear infinite;
  }

  .modal-file-item {
    padding: 10px 0;
    border-bottom: solid 1px $color05;
  }

  .upload-button {
    margin-top: 20px;
  }

  .file-upload-info {
    width: 295px;
    display: flex;
  }
}

.brd-block {
  border-bottom: solid 1px $color05;
  padding: 0 0 25px;
}

.modal-request-to-pass {
  font-size: 16px;
  .qr-wrap {
    height: 150px;
    text-align: center;

    svg {
      height: 100%;
    }
  }

  .qr-link {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  &__code-wrap {
    background-color: #fffae4;
    padding: 20px 20px 30px;
    text-align: center;
    margin-bottom: 20px;

    &__title {
      font-size: 15px;
      font-weight: 700;
      line-height: 20px;
    }

    &__error {
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      color: #FF3C3C;
    }

    &__input {
      background-color: #f2f3f5;
      border: 1px solid #9ca9b6;
      border-radius: 4px;
      width: 216px;
      height: 48px;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 7px;
      text-align: center;
      margin-bottom: 30px;
    }
  }
}
