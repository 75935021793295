@import 'scss/_variables.scss';

.state_container {
  width: 610px;
  height: 100vh;
  background-color: #42525d;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpointMax(medium) {
    width: auto;
    height: 100%;
    margin: 0;
  }

  &.state_container_error {
    z-index: 1000;
  }

  .state_inner_container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpointMax(medium) {
      padding: 0 8px;
    }

    .unFormat_icon {
      width: 32px;
      height: 32px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cg fill='none' fill-rule='evenodd' opacity='.5'%3E%3Cg fill='%23FFF'%3E%3Cg%3E%3Cg%3E%3Cpath d='M16 4c6.627 0 12 5.373 12 12s-5.373 12-12 12S4 22.627 4 16 9.373 4 16 4zm0 2.667c-5.154 0-9.333 4.179-9.333 9.333s4.179 9.333 9.333 9.333 9.333-4.179 9.333-9.333S21.154 6.667 16 6.667zm-2.954 4.508l.126.11L16 14.115l2.828-2.828c.52-.52 1.365-.52 1.885 0 .48.48.518 1.237.111 1.76l-.11.126-2.829 2.827 2.828 2.83c.521.52.521 1.364 0 1.885-.48.48-1.237.518-1.76.11l-.125-.11L16 17.885l-2.828 2.83c-.52.52-1.365.52-1.886 0-.48-.481-.517-1.238-.11-1.76l.11-.126L14.114 16l-2.828-2.829c-.52-.52-.52-1.365 0-1.885.481-.481 1.237-.518 1.76-.111z' transform='translate(-824 -374) translate(676 374) translate(148)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }

    .state_container_text {
      font-size: 16px;
      letter-spacing: 0.13px;
      color: #fff;
      opacity: 0.5;
      margin-top: 20px;

      @include breakpointMax(medium) {
        text-align: center;
      }
      
    }

    .error_icon {
      width: 32px;
      height: 32px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cg fill='none' fill-rule='evenodd' opacity='.5'%3E%3Cg fill='%23FFF'%3E%3Cg%3E%3Cg%3E%3Cpath d='M19.908 7.427l.15.232 8.377 13.708c1.936 3.17-.255 7.22-3.907 7.39l-.24.006H7.533c-3.715 0-6.026-3.981-4.268-7.188l.12-.208L11.763 7.66c1.847-3.021 6.17-3.099 8.146-.232zM14.15 8.882l-.113.168-8.376 13.708c-.861 1.41.086 3.204 1.691 3.33l.18.008h16.756c1.65 0 2.688-1.745 1.96-3.18l-.089-.158L17.784 9.05c-.822-1.343-2.723-1.399-3.634-.169zm1.76 13.214c.737 0 1.333.597 1.333 1.333s-.596 1.334-1.333 1.334-1.333-.598-1.333-1.334c0-.736.596-1.333 1.333-1.333zm0-10.667c.684 0 1.247.515 1.324 1.178l.01.156v6.666c0 .737-.598 1.334-1.334 1.334-.684 0-1.247-.515-1.324-1.178l-.01-.156v-6.666c0-.737.598-1.334 1.334-1.334z' transform='translate(-824 -374) translate(695 374) translate(129)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }
}

.download-btn {
  line-height: 1.5;
  padding: 8px 20px;
  border-radius: 20px;
  background-color: #2383e5;
  color: #fff;
  cursor: pointer;
  transition: opacity .3s;
  user-select: none;
  @include fontFamilyBold;

  width: 110px;
  margin: 10px auto 0;

  &:hover {
    opacity: 0.7;
  }
}



