@import 'scss/_variables.scss';

.wire-transfer-form {
  width: 600px;

  .modal-container {
    margin-top: 3vh!important;
    padding: 20px!important;

    max-height: 800px;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #d8d8d8;
    }
  
  }

  &--loading {
    pointer-events: none;
  }

  .sections-title {
    display: flex;
    align-items: center;

    margin-bottom: 20px;

    &__icon {
      width: 14px;
      height: 16px;

      margin-right: 14px;
    }

    &__text {
      font-size: 16px;
      color: $colorDark;

      letter-spacing: 0.14px;

      @include fontFamilyBolder;
    }
  }

  .wire-transfer-form-loader-layout {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 990px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1;

    &__text {
      font-size: 14px; 
      @include fontFamilyBold;
      color: $color09;
      line-height: 1.71;
      letter-spacing: 0.11px;
    }
  }

  .wire-transfer-form-form-group-column {
    display: flex;
    gap: 0 20px;
  }

  .wire-transfer-form-form-group {
    margin-bottom: 20px;

    position: relative;

    &--column-item {
      width: calc(100% / 2);
    }

    &--column-item-3 {
      width: calc(100% / 3);
    }

    &__input-count {
      position: absolute;
      top: 30px;
      right: 8px;

      font-size: 12px;
      color: $color09;

      opacity: 0.5;
    }

    &__dropdown {
      max-width: 100%;

      .dropdown-content {
        min-width: 100%;
      }
    }

    &__label {
      font-size: 14px;
      @include fontFamilyBold;
      color: $colorText;
      letter-spacing: 0.12px;
      display: block;
      margin-bottom: 5px;
    }

    &__input {
      width: 100%!important;

      &--with-count {
        padding-right: 26px;
      }
    }
  }

  &__header {
    font-size: 20px;
    color: $colorText;
    letter-spacing: 0.18px;

    @include fontFamilyBold;

    margin-bottom: 25px;
  }
}
