@mixin menu-mixin {
  .menu-item {
    list-style: none;
    display: block;
    white-space: nowrap;
    font-size: $fontSize;
    color: $color08;
    padding: 0 10px;
  }

  &.menu-selectable {
    .menu-item {
      border-radius: $borderRadius;
      cursor: pointer;
      padding: 5px 10px;

      &:hover,
      &.active {
        background-color: $colorAction04;
        transition: background-color $transitionTime;
        color: $colorAction10;
        text-decoration: none;
      }

      a:hover {
        text-decoration: none;
      }
    }
  }

  &.menu-selectable-overflow {
    max-height: 300px;
    overflow-y: auto;
  }


}
