@import 'scss/_variables.scss';

.order-topic-form {

  &__submit-label {
    @include fontFamilyBold;
  }

  .order-topic-form-timer-container {
    
    &__icon {
      width: 16px;
      height: 16px;

      stroke: $colorText;
    }

    &__no-time-label {
      color: $colorBright2_08;

      @include fontFamilyBold;
    }
  }

  .order-topic-form-form {

    &--loading {
      opacity: 0.5;
      pointer-events: none;
    }
   
    .input-text {
      width: 100%;

      margin-bottom: 12px;
    
      @include breakpoint(medium) {
        width: calc(100% - 118px);

        margin-bottom: 0;
      }
    }
  }
}
