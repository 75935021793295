@import 'scss/_variables.scss';

.client-chat {
  position: fixed;
  top: 0;
  right: 600px;
  width: 350px;
  animation: rightElements600 0.1s ease-in-out;
  z-index: -1;
  background-color: #fff;

  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

  &--standalone {
    position: relative;
    top: unset;
    z-index: 1;
    background-color: transparent;
    right: unset;
    box-shadow: none;
    animation: none;
  }

  &__banner {
    background: #fff;
    padding: 10px 20px;
    font-size: $fontSizeXSm;
    border-bottom: 1px solid #e6e8eb;

    &--green {
      color: $colorBright1_10;
    }

    &--standalone {
      border-bottom: none;
      position: relative;

      &:after {
        content: '';
        width: calc(100% + 30px);
        left: 0;
        bottom: 0;
        height: 1px;
        background: #e6e8eb;
        position: absolute;
      }
    }
    
    a {
      color: $colorAction11;
    }
  }

  &__footer {
    background: #F3F8FD;
    height: 30px;
    position: fixed;
    right: 600px;
    width: 350px;
    bottom: 0;
    animation: rightElements 0.5s ease-in-out;
  }

  &__prev-msg-btn {
    position: absolute;
    top: -20px;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);

    background: transparent;
    color: #969AA3;
    border: none;
    cursor: pointer;

    transition: opacity 300ms;

    &:hover {
      opacity: 0.7;
    }
  }
}
