.checkbox {
    position: relative;
    cursor: pointer;
    font-size: $fontSizeSm;
    display: block;
    color: $color08;
    padding: 6px 0 7px 0;
    white-space: nowrap;
    transition: color $transitionTime;

    @include breakpointMax(medium) {
        font-size: 16px;
        display: flex;
        color:#39444D;
        padding: 12px 0 12px 0;
    }

    $iconSize: 15px;

    .checkbox-icon {
        display: inline-block;
        position: relative;
        width: $iconSize;
        height: $iconSize;
        border: 1px solid $color06;
        background-color: $colorWhite;
        border-radius: $borderRadius;
        vertical-align: top;
        transition: border $transitionTime;
        top: 1px;

        @include breakpointMax(medium) {
            width: 16px;
            height: 16px;
        }
        
    }

    &:hover {
        color: $colorText;
        
        .checkbox-icon {
            border: 1px solid $colorText;
        }

    }

    .checkbox-label {
        margin-left: 5px;
        display: inline-block;
        vertical-align: middle;

        @include breakpointMax(medium) {
            margin-left: 12px;
        }
    }

    [type="checkbox"] {
        position: absolute;
        left: -9999px;

        &:checked {
            &+.checkbox-icon {
                background-color: $colorAction10;
                border: 1px solid $colorAction10;

                &:after {
                    content: '';
                    width: $iconSize;
                    height: $iconSize;
                    background-image: url(./img/icons/selected-item.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 10px auto;
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    transition: all $transitionTimeFast;
                    opacity: 1;
                    transform: scale(1);
                }

                &.minus {
                  &:after {
                    content: '-';
                    background-image: none;
                    color: #fff;
                    text-align: center;
                    font-size: 13px;
                    font-weight: 900;
                    line-height: 14px;
                  }
                }
            }


            &:disabled ~ .checkbox-icon {
                background-color: $color11;
                border-color: $color11;
            }

            &:disabled ~ .checkbox-label {
                cursor: not-allowed;
                color: $color00;

                @include breakpointMax(medium) {
                    color: $colorTextLightGrey;
                    opacity: .5;
                    pointer-events: none;
                }
            }

            &:checked:disabled ~ .checkbox-icon {
                background-color: $color07;
                border-color: $color07;
            }

            &:checked:disabled ~ .checkbox-label {
                color: $colorAction10;

                @include breakpointMax(medium) {
                    color: $colorTextLightGrey;
                    opacity: .5;
                    pointer-events: none;
                }
            }

            ~ .checkbox-label {
                color: $colorAction10;
            }
        }

        &:not(:checked) + .checkbox-icon:after {
            opacity: 1;
            transform: scale(1);
        }
    }

    &.default {
        [type="checkbox"]:checked {
            ~ .checkbox-label {
                color: $colorText;
            }
        }
    }

    &.disabled {
        cursor: not-allowed;
        color: $color00;

        @include breakpointMax(medium) {
            color: $colorTextLightGrey;
            opacity: .5;
            pointer-events: none;
        }

        ~ .checkbox-label {
            color: $color00;

            @include breakpointMax(medium) {
                color: $colorTextLightGrey;
                opacity: .5;
                pointer-events: none;
            }
        }

        .checkbox-icon {
            background-color: $color01;
            border: 1px solid $color00;
        }
    }
}
