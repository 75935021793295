.tooltip-container {
  display: inline-block;
  position: relative;

  .tooltip-content {
    background-color: $color09;
    border-radius: $borderRadius;
    color: $colorWhite;
    position: absolute;
    bottom: calc(100% + 3px);
    line-height: 1.23;
    letter-spacing: 0.1px;
    font-weight: 500;
    z-index: 5;
    opacity: 0;
    height: 0;
    width: 0;
    padding: 0;
    font-size: 0;
    right: 0;

    &.position-down {
      top: calc(100% + 10px);
      bottom: auto;

      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 50%;
        margin-right: -5px;
        height: 0;
        width: 0;
        border: 5px solid transparent;
        border-bottom-color: #3b464e;
        bottom: 100%;
      }
    }

    &.no-arrow {
      &:after {
        display: none!important;
      }
    }

    &.white-with-grey-text {
      box-shadow: 0 2px 9px 0 rgba(99, 114, 128, 0.28);
      background-color: $colorWhite;
      color: $colorText;
    }

    &.light_tooltip {
      background: #FFFFFF;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      color: #637280;

       &:after {
        display: none;
       }
    }

    h3 {
      color: #fff;
      font-size: 16px;
      font-weight: 900;
      letter-spacing: 0.04px;
      margin-bottom: 8px;
    }

    &.corner_tooltip {
      left: calc(100% + 13px);
      top: 50%;
      border-radius: 2px;
      border: 1px solid #e6e8eb;
      background: #fff;
      color: #637280;

      &:after,
      &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-right: 10px solid #e6e8eb;
        border-bottom: 10px solid transparent;
        position: absolute;
        left: -11px;
        top: 50%;
        margin-top: -11px;
      }

      &:after {
        border-right: 10px solid #fff;
        top: 47px;
        left: -10px;
      }
    }
  }

  .tooltip-trigger {
    position: relative;
    display: inline-block;
    background-position: 0 0;
    background-repeat: no-repeat;
    cursor: pointer;

    &:hover {
      & + .tooltip-content {
        white-space: nowrap;
        width: auto;
        height: auto;
        opacity: 1;
        padding: 10px 15px;
        font-size: $fontSizeSm;
        min-height: 36px;

        &.corner_tooltip {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
          width: 310px;
          white-space: normal;
          height: 97px;
          line-height: 24px;
        }

        &.multi-string {
          white-space: inherit;
        }

        &.w8_form {
          white-space: inherit;
          width: 200px;
        }

        &.w-270 {
          width: 270px;
        }
    
        &.right-position {
          right: auto;
          left: 100%;
          text-align: left;
        }
    
        &.left-position {
          right: 100%;
          left: auto;
        }
    
        &.with-wrap-content {
          white-space: normal;
        }

        &.with-list {
          li {
            line-height: 22px;
            color: #fff;

            background-color: transparent;
            border: none;
            padding: 0;
            font-weight: normal;
            margin-bottom: 5px;

            display: flex;
            align-items: center;
        
            &:before {
              content: "";
              background-image: url("data:image/svg+xml,%3Csvg width='4' height='4' viewBox='0 0 4 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='2' cy='2' r='2' fill='white'/%3E%3C/svg%3E%0A");
              min-width: 5px;
              height: 5px;
              display: block;
              background-repeat: no-repeat;
              margin-right: 6px;
              background-size: contain;
            }
          }
        }
      }
    }
  }

  .sctict-tooltip-content {
    background-color: #637280;
    border-radius: 3px;
    color: #fff;
    position: absolute;
    bottom: calc(100% + 3px);
    line-height: 1.23;
    letter-spacing: 0.1px;
    font-weight: 500;
    z-index: 1;
    white-space: nowrap;
    width: auto;
    height: auto;
    padding: 20px;
    font-size: 14px;
    min-height: 36px;

    &.position-left {
      left: calc(100% + 10px);

      &:after {
        content: "";
        display: block;
        position: absolute;
        left: -14px;
        height: 0;
        width: 0;
        border: 7px solid transparent;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    &.strict-tooltip-green {
      background-color: #00aa6c;
      &.position-left {
        &:after {
          border-right-color: #00aa6c;
        }
      }
    }
    &.strict-tooltip-yellow {
      background-color: #fcb200;
      &.position-left {
        &:after {
          border-right-color: #fcb200;
        }
      }
    }
  }

  .ctrict-tooltip-close {
    position: absolute;
    display: block;
    background-image: url("./img/icons/close.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 14px;
    width: 14px;
    top: 10px;
    right: 10px;
    cursor: pointer;

    &:hover {
      background-image: url("./img/icons/close-hover.svg");
    }
  }
}

.tooltip-template {
  &.tooltip-high-trigger {
    padding-right: 25px;
    position: relative;
    top: 2px;
    left: 2px;

    &:hover {
      & > .tooltip-content {
        display: block;
        left: 25px;
        right: auto;
        white-space: normal;
        width: 300px;
        bottom: 5px;
        opacity: 1;
        padding: 10px 15px;
        font-size: 14px;
        height: auto;
        min-height: 66px;
      }
    }
  }
}
