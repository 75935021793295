.loader-files-preview {
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #d6e9ff;
  border-top-color: #3099ff;
  animation: loader 1s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
