.client-score-wrap {
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0.04px;
    color: #fe7800;
    margin-left: 5px;
  }
}

.client-score-desc {
  opacity: 0.5;
  font-family: Lato;
  font-size: 10px;
  letter-spacing: 0.07px;
  color: #3b464e;
}

.scores-content {
  h3 {
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: 600;
  }

  h4 {
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0.04px;
    color: #4e4e4e;
    margin-bottom: 20px;
  }

  .desc-levels {
    display: flex;
    margin-bottom: 28px;

    li {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      font-weight: bold;
      line-height: 2;
      letter-spacing: 0.8px;
      color: #030303;
      text-transform: uppercase;

      i {
        display: flex;
        padding: 0 8px;
        color: #fff;
        text-transform: none;
        font-weight: 700;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0.05px;
        border-radius: 4px;
        height: 26px;
        align-items: center;
        border: 1px solid transparent;
      }

      span {
        padding-left: 3px;
      }

      & + li {
        margin-left: 25px;
      }

      &.level-a-plus i {
        background: 
        linear-gradient(188.8deg, #DBBA42 6.7%, #875017 148.43%) padding-box,
        linear-gradient(to right, #D4B23F, #885217) border-box;
      }

      &.level-a i {
        background-color: #D5B23F;
      }
      &.level-b i {
        background-color: #eab32f;
      }
      &.level-c i {
        background-color: #FC5C04;
      }
      &.level-d i {
        background-color: #EE0404;
      }
      &.level-f i {
        background-color: #AC1C2C;
      }
    }
  }
}
