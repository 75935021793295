.accordion {
    font-size: $fontSizeL;

    .accordion-item {
        box-shadow: 0 -1px 0 0 $color05;

        &:last-child {
            box-shadow:
                inset 0 -1px 0 0 $color05,
                inset 0 1px 0 0 $color05;
        }

        .accordion-content {
            font-size: $fontSizeMd;
            line-height: 1.64;
            letter-spacing: 0.1px;
            
            .border-notes {
                padding-left: 48px;
                position: relative;
            }

            .ReactCollapse--content {
                padding-bottom: 25px;
            }

            ul > li {
                position: relative;
                padding-left: 30px;
                line-height: 20px;
                padding-bottom: 5px;
                &:before {
                    content: '';
                    background-image: url('./img/icons/green-check.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    top: 2px;
                    left: 0;
                }
            }
        }

        .border-notes:after {
            content: '';
            background-image: url('./img/icons/white_arrow_on_green_circle.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            position: absolute;
            width: 28px;
            height: 28px;
            z-index: 1;
            top: 0;
            left: 0;
        }

        .accordion-trigger {
            display: block;
            padding: 18px 0 21px;
            position: relative;

            .arrow-down {
                position: absolute;
                top: 50%;
                margin-top: -4px;
                right: 0;
                transform: rotate(-50grad);
                transition: transform $transitionTime; 
            }

            &:hover {
                text-decoration: none;
            }
        }

        &.active {
            .accordion-trigger {
                color: $colorDark;
            }

            .arrow-down {
                transform: rotate(50grad);
                border-color: $colorAction10;
            }
        }

        h4 {
            letter-spacing: 0.1px;
            font-size: $fontSizeL;
        }
    }

}
