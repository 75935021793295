@import 'scss/_variables.scss';
@import './mixin.scss';

.menu {
    margin: 0;
    padding: 10px;

    @include menu-mixin;

    &.menu__statuses {
        .svg-icon {
            width: 18px;
            height: 18px;
            margin-right: 5px;
        }
        .checkbox-label {
            display: inline-flex;
            vertical-align: middle;
            align-items: center;
        }
    }
}
