@import 'scss/_variables.scss';

.story-container {
  max-width: 500px;
  margin: 0 auto;

  padding: 20px 0;

  .order-tab-collapse {
    border-bottom: 1px solid $color05;

    .order-tab-collapse-head {
      cursor: pointer;
      height: 40px;
      position: relative;
      @include fontFamilyMedium;
      display: flex;
      align-items: center;

      .arrow-down {
        position: absolute;
        top: 16px;
        right: 0;
        transform: rotate(-50grad);
        transition: transform $transitionTime;

        &.active {
          transform: rotate(50grad);
          border-color: #2a86e1;
        }
      }

      .order-tab-head-name {
        display: inline-block;
        line-height: 40px;

        @include breakpointMax(medium) {
          line-height: 14px;
          max-width: 250px;
        }
      }
    }

    .order-tab-collapse-body {
      padding: 0px 0 0px 22px;

      @include breakpointMax(medium) {
        padding: 0;
      }

      .order-tab-content {
        padding-bottom: 20px;
      }
    }
  }
}
