.balance-box {
  background-color: $colorAction05;
  border-radius: $borderRadius;
  padding: 12px 15px;
  margin-bottom: 20px;
  @include fontFamilyMedium;

  .balance-sum {
    @include fontFamilyBold;
    text-align: right;
    font-size: $fontSizeTitle;
  }
}
